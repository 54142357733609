import React, { FC, useEffect, useState } from "react";
import styles from "../DetailForm.module.css";
import UploadForm from "../UploadForm/UploadForm";
import error from "../../../../icons/error-emoji.png";
import classNames from "classnames";
import { AddressDetails, documentToBase64 } from "../../../../domains/KYC";
import SkyInputBox from "../../../base/SkyInputBox/SkyInputBox";
import DocumentUploader from "../DocumentUploader/DocumentUploader";

type AddressProofFormProps = {
  kycData: AddressDetails;
  onChange: (details: AddressDetails) => void;
};

const AddressDetailsForm: FC<AddressProofFormProps> = (props) => {
  const [ownerName, setOwnerName] = useState<string>(
    props.kycData.ownerName
  );
  const [address1, setAddress1] = useState<string>(
    props.kycData.address1
  );
  const [address2, setAddress2] = useState<string>(
    props.kycData.address2
  );
  const [city, setCity] = useState<string>(props.kycData.city);
  const [zip, setZip] = useState<string>(props.kycData.zip);
  const [state, setState] = useState<string>(props.kycData.state);
  const [country, setCountry] = useState<string>(props.kycData.country);
  const [supportingDocument, setSupportingDocument] = useState<string>(
    props.kycData.supportingDocument
  );
  const [isOpenUploadOption, setIsOpenUploadOption] = useState<boolean>(false);
  const [errorMessage] = useState<string | null>(null);

  const handleUploadDoc = () => {
    setIsOpenUploadOption(true);
  };

  const handleUploadDocClose = () => {
    setIsOpenUploadOption(false);
  };

  const handleFileSelect = async (file: File | string) => {
    if (file instanceof File) {
      const base64 = await documentToBase64(file);
      setSupportingDocument(base64 as string);
    } else {
      setSupportingDocument(file as string);
    }
  };

  useEffect(() => {
    props.onChange({
      ownerName: ownerName,
      address1: address1,
      address2: address2,
      city: city,
      zip: zip,
      state: state,
      country: country,
      supportingDocument: supportingDocument,
    });
  }, [
    ownerName,
    address1,
    address2,
    city,
    zip,
    state,
    country,
    supportingDocument,
  ]);

  return (
    <div className={styles.formContainer} data-testid="address-details-form">
      {isOpenUploadOption ? (
        <UploadForm
          onClose={handleUploadDocClose}
          onFileSelect={handleFileSelect}
        />
      ) : (
        <div className={styles.wrapperContainer}>
          <div className={styles.leftContainer}>
            <DocumentUploader
              handleUploadDoc={handleUploadDoc}
              supportingDocument={supportingDocument}
              supportedDocumentsHelpText={"Any Photo ID, viz. Voter ID Card, Driving Licence, Passport"}
            />
          </div>
          <div className={styles.rightContainer}>
            <label>
              Required for address purpose
              <br />
              (All fields are mandatory)
            </label>
            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setOwnerName(value as string)}
                placeholder={"Owner / Director Name"}
                type={"text"}
                value={ownerName}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setAddress1(value as string)}
                placeholder={"Address 1"}
                type={"text"}
                value={address1}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setAddress2(value as string)}
                placeholder={"Address 2"}
                type={"text"}
                value={address2}
              />
            </div>

            <div
              className={classNames(
                styles.inputBoxContainer,
                styles.gridContainer
              )}
            >
              <SkyInputBox
                isValid={true}
                onChange={(value) => setCity(value as string)}
                placeholder={"City"}
                type={"text"}
                value={city}
              />

              <SkyInputBox
                isValid={true}
                onChange={(value) => setZip(value as string)}
                placeholder={"Zip"}
                type={"text"}
                value={zip}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setState(value as string)}
                placeholder={"State"}
                type={"text"}
                value={state}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setCountry(value as string)}
                placeholder={"Country"}
                type={"text"}
                value={country}
              />
            </div>

            {errorMessage && (
              <div className={styles.error}>
                <img src={error} alt="error-icon" />
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default AddressDetailsForm;
