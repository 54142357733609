import React, {FC, useContext, useEffect, useRef, useState} from "react";
import styles from "./VirtualNumberPurchase.module.css";
import SkyButton, {ButtonSize} from "../../base/SkyButton/SkyButton";
import classNames from "classnames";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  Circle,
  DeleteForever,
  FaxOutlined,
  IndeterminateCheckBoxOutlined,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PhoneEnabledOutlined,
  RadioButtonChecked,
  RadioButtonUnchecked,
  ShoppingCartOutlined,
  SmsOutlined
} from "@mui/icons-material";
import SkyDropdown from "../../base/SkyDropdown/SkyDropdown";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import SkyTooltip from "../../base/SkyTooltip/SkyTooltip";
import {
  fetchAvailableDids,
  fetchCitiesForDidNumbers,
  fetchCountriesForDidNumbers,
  fetchStatesForDidNumbers, getOptimizedRates,
  initiateDidOrder
} from "../../../api/endpoint";
import {AuthContext} from "../../../contextApi/AuthContext/authContext";
import {notyf} from "../../../notyf";
import {GroupType} from "../../../models/response/DidGroupTypesResponse";
import DialCodeSelector from "../../business/DialCodeSelector/DialCodeSelector";
import DotLoader from "../../business/DotLoader/DotLoader";
import {useQuery} from "react-query";
import {AxiosError} from "axios";
import {City, DidCountry, NumberDetails, State} from "../../../domains/DidNumber";
import Dialog, {Size} from "../../business/Dialog/Dialog";

enum NumberType {
  mobile = "MOBILE",
  landline = "LANDLINE",
  tfn = "TFN",
  geo = "GEO",
  national = "NATIONAL"
}

type BasePrice = {
  setupFee: number,
  rentalFee: number
}

const VirtualNumberPurchase: FC = () => {
  const {accessToken} = useContext(AuthContext);
  const [isDialCodeSelectorDropdownOpen, setIsDialCodeSelectorDropdownOpen] =
    useState(false);
  const [selectedCountry, setSelectedCountry] = useState<DidCountry | null>(null);
  const [selectedNumberType, setSelectedNumberType] =
    useState<GroupType | null>(null);
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [isNumberTypeDropdownOpen, setIsNumberTypeDropdownOpen] =
    useState(false);
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);
  const [lookupResultData, setLookupResultData] = useState<NumberDetails[]>([]);
  const [selectedNumbers, setSelectedNumbers] = useState<NumberDetails[]>([]);

  const [totalCartMRC, setTotalCartMRC] = useState(0);
  const [totalCartNRC, setTotalCartNRC] = useState(0);

  const numberTypeSelectorRef = useRef<HTMLDivElement>(null);
  const stateSelectorRef = useRef<HTMLDivElement>(null);
  const citySelectorRef = useRef<HTMLDivElement>(null);
  const countrySelectorRef = useRef<HTMLDivElement>(null);

  const [isOrderCreated, setIsOrderCreated] = useState(false);
  const [showDisableMultiSelectionDialog, setShowDisableMultiSelectionDialog] = useState(false);
  const [basePrice, setBasePrice] = useState<BasePrice>({
    setupFee: 0.00,
    rentalFee: 0.00
  });
  const [offerApplicability, setOfferApplicability] = useState(false);

  const numberTypes = [NumberType.mobile, NumberType.landline, NumberType.tfn, NumberType.geo, NumberType.national];

  const {
    isFetching: isDidCountriesFetching,
    isError: isDidCountriesFetchError,
    data: countries
  } = useQuery(
    "countries",
    () => fetchCountriesForDidNumbers(accessToken ? accessToken : ""),
    {
      select: (data) => data,
      onError: () => notyf.error("Failed to load countries!")
    }
  );

  const {
    isFetching: isStatesFetching,
    isError: isStatesFetchError,
    data: states
  } = useQuery(
    ["states", selectedCountry],
    () => {
      if (accessToken && selectedCountry) {
        return fetchStatesForDidNumbers(
          accessToken,
          selectedCountry.id
        );
      }
    },
    {
      enabled: selectedCountry != null,
      select: (data) => data,
      onError: () => notyf.error("Failed to load states!")
    }
  );

  const {
    isFetching: isCitiesFetching,
    isError: isCitiesFetchError,
    data: cities
  } = useQuery(
    ["cities", selectedCountry, selectedState],
    () =>
      fetchCitiesForDidNumbers(
        accessToken ? accessToken : "",
        selectedCountry ? selectedCountry.id : "",
        selectedState ? selectedState.id : ""
      ),
    {
      enabled: selectedCountry != null,
      select: (data) => data,
      onError: () => notyf.error("Failed to load cities!")
    }
  );

  const {isFetching: isAvailableDIDFetching, refetch: fetchAvailableDIDs} =
    useQuery(
      ["availableDIDs"],
      () =>
        fetchAvailableDids(
          accessToken ? accessToken : "",
          selectedCountry ? selectedCountry.name : "",
          selectedState ? selectedState.name : "",
          selectedCity ? selectedCity.name : "",
          selectedNumberType ? selectedNumberType.typeName : ""
        ),
      {
        onSuccess: (data) => {
          if (data) setBasePrice({setupFee: data[0].setupFee, rentalFee: data[0].monthlyFee});
          setLookupResultData(
            data.map((did) => {
              return {
                didId: did.id,
                number: did.number,
                skuId: did.skuId,
                mrc: did.monthlyFee,
                nrc: did.setupFee,
                country: selectedCountry ? selectedCountry.name : "",
                state: selectedState ? selectedState.name : "",
                supports: {call: true, sms: false, fax: false},
                isSelected: false,
                isMovedToCart: false
              };
            })
              .filter(
                (did) =>
                  !selectedNumbers.some(
                    (selectedDid) =>
                      did.number === selectedDid.number &&
                      selectedDid.isSelected
                  )
              )
          );
        },
        onError: (err: AxiosError) => {
          if (err.response?.status !== 404)
            notyf.error("Failed to lookup available DIDs!");

          setLookupResultData([]);
        },
        enabled: false
      }
    );

  const {isFetching: isCheckoutProcessing, refetch: initiateOrder} =
    useQuery(["initiateOrder"], () =>
        initiateDidOrder(accessToken ? accessToken : "", {
          orders: selectedNumbers.map((numberDetails) => {
            return {
              availableDidId: numberDetails.didId,
              number: numberDetails.number,
              price: numberDetails.mrc + numberDetails.nrc,
              skuId: numberDetails.skuId
            };
          })
        }),
      {
        onSuccess: (isOrderSuccess) => {
          if(!isOrderSuccess) notyf.error("Order creation failed. Please try again.");
          else {
            setIsOrderCreated(true);
            setSelectedNumbers([]);
          }
        },
        onError: () => notyf.error("Order creation failed. Please try again."),
        enabled: false
      }
    );

  const {
    isFetching: isOptimizedRatesFetching,
  } = useQuery(
    ["optimizedRates", selectedNumbers.length],
    () =>
      getOptimizedRates(
        accessToken ? accessToken : "",
        selectedCountry?.name ?? "",
        selectedNumbers.length,
        selectedNumberType?.typeName ?? ""
      ),
    {
      enabled: selectedCountry !=null && selectedNumberType != null,
      onSuccess: (rates) => {
        if (rates) {
          setOfferApplicability(rates.setup < basePrice?.setupFee || rates.rental < basePrice?.rentalFee);

          setSelectedNumbers([...selectedNumbers.map(number => {
            number.nrc = rates.setup;
            number.mrc = rates.rental;
            return number;
          })]);
        }
      },
      onError: () => {
        notyf.error("Failed to apply offer!");
      }
    }
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    setTotalCartMRC(
      selectedNumbers
        .map((number) => number.mrc)
        .reduce((sum, current) => sum + current, 0)
    );

    setTotalCartNRC(
      selectedNumbers
        .map((number) => number.nrc)
        .reduce((sum, current) => sum + current, 0)
    );
  }, [selectedNumbers]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      (numberTypeSelectorRef.current &&
        !numberTypeSelectorRef.current.contains(event.target as Node)) ||
      (stateSelectorRef.current &&
        !stateSelectorRef.current.contains(event.target as Node)) ||
      (citySelectorRef.current &&
        !citySelectorRef.current.contains(event.target as Node)) ||
      (countrySelectorRef.current &&
        !countrySelectorRef.current.contains(event.target as Node))
    ) {
      closeDropdown();
    }
  };

  const closeDropdown = () => {
    setIsNumberTypeDropdownOpen(false);
    setIsStateDropdownOpen(false);
    setIsCityDropdownOpen(false);
    setIsDialCodeSelectorDropdownOpen(false);
  };

  const handleDialCodeSelection = (country: DidCountry) => {
    setSelectedCountry(country);
    setSelectedState(null);
    setSelectedCity(null);
    setIsDialCodeSelectorDropdownOpen(false);
  };

  const updateSelectionOfAllNumbers = (toBeAllSelected: boolean) => {
    setLookupResultData(
      lookupResultData.map((data) => ({
        ...data,
        isSelected: toBeAllSelected
      }))
    );
  };

  const lookupResultContainerHeaders = [
    () => (
      <div className={classNames(styles.rowHeader, styles.rowHeaderNumber)}>
        <span className={styles.clickable}>
          {lookupResultData?.filter(
            (data) => data.isSelected && !data.isMovedToCart
          ).length == 0 ? (
            <CheckBoxOutlineBlank
              data-testid={"none-select-icon"}
              onClick={() => updateSelectionOfAllNumbers(true)}
            />
          ) : lookupResultData?.filter(
            (data) => data.isSelected && !data.isMovedToCart
          ).length <
          lookupResultData?.filter((data) => !data.isMovedToCart).length ? (
            <IndeterminateCheckBoxOutlined
              data-testid={"some-select-icon"}
              onClick={() => updateSelectionOfAllNumbers(true)}
            />
          ) : (
            <CheckBoxOutlined
              data-testid={"all-select-icon"}
              onClick={() => updateSelectionOfAllNumbers(false)}
            />
          )}
        </span>
        Number
      </div>
    ),
    () => <span className={styles.rowHeader}>MRC*</span>,
    () => <span className={styles.rowHeader}>NRC**</span>,
    () => (
      <span
        className={classNames(
          styles.rowHeader,
          styles.capabilityIconsContainer
        )}
      >
        <PhoneEnabledOutlined/>
        <SmsOutlined/>
        <FaxOutlined/>
      </span>
    )
  ];

  const getDropdownMessageElement = (message: string) => {
    return (
      <div
        className={classNames(styles.notFoundMessageBox, styles.statusBox)}
        data-testid={"dropdown-message"}
      >
        <span className={styles.font}> {message} </span>
      </div>
    );
  };

  const getClass = (isCapable: boolean) =>
    isCapable ? styles.blue : styles.lightGray;

  const StateDropdownContent = () => {
    return !states ? (
      <>
        {isStatesFetching ? <div className={styles.statusBox}><DotLoader/></div> : null}
        {isStatesFetchError ? getDropdownMessageElement("Error loading states!") : null}
      </>
    ) : states.length > 0 ? (
      <SkyDropdown
        data={states.map((state) =>
          ({id: state.id, value: state.name}))
        }
        isSearchable={true}
        onSelect={(data) => {
          setSelectedState({id: data.id, name: data.value});
          setSelectedCity(null);
          setIsStateDropdownOpen(false);
        }}
      />
    ) : getDropdownMessageElement("No state found!");
  };

  const CityDropdownContent = () => {
    return !cities ? (
      <>
        {isCitiesFetching && (
          <div className={styles.statusBox}
               data-testid={"city-fetching-loader"}>
            <DotLoader/>
          </div>
        )}
        {isCitiesFetchError &&
          getDropdownMessageElement("Error loading cities!")}
      </>
    ) : cities.length > 0 ? (
      <SkyDropdown
        data={cities.map((city) => {
          return {
            id: city.id,
            value: city.name
          };
        })}
        isSearchable={true}
        onSelect={(data) => {
          setSelectedCity({id: data.id, name: data.value});
          setIsCityDropdownOpen(false);
        }}
      />
    ) : getDropdownMessageElement("No city found!");
  };

  const DialCodeSelectorContent = () => {
    return !countries ? (
      <>
        {isDidCountriesFetching && (
          <div className={styles.statusBox}>
            <DotLoader/>
          </div>
        )}
        {isDidCountriesFetchError &&
          getDropdownMessageElement("Error loading countries!")}
      </>
    ) : countries.length > 0 ? (
      <DialCodeSelector
        countries={countries.map((country) => ({
          id: country.id,
          name: country.name,
          code: country.iso2,
          dialCode: country.phonecode
        }))}
        backgroundColor={"var(--white)"}
        fontColor={"var(--gray)"}
        onSelect={(country) =>
          handleDialCodeSelection({
            id: country.id,
            name: country.name,
            dialCode: country.dialCode,
            prefix: country.code,
            flag: country.flag
          })
        }
      />
    ) : (
      getDropdownMessageElement("No country found!")
    );
  };

  const RenderNumberTypeDropdown = () => {
    return (
      <SkyDropdown
        data={numberTypes.map((numberType) => ({
          id: "type-" + numberType,
          value: numberType
        }))}
        onSelect={(data) => {
          setSelectedNumberType({
            id: data.id,
            typeName: data.value
          });
          setIsNumberTypeDropdownOpen(false);
        }}
      />
    );
  };

  return (
    <div
      className={styles.purchaseNumberContainer}
      data-testid={"buy-a-number-container"}
    >
      <div className={styles.purchaseNumberLookupContainer}>
        <div
          className={styles.purchaseNumberLookupFormContainer}
          data-testid={"lookup-form"}
        >
          <div className={styles.purchaseNumberInputContainer}>
            <div
              className={classNames(styles.font, styles.purchaseNumberInput)}
            >
              <label>
                Country
                <span className={styles.requiredFieldIndicator}>*</span>
              </label>

              <div className={styles.purchaseNumberInputField}>
                <div
                  className={styles.dialCodeContainer}
                  data-testid={"country-selector"}
                  onClick={() =>
                    setIsDialCodeSelectorDropdownOpen(
                      !isDialCodeSelectorDropdownOpen
                    )
                  }
                >
                  <div
                    className={styles.selectedDialCode}
                    data-testid={"selected-country-container"}
                  >
                    {selectedCountry ? selectedCountry.flag : <></>}
                    <span
                      data-testid="selected-country-dial-code"
                      className={styles.font}
                    >
                      {selectedCountry ? `+${selectedCountry.dialCode}` : ""}
                    </span>
                  </div>
                  {isDialCodeSelectorDropdownOpen ? (
                    <KeyboardArrowUp
                      data-testid={"country-selector-arrow-up"}
                    />
                  ) : (
                    <KeyboardArrowDown
                      data-testid={"country-selector-arrow-down"}
                    />
                  )}
                </div>
                {isDialCodeSelectorDropdownOpen && (
                  <div ref={countrySelectorRef}>
                    <DialCodeSelectorContent/>
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames(styles.font, styles.purchaseNumberInput)}
            >
              <label>
                Type
                <span className={styles.requiredFieldIndicator}>*</span>
              </label>
              <div className={styles.purchaseNumberInputField}>
                <div
                  className={styles.purchaseNumberDropdownInput}
                  data-testid={"number-type-selector"}
                  onClick={() =>
                    setIsNumberTypeDropdownOpen(!isNumberTypeDropdownOpen)
                  }
                >
                  <span>
                    {selectedNumberType ? selectedNumberType.typeName : ""}
                  </span>
                  {isNumberTypeDropdownOpen ? (
                    <KeyboardArrowUp
                      data-testid={"number-type-selector-arrow-up"}
                    />
                  ) : (
                    <KeyboardArrowDown
                      data-testid={"number-type-selector-arrow-down"}
                    />
                  )}
                </div>
                {isNumberTypeDropdownOpen && (
                  <div ref={numberTypeSelectorRef}>
                    <RenderNumberTypeDropdown/>
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames(styles.font, styles.purchaseNumberInput)}
            >
              <label>State</label>
              <div className={styles.purchaseNumberInputField}>
                <div
                  className={classNames(
                    styles.purchaseNumberDropdownInput,
                    selectedCountry ? styles.enabled : styles.disabled
                  )}
                  data-testid={"state-selector"}
                  onClick={() => setIsStateDropdownOpen(!isStateDropdownOpen)}
                >
                  <span>{selectedState ? selectedState.name : ""}</span>
                  {isStateDropdownOpen ? (
                    <KeyboardArrowUp data-testid={"state-selector-arrow-up"}/>
                  ) : (
                    <KeyboardArrowDown
                      data-testid={"state-selector-arrow-down"}
                    />
                  )}
                </div>
                {isStateDropdownOpen && (
                  <div ref={stateSelectorRef}>
                    <StateDropdownContent/>
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames(styles.font, styles.purchaseNumberInput)}
            >
              <label>City</label>
              <div className={styles.purchaseNumberInputField}>
                <div
                  className={classNames(
                    styles.purchaseNumberDropdownInput,
                    selectedCountry ? styles.enabled : styles.disabled
                  )}
                  data-testid={"city-selector"}
                  onClick={() => setIsCityDropdownOpen(!isCityDropdownOpen)}
                >
                  <span>{selectedCity ? selectedCity.name : ""}</span>
                  {isCityDropdownOpen ? (
                    <KeyboardArrowUp data-testid={"city-selector-arrow-up"}/>
                  ) : (
                    <KeyboardArrowDown
                      data-testid={"city-selector-arrow-down"}
                    />
                  )}
                </div>
                {isCityDropdownOpen && (
                  <div ref={citySelectorRef}>
                    <CityDropdownContent/>
                  </div>
                )}
              </div>
            </div>
            <div>
              {isAvailableDIDFetching ? (
                <DotLoader/>
              ) : (
                <SkyButton
                  text="Lookup"
                  size={ButtonSize.SMALL}
                  testId={"lookup-button"}
                  onClick={() => fetchAvailableDIDs()}
                  disabled={!selectedCountry || !selectedNumberType}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.purchaseNumberLookupResultContainer}>
        <div
          className={styles.purchaseNumberSelector}
          data-testid={"purchase-number-selector"}
        >
          <div className={styles.purchaseNumberSelectorGrid}>
            <SkyGrid
              isLoading={isAvailableDIDFetching}
              headerRenderer={lookupResultContainerHeaders}
              renderer={lookupResultData
                .filter((data) => !data.isMovedToCart)
                .map((data) => {
                  return [
                    () => (
                      <div className={styles.selectorNumberCol}>
                        <div
                          onClick={() => {
                            setLookupResultData(
                              lookupResultData.map((result) =>
                                result.didId === data.didId
                                  ? {...result, isSelected: !data.isSelected}
                                  : result
                              )
                            );
                          }}
                        >
                          {data.isSelected ? (
                            <RadioButtonChecked
                              className={classNames(
                                styles.selectedIcon,
                                styles.blue,
                                styles.clickable
                              )}
                              data-testid={`number-selected-${data.didId}`}
                            />
                          ) : (
                            <RadioButtonUnchecked
                              className={classNames(
                                styles.unselectedIcon,
                                styles.blue,
                                styles.clickable
                              )}
                              data-testid={`number-unselected-${data.didId}`}
                            />
                          )}
                        </div>
                        {"+" + data.number}
                      </div>
                    ),
                    () => (
                      <span data-testid={`number-mrc-${data.number}`}>
                        {"$ " + data.mrc}
                      </span>
                    ),
                    () => (
                      <span data-testid={`number-nrc-${data.number}`}>
                        {"$ " + data.nrc}
                      </span>
                    ),
                    () => (
                      <div
                        className={classNames(
                          styles.capabilityIconsContainer,
                          styles.iconContainer,
                          styles.capabilityIconsDataContainer
                        )}
                      >
                        <Circle
                          className={getClass(data.supports.call)}
                          data-testid={"capability-call-icon"}
                        />
                        <Circle
                          className={getClass(data.supports.sms)}
                          data-testid={"capability-sms-icon"}
                        />
                        <Circle
                          className={getClass(data.supports.fax)}
                          data-testid={"capability-fax-icon"}
                        />
                      </div>
                    )
                  ];
                })}
            />
          </div>
          <div
            className={styles.purchaseNumberSelectorFooter}
            data-testid={"purchase-number-selector-footer"}
          >
            <span className={styles.abbreviation}>
              <span className={classNames(styles.boldFont, styles.blue)}>
                MRC*
              </span>
              Monthly Recurring Charges
            </span>
            <span className={styles.abbreviation}>
              <span className={classNames(styles.boldFont, styles.blue)}>
                NRC**
              </span>
              Non-Recurring Charges (Setup)
            </span>
            {lookupResultData.length > 0 && (
              <SkyButton
                size={ButtonSize.SMALL}
                testId={"move-to-cart-button"}
                onClick={() => {
                  const newlySelectedNumbers = lookupResultData.filter(
                    (data) => data.isSelected && !data.isMovedToCart
                  );
                  if(selectedNumbers.length > 0 || newlySelectedNumbers.length > 1) {
                    setShowDisableMultiSelectionDialog(true);
                    return;
                  }
                  setSelectedNumbers([
                    ...selectedNumbers,
                    ...newlySelectedNumbers
                  ]);

                  setLookupResultData(
                    lookupResultData.map((data) =>
                      data.isSelected ? {...data, isMovedToCart: true} : data
                    )
                  );
                }}
                text={"Move to cart"}
              />
            )}
          </div>
        </div>
        <div
          className={styles.purchaseNumberCart}
          data-testid={"purchase-number-cart"}
        >
          <div className={styles.cartIconContainer}>
            <ShoppingCartOutlined/>
          </div>
          <div className={styles.cartGrid}>
            <SkyGrid
              isLoading={isCheckoutProcessing || isOptimizedRatesFetching}
              columns={[" ", "MRC", "NRC", " "]}
              renderer={selectedNumbers.map((numberDetails) => {
                return [
                  () => (
                    <span
                      className={styles.rightAlign}
                      data-testid={`number-cart-${numberDetails.didId}`}
                    >
                        {"+" + numberDetails.number}
                      </span>
                  ),
                  () => (
                    <span
                      data-testid={`number-mrc-cart-${numberDetails.didId}`}
                    >
                        {"$ " + numberDetails.mrc}
                      </span>
                  ),
                  () => (
                    <span
                      data-testid={`number-nrc-cart-${numberDetails.didId}`}
                    >
                        {"$ " + numberDetails.nrc}
                      </span>
                  ),
                  () => (
                    <div
                      className={classNames(styles.blue, styles.iconContainer, styles.size)}
                    >
                      <SkyTooltip
                        tooltipRenderer={() => (
                          <div>
                            <span>Country: {numberDetails.country}</span>
                            <br/>
                            <span>State: {numberDetails.state}</span>
                            <div className={styles.tooltipIconsContainer}>
                              Capability:
                              <div>
                                {numberDetails.supports.call && (
                                  <PhoneEnabledOutlined/>
                                )}
                                {numberDetails.supports.sms && <SmsOutlined/>}
                                {numberDetails.supports.fax && <FaxOutlined/>}
                              </div>
                            </div>
                          </div>
                        )}
                      >
                        <InfoOutlined className={styles.clickable}/>
                      </SkyTooltip>

                      <DeleteForever
                        className={styles.clickable}
                        data-testid={"remove-number-from-cart"}
                        onClick={() => {
                          numberDetails.isSelected = false;
                          setSelectedNumbers(
                            selectedNumbers.filter(
                              (selectedNumber) => selectedNumber.isSelected
                            )
                          );
                          setLookupResultData(
                            lookupResultData.map((data) =>
                              data.number === numberDetails.number
                                ? {
                                  ...data,
                                  isSelected: false,
                                  isMovedToCart: false
                                }
                                : data
                            )
                          );

                          setTotalCartMRC(
                            selectedNumbers
                              .filter(
                                (selectedNumber) => selectedNumber.isSelected
                              )
                              .map((number) => number.mrc)
                              .reduce((sum, current) => sum + current, 0)
                          );

                          setTotalCartNRC(
                            selectedNumbers
                              .filter(
                                (selectedNumber) => selectedNumber.isSelected
                              )
                              .map((number) => number.nrc)
                              .reduce((sum, current) => sum + current, 0)
                          );
                        }}
                      />
                    </div>
                  )
                ];
              })}
            />
          </div>
          {selectedNumbers.length > 0 && !isCheckoutProcessing && !isOptimizedRatesFetching && (
            <div data-testid={"cart-footer"}>
              {
                offerApplicability &&
                  <div className={styles.offerMessageContainer} data-testid={"offer-banner"}>
                    <span>Offer applied</span>
                  </div>
              }
              <div className={styles.cartAmountContainer}>
                <div></div>
                <div className={styles.font}>
                  <div
                    className={classNames(
                      styles.cartAmountTotal,
                      styles.bottomBorder
                    )}
                  >
                    <span data-testid={"total-mrc"}>$ {totalCartMRC}</span>
                    <span data-testid={"total-nrc"}>$ {totalCartNRC}</span>
                  </div>
                  <div className={styles.cartAmountTotal}>
                    <span className={styles.blue}>Total</span>
                    <span data-testid={"total-amount"}>
                      $ {totalCartNRC + totalCartMRC}
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
              <div className={styles.cartButtonContainer}>
                <SkyButton
                  size={ButtonSize.SMALL}
                  onClick={() => initiateOrder()}
                  text={"Checkout"}
                />
              </div>
            </div>
          )}
          {
            isOrderCreated && <Dialog
                  size={Size.S}
                  testId={"order-confirmation-dialog"}
                  header={"Order Confirmation"}
                  message={"Your order has been created successfully. This will be updated in your account within 5 minutes. For any assistance, please connect to support@skytelservices.com"}
                  onConfirm={() => setIsOrderCreated(false)}
              />
          }
          {
            showDisableMultiSelectionDialog && <Dialog
                  size={Size.S}
                  testId={"order-confirmation-dialog"}
                  header={"Order Confirmation"}
                  message={"Multiple numbers selection is not supported. Please checkout number one at a time."}
                  onConfirm={() => setShowDisableMultiSelectionDialog(false)}
              />
          }
        </div>
      </div>
    </div>
  );
};

export default VirtualNumberPurchase;
