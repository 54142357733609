import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import styles from "./RegistrationScreen.module.css";
import userWithLaptop from "../../images/UserWithLaptop.png";
import registrationFormLogo from "../../images/skyRegistrationLogo.png";
import {UserRegistrationRequest} from "../../models/request/UserRegistrationRequest";
import {registerUser} from "../../api/endpoint";
import errorIcon from "../../icons/error-emoji.png";
import countriesCode from "../../countryCode.json";
import {ToastContainer} from "react-toastify";
import {notyf} from "../../notyf";
import Dialog, {Size} from "../../components/business/Dialog/Dialog";
import welcomeImage from "../../icons/welcome.png";
import loaderClip from "../../icons/signup_loader.svg";
import {OTPContext} from "../../contextApi/OTPContext/OTPContext";
import {isOTPRequired} from "../../requester";
import SkyButton, {ButtonSize} from "../../components/base/SkyButton/SkyButton";
import classNames from "classnames";

const RegistrationScreen: FC = () => {
  const navigate = useNavigate();
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(true);
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("+1");
  const [isContactNumberValid, setIsContactNumberValid] =
    useState<boolean>(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState<boolean>(true);
  const [isStateValid, setIsStateValid] = useState<boolean>(true);
  const [isBlank, setIsBlank] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchDialCode, setSearchDialCode] = useState<string>("");
  const [showPasswordTooltip, setShowPasswordTooltip] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRegistrationSuccessDialogOpen, setIsRegistrationSuccessDialogOpen] =
    useState<boolean>(false);
  const [emailExistError, setEmailExistError] = useState<string>("");
  const {
    setOTPRequired,
    setErrorResponse,
    isOTPVerificationSuccess,
    setIsOTPVerificationSuccess,
  } = useContext(OTPContext);

  const nameRegex = /^[A-Za-z]+$/;
  const emailRegex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)+$/;
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*._-])[A-Za-z\d!@#$%^&*._-]{8,15}$/;
  const contactNumberRegex = /^\d+$/;
  const zipCodeRegex = /^(?=.*\d)[A-Za-z\d]*\d[A-Za-z\d]*$/;
  const stateRegex = /^[A-Za-z ]+$/;

  const [formData, setFormData] = useState<UserRegistrationRequest>({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    confirmPassword: "",
    contactNumber: "",
    zipCode: "",
    state: "",
    address: "",
  });

  useEffect(() => {
    if (isOTPVerificationSuccess) {
      setIsRegistrationSuccessDialogOpen(true);
      setIsOTPVerificationSuccess(false);
    }
  }, [isOTPVerificationSuccess]);

  const validateField = (value, regex, minLength, maxLength) =>
    regex.test(value) && value.length >= minLength && value.length <= maxLength;

  const handleChange = (e) => {
    const {name, value} = e.target;
    if (name === "username") {
      setEmailExistError("");
    }
    const validators = {
      firstName: validateField(value, nameRegex, 1, 15),
      lastName: validateField(value, nameRegex, 1, 15),
      username: validateField(value, emailRegex, 1, Infinity),
      password: validateField(value, passwordRegex, 8, 15),
      zipCode: validateField(value, zipCodeRegex, 1, 10),
      contactNumber: validateField(value, contactNumberRegex, 8, 15),
      state: validateField(value, stateRegex, 1, Infinity),
    };

    const isValid = validators[name] || value.trim() === "";
    const newState = {...formData, [name]: value};

    switch (name) {
      case "firstName":
        setIsFirstNameValid(isValid);
        break;
      case "lastName":
        setIsLastNameValid(isValid);
        break;
      case "username":
        setIsEmailValid(isValid);
        break;
      case "password":
        setIsPasswordValid(isValid);
        break;
      case "zipCode":
        setIsZipCodeValid(isValid);
        break;
      case "contactNumber":
        setIsContactNumberValid(isValid);
        break;
      case "state":
        setIsStateValid(isValid);
        break;
      default:
        break;
    }

    setFormData(newState);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropDownOpen(false);
    }
  };
  useEffect(() => {
    handlePasswordConfirm();
    if (isDropDownOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [formData, isDropDownOpen]);
  const handlePasswordConfirm = () => {
    setIsPasswordMatch(
      formData.password === formData.confirmPassword ||
      formData.password === "" ||
      formData.confirmPassword === ""
    );
  };

  const handleSubmit = async () => {
    const {
      firstName,
      lastName,
      username,
      password,
      confirmPassword,
      contactNumber,
      zipCode,
      state,
      address,
    } = formData;

    const formattedContactNumber = `${selectedCountryCode}:${contactNumber}`;
    const updatedFormData = {
      ...formData,
      contactNumber: formattedContactNumber,
    };
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      username.trim() === "" ||
      password.trim() === "" ||
      confirmPassword.trim() === "" ||
      contactNumber.trim() === "" ||
      zipCode.trim() === "" ||
      state.trim() === "" ||
      address.trim() === ""
    ) {
      setIsBlank(true);
      return;
    }
    if (!passwordRegex.test(password)) {
      setIsPasswordValid(false);
      return;
    }
    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      !isEmailValid ||
      !isPasswordValid ||
      !isPasswordMatch ||
      !isContactNumberValid ||
      !isZipCodeValid ||
      !isStateValid
    ) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await registerUser(updatedFormData);
      if (response.userId !== null) {
        setIsRegistrationSuccessDialogOpen(true);
      } else {
        notyf.error("Something went wrong");
      }
    } catch (error: any) {
      if (isOTPRequired(error)) {
        setOTPRequired(true);
        setErrorResponse(error);
      } else if (error?.response?.status === 409) {
        setEmailExistError(error?.response?.data?.error);
      } else {
        notyf.error("Something went wrong. Please try again later");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialCodeSelection = (dialCode) => {
    setSelectedCountryCode(dialCode);
    setSearchDialCode("");
    setIsDropDownOpen(false);
  };
  const dropDownOpen = () => setIsDropDownOpen(!isDropDownOpen);

  const getInputClassName = (fieldValue, isValid = true) =>
    isBlank && (!fieldValue.trim() || !isValid)
      ? styles.emptyInputField
      : styles.inputField;

  return (
    <div className={styles.registerScreenOuterContainer}>
      <div className={styles.registerScreenLeftContainer}>
        <img
          src={userWithLaptop}
          alt="Guy with laptop"
          className={styles.userWithLaptop}
        />
      </div>
      <div className={styles.registerScreenRightContainer}>
        {!isLoading && (
          <img
            src={registrationFormLogo}
            alt="logo"
            className={styles.skyFormLogo}
          />
        )}
        <div
          className={`${styles.registrationFormContainer} ${
            isLoading ? styles.hideForm : ""
          }`}
        >
          <div className={styles.userNameInputContainer}>
            <div className={styles.inputContainer}>
              <label htmlFor="firstname" data-required={true}>
                First Name
              </label>
              <input
                type="text"
                id="firstname"
                name="firstName"
                onChange={handleChange}
                className={getInputClassName(formData.firstName, isFirstNameValid)}
              />
              {!isFirstNameValid && (
                <p>
                  {" "}
                  {!nameRegex.test(formData.firstName)
                    ? "Name must contain only letters!"
                    : "Must be less than 15 chars!"}
                </p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="lastname" data-required={true}>
                Last Name
              </label>
              <input
                type="text"
                id="lastname"
                name="lastName"
                onChange={handleChange}
                className={getInputClassName(formData.lastName, isLastNameValid)}
              />
              {!isLastNameValid && (
                <p className={styles.lastNameValidation}>
                  {!nameRegex.test(formData.lastName)
                    ? "Name must contain only letters!"
                    : "Must be less than 15 chars!"}
                </p>
              )}
            </div>
          </div>
          <label htmlFor="email" data-required={true}>
            Email
          </label>
          <input
            type="email"
            id="email"
            name="username"
            onChange={handleChange}
            className={getInputClassName(formData.username)}
          />
          {!isEmailValid && !emailExistError && (
            <p className={styles.InValidMailText}>
              Please enter a valid email address!
            </p>
          )}
          {emailExistError && (
            <p className={styles.emailExistErrorText}>
              <img
                src={errorIcon}
                alt="error icon"
                className={styles.emailErrorIcon}
              />
              {emailExistError}
            </p>
          )}
          <div className={styles.passwordInputOuterContainer}>
            <div className={styles.inputOuterContainer}>
              <div className={styles.inputContainer}>
                <label htmlFor="password" data-required={true}>
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  className={
                    (isBlank && !formData.password) ||
                    !isPasswordValid ||
                    /^\s+$/.test(formData.password)
                      ? styles.emptyInputField
                      : styles.inputField
                  }
                />
                <div
                  className={styles.passwordInfoIcon}
                  onClick={() => setShowPasswordTooltip(!showPasswordTooltip)}
                  data-testid="password-info-icon"
                />
                {showPasswordTooltip && (
                  <div className={styles.tooltip}>
                    Password must contain between 8 and 15 characters, including
                    UPPERCASE, lowercase, numbers and special characters.
                    <span>(ex. A1b2c3@e)</span>
                  </div>
                )}
                {!isPasswordValid && (
                  <div className={styles.passwordErrorContainer}>
                    <p className={styles.passwordValidationText}>
                      Invalid Password!
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.inputContainer}>
                <label htmlFor="confirmPassword" data-required={true}>
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={handleChange}
                  className={getInputClassName(formData.confirmPassword)}
                />
                {!isPasswordMatch && (
                  <div className={styles.passwordErrorContainer}>
                    <img
                      src={errorIcon}
                      alt="error icon"
                      className={styles.errorIcon}
                    />
                    <p>Password does not match!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.inputOuterContainer}>
            <div className={styles.contactInput}>
              <label htmlFor="contactNumber" data-required={true}>
                Contact Number
              </label>
              <div className={styles.contactNumber}>
                <div
                  ref={dropdownRef}
                  onClick={dropDownOpen}
                  data-testid="dropdown"
                >
                  <input
                    type="text"
                    readOnly={true}
                    className={styles.countryCode}
                    value={selectedCountryCode}
                    onChange={handleDialCodeSelection}
                  />
                  {isDropDownOpen && (
                    <div className={styles.countryCodeDropdownMenu}>
                      <input
                        type="text"
                        className={`${styles.countryCodeSearch}`}
                        placeholder="Search..."
                        value={searchDialCode}
                        onChange={(e) => setSearchDialCode(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                        data-testid="select-countryCode"
                      />
                      {countriesCode
                        .filter(
                          (country) =>
                            country.name
                              .toLowerCase()
                              .includes(searchDialCode.toLowerCase()) ||
                            country.dialCode.includes(searchDialCode)
                        )
                        .map((country, index) => (
                          <div
                            key={index}
                            className={styles.countryCodeDropdownOption}
                            onClick={() =>
                              handleDialCodeSelection(country.dialCode)
                            }
                          >
                            {country.name} - {country.dialCode}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <input
                  type="number"
                  id="contactNumber"
                  name="contactNumber"
                  onChange={handleChange}
                  className={
                    isBlank && !formData.contactNumber
                      ? styles.contactNumberEmptyField
                      : styles.contactNumberInputField
                  }
                />
              </div>
              {!isContactNumberValid && <p>Contact number should be valid!</p>}
            </div>
            <div className={styles.zipCodeInput}>
              <label htmlFor="zipCode" data-required={true}>
                ZIP
              </label>
              <input
                type="text"
                id="zipCode"
                name="zipCode"
                onChange={handleChange}
                className={getInputClassName(formData.zipCode, isZipCodeValid)}
              />
              {!isZipCodeValid && (
                <p className={styles.zipValidation}>
                  ZIP code should be valid!
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputOuterContainer}>
            <div className={styles.inputContainer}>
              <label htmlFor="state" data-required={true}>
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                onChange={handleChange}
                className={getInputClassName(formData.state, isStateValid)}
              />
              {!isStateValid && <p>State must contain only letters!</p>}
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="address" data-required={true}>
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                onChange={handleChange}
                className={getInputClassName(formData.address)}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={handleSubmit}
            className={styles.signUpButton}
            disabled={isLoading}
          >
            Sign Up
          </button>
        </div>
        <div className={styles.loaderContainer}>
          {isLoading && (
            <img
              src={loaderClip}
              alt="Loading"
              className={styles.loaderImage}
            />
          )}
        </div>
      </div>
      <ToastContainer/>
      {isRegistrationSuccessDialogOpen && (
        <Dialog
          size={Size.SM}
          renderer={() => (
            <div className={styles.successMessageContainer}>
              <img
                src={welcomeImage}
                alt="Success icon"
                className={styles.successImage}
              />
              <p className={styles.nameContainer}>Hi {formData.firstName}</p>
              <p>Thanks for registering an account with us!</p>
              <p className={styles.dialogueMessage}>
                You can login now from login screen.
              </p>
              <div className={styles.buttonContainer}>
                <SkyButton
                  text="OK"
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    setIsRegistrationSuccessDialogOpen(false);
                    navigate("/");
                  }}
                  disabled={false}
                  testId={"dialog-confirm-button"}
                />
              </div>
            </div>
          )}
        />
      )}
    </div>
  );
};

export default RegistrationScreen;
