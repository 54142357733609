import {AxiosError, AxiosRequestConfig} from "axios";
import axios from "axios";
import {notyf} from "./notyf";
import {sendMattermostAlert} from "./api/endpoint";

export const baseUrl = "/api/v1";

export const isOTPRequired = (error) =>
  error.response.headers != undefined &&
  error.response.headers["skyerror"] != undefined &&
  error.response.headers["skyerror"] === "ERR_OTP_REQUIRED";

export const isWrongOTP = (error) =>
  error.response.headers != undefined &&
  error.response.headers["skyerror"] != undefined &&
  (error.response.headers["skyerror"] === "ERR_INCORRECT_OTP" ||
    error.response.headers["skyerror"] === "ERR_OTP_EXPIRED");

const config: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: baseUrl,
};

const requester = axios.create(config);

requester.interceptors.response.use(
  (response) => {
    return response.data;
  },

  function (error: AxiosError) {
    if (error.response) {
      if (isOTPRequired(error) || isWrongOTP(error)) {
        //nothing to do
      } else if (error.message === "Network Error") {
        notyf.error(`<b>Connection Failed</b>`);
      } else if (error.response.status === 401) {
        window.location.href = "/login"
      }
      else if (
        error.response.status >= 400 &&
        error.response.status < 500 &&
        error.response.status != 404 &&
        error.response.status != 403 &&
        error.response.status != 409
      ) {
        notyf.error(`Ooops!! Something Went Wrong`);
      } else if (error.response.status >= 500) {
        notyf.error(
          `<b>Sorry!!</b><br /><div> The service is temporarily unavailable</div>`
        );
        const message = `HTTP status ${error.response.status} received from ${error.config?.url}`;
        sendMattermostAlert(message)
          .then((_) => {
          })
          .catch(() => {
            notyf.error("Error while sending Alert Message");
          })
      }
      return Promise.reject(error);
    }
  }
);

export default requester;