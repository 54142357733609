import React, { FC } from "react";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export type Props = {
  tooltipText?: string;
  tooltipRenderer?: () => JSX.Element | null;
  children: React.ReactElement<any, any>;
  backgroundColor?: string;
  fontColor?: string;
  maxWidth?: number;
  isOpen?: boolean;
};

const SkyTooltip: FC<Props> = (props: Props) => {
  if (!props.tooltipText && !props.tooltipRenderer) return props.children;

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : "var(--blue)",
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: props.backgroundColor
        ? props.backgroundColor
        : "var(--blue)",
      color: props.fontColor ? props.fontColor : "var(--white)",
      maxWidth: props.maxWidth ? props.maxWidth : 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid",
      borderColor: props.backgroundColor
        ? props.backgroundColor
        : "var(--blue)",
    },
  }));

  return (
    <CustomTooltip
      title={
        (props.tooltipText && props.tooltipText) ||
        (props.tooltipRenderer && props.tooltipRenderer())
      }
      open={props.isOpen}
    >
      {props.children}
    </CustomTooltip>
  );
};

export default SkyTooltip;
