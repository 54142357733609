import React, { FC, useContext, useMemo } from "react";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import cookie from "react-cookies";
import { useNavigate } from "react-router-dom";
import styles from "./profileDropdownCard.module.css";
import SkyButton, {ButtonSize} from "../../base/SkyButton/SkyButton";

type Props = {
  userName: string;
  emailId: string;
  handleProfileClick:(boolean) => void;
};

const ProfileDropdownCard: FC<Props> = (props) => {
  const { removeAccessToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleMyProfileClick = () => {
    navigate("/profile");
    props.handleProfileClick(false);
  };

  const handleLogoutButtonClick = () => {
    navigate("/login");
    removeAccessToken();
    cookie.remove("accessToken", { path: "/" });
  };

  return (
    <div className={styles.popup} data-testid="profile-card">
      <div className={styles.innerBox}>
        <div className={styles.nameInitialContainer}>
          <h2>{props.userName.substring(0, 1).toUpperCase()}</h2>
          <div className={styles.activeSymbol} />
        </div>
        <div className={styles.userDetailContainer}>
          <span className={styles.popUpName}>{props.userName}</span>
          <span className={styles.popUpId}>{props.emailId}</span>
        </div>
        <SkyButton
            text={"My Profile"}
            onClick={handleMyProfileClick}
            size={ButtonSize.SMALL}
            testId={"profile-button"}
            disabled={false}
        />
      </div>
      <div className={styles.logOutButtonContainer}>
        <SkyButton
            text={"Log Out"}
            onClick={handleLogoutButtonClick}
            size={ButtonSize.SMALL}
            testId={"logout-button"}
            disabled={false}
        />
      </div>
    </div>
  );
};
export default ProfileDropdownCard;
