import React, { FC } from "react";
import styles from "./footercomponent.module.css";

type Props = {};

const Footer: FC<Props> = () => {
  return (
    <p className={styles.copyright} data-testid="footer">
      Copyright &copy; All Right reserved by Skytel
    </p>
  );
};

export default Footer;
