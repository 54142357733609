import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./CDRContainer.module.css";
import SkyCalender from "../../business/Calendar/SkyCalender";
import Dialog, { Size } from "../../business/Dialog/Dialog";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { addDays } from "date-fns";
import DownloadIcon from "./DownloadIcon/DownloadIcon";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import CDRSearch from "../CDRSearch/CDRSearch";
import CDRSearchForm from "./CDRSearchForm";
import { downloadCDR } from "../../../api/endpoint";
import { formatDateToyyyyMMdd } from "../../../domains/Date";
import { notyf } from "../../../notyf";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import waitingimage from "../../../images/downloading.gif";
import SkyButton, {ButtonSize} from "../../base/SkyButton/SkyButton";

const CDRContainer: FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState<boolean>(false);
  const [isSearchResultDialogOpen, setIsSearchResultDialogOpen] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<(() => JSX.Element)[][]>([]);
  const columns = ["Date", "Search", "Download"];
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<string>("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [callerNumber, setCallerNumber] = useState<number>();
  const [calledNumber, setCalledNumber] = useState<number>();
  const [isSearchResultDownloading, setIsSearchResultDownloading] =
    useState<boolean>(false);
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    constructTableData(addDays(new Date(), -15), addDays(new Date(), -1));

    const now = new Date();
    const oneHourLater = new Date(new Date().getTime() + 60 * 60 * 1000);
    setStartTime(now.getHours() + ":" + now.getMinutes());
    setEndTime(oneHourLater.getHours() + ":" + oneHourLater.getMinutes());
  }, []);

  const getDiffInDays = (from: Date, to: Date) => {
    const diff = to.getTime() - from.getTime();
    return Math.ceil(diff / (1000 * 3600 * 24));
  };

  const getDaysBetweenDates = (
    startDate: moment.Moment,
    endDate: moment.Moment
  ) => {
    const now = startDate.clone();
    const dates: string[] = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("MM/DD/YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const openSearchDialog = (date: Date) => {
    setSelectedDate(date);
    setIsSearchDialogOpen(true);
  };

  const constructTableData = (from: Date, to: Date) => {
    setTableData(
      getDaysBetweenDates(moment(from), moment(to))
        .map((date) => [
          () => <>{moment(date, "MM/DD/YYYY").format("Do MMM, YYYY")}</>,
          () => (
            <span
              className={styles.searchIconWrapper}
              onClick={() =>
                openSearchDialog(moment(date, "MM/DD/YYYY").toDate())
              }
            >
              <SearchIcon
                data-testid={"search-icon"}
                className={styles.searchIcon}
              />
            </span>
          ),
          () => (
            <>
              <DownloadIcon date={moment(date, "MM/DD/YYYY").toDate()} />
            </>
          ),
        ])
        .reverse()
    );
  };

  const errorAlert = (message: string) => {
    setErrorMessage(message);
    setIsSearchDialogOpen(false);
    setIsDialogOpen(true);
  };

  const downloadSearchedSheet = (date: Date) => {
    setIsSearchResultDownloading(true);
    if (accessToken)
      downloadCDR(
        accessToken,
        formatDateToyyyyMMdd(date),
        startTime,
        endTime,
        callerNumber ? callerNumber + "" : "",
        calledNumber ? calledNumber + "" : ""
      )
        .then((cdrBlobResponse) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(new Blob([cdrBlobResponse]));
          link.setAttribute(
            "download",
            "CDR_" + formatDateToyyyyMMdd(date) + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((_) => notyf.error("Unable to download CDR list"))
        .finally(() => setIsSearchResultDownloading(false));
  };

  return (
    <div className={styles.cdrContainer} data-testid="cdr_calender_dataSheet">
      <div className={styles.calendarContainer}>
        <SkyCalender
          onChange={(from, to) => {
            const diffInDays = getDiffInDays(from, to);
            if (diffInDays > 15) {
              setErrorMessage(
                "Please select date range for maximum of 15 days."
              );
              setIsDialogOpen(true);
            } else if (diffInDays < 0) {
              setErrorMessage("End date can not be later than start date.");
              setIsDialogOpen(true);
            } else {
              constructTableData(from, to);
            }
          }}
        />
      </div>

      <div className={styles.dataContainer}>
        <SkyGrid columns={columns} renderer={tableData} isLoading={isLoading} />
      </div>

      {isDialogOpen && (
        <Dialog
          header={"Alert"}
          message={errorMessage}
          onConfirm={() => setIsDialogOpen(false)}
          testId="alert-dialog"
        />
      )}

      {isSearchDialogOpen && (
        <Dialog
          header={"Search CDR"}
          size={Size.M}
          renderer={() => (
            <CDRSearchForm
              onCancel={() => setIsSearchDialogOpen(false)}
              onSearch={(startTime, endTime, callerNumber, calledNumber) => {
                setStartTime(startTime);
                setEndTime(endTime);
                setCallerNumber(callerNumber);
                setCalledNumber(calledNumber);

                setIsSearchDialogOpen(false);
                setIsSearchResultDialogOpen(true);
              }}
              onValidationError={(errorMessage) => errorAlert(errorMessage)}
              selectedDate={selectedDate}
            />
          )}
          testId="search-dialog"
        />
      )}

      {isSearchResultDialogOpen && (
        <Dialog
          testId="search-result-dialog"
          size={Size.XL}
          defaultBackgroundColor={"white"}
          renderer={() => (
            <div
              data-testid={"cdr-search-popup-wrapper"}
              className={styles.cdrSearchDialogWrapper}
            >
              <div className={styles.cdrSearchGridContainer}>
                <CDRSearch
                  date={selectedDate}
                  startTime={startTime}
                  endTime={endTime}
                  calledNumber={calledNumber}
                  callerNumber={callerNumber}
                />
              </div>
              <div className={styles.searchResultDialogButtonWrapper}>
                {isSearchResultDownloading && (
                  <img
                    src={waitingimage}
                    className={styles.downloadIconWaiting}
                  />
                )}
                {!isSearchResultDownloading && (
                    <div className={styles.searchPopupButton}>
                        <SkyButton
                            text={"Download Search Sheet"}
                            onClick={() => downloadSearchedSheet(selectedDate)}
                            size={ButtonSize.SMALL}
                            testId={"search-result-dialog-download-button"}
                        />
                    </div>
                )}
                  <div className={styles.searchPopupButton}>
                      <SkyButton
                          text={"Cancel"}
                          onClick={() => setIsSearchResultDialogOpen(false)}
                          size={ButtonSize.SMALL}
                          testId={"search-result-dialog-cancel-button"}
                      />
                  </div>
              </div>
            </div>
          )}
          onConfirm={() => setIsSearchResultDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default CDRContainer;
