import React, { FC, useEffect, useState } from "react";
import styles from "../DetailForm.module.css";
import UploadForm from "../UploadForm/UploadForm";
import error from "../../../../icons/error-emoji.png";
import { documentToBase64, TaxDetails } from "../../../../domains/KYC";
import SkyInputBox from "../../../base/SkyInputBox/SkyInputBox";
import DocumentUploader from "../DocumentUploader/DocumentUploader";

type TaxDetailsFormProps = {
  kycData: TaxDetails;
  onChange: (details: TaxDetails) => void;
};

const TaxDetailsForm: FC<TaxDetailsFormProps> = (props) => {
  const [companyNumber, setCompanyNumber] = useState<string>(
    props.kycData.companyNumber
  );
  const [taxNumber, setTaxNumber] = useState<string>(
    props.kycData.taxNumber
  );
  const [isOpenUploadOption, setIsOpenUploadOption] = useState<boolean>(false);
  const [errorMessage] = useState<string | null>(null);
  const [supportingDocument, setSupportingDocument] = useState<string>(
    props.kycData.supportingDocument
  );
  const handleUploadDoc = () => {
    setIsOpenUploadOption(true);
  };

  const handleUploadDocClose = () => {
    setIsOpenUploadOption(false);
  };

  const handleFileSelect = async (file: File) => {
    if (file instanceof File) {
      const base64 = await documentToBase64(file);
      setSupportingDocument(base64 as string);
    } else {
      setSupportingDocument(file as string);
    }
  };

  useEffect(() => {
    props.onChange({
      companyNumber: companyNumber,
      taxNumber: taxNumber,
      supportingDocument: supportingDocument,
    });
  }, [companyNumber, taxNumber, supportingDocument]);

  return (
    <div className={styles.formContainer} data-testid="tax-details-form">
      {isOpenUploadOption ? (
        <UploadForm
          onClose={handleUploadDocClose}
          onFileSelect={handleFileSelect}
        />
      ) : (
        <div className={styles.wrapperContainer}>
          <div className={styles.leftContainer}>
            <DocumentUploader
              handleUploadDoc={handleUploadDoc}
              supportingDocument={supportingDocument}
              supportedDocumentsHelpText={"PAN Card, GST / VAT Registration Certificate"}
            />
          </div>
          <div className={styles.rightContainer}>
            <label>
              Required for taxation purpose
              <br />
              (All fields are mandatory)
            </label>
            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setCompanyNumber(value as string)}
                placeholder={"Company Number / CIN"}
                type={"text"}
                value={companyNumber}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setTaxNumber(value as string)}
                placeholder={"Vat / GST / Tax Number"}
                type={"text"}
                value={taxNumber}
              />
            </div>

            {errorMessage && (
              <div className={styles.error}>
                <img src={error} alt="error-icon" />
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default TaxDetailsForm;
