import React, {FC, useEffect, useState} from "react";
import styles from "./AreaChartWidget.module.css";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {Animation} from "@mui/icons-material";
import {areaChartDummyData} from "../../../utils/dummyData";

export type AreaChartData = {
  name: string;
  firstDataPoint: number;
  secondDataPoint: number;
};

export type Props = {
  data: AreaChartData[];
  isDummyData?: boolean;
};

const AreaChartWidget: FC<Props> = (props) => {
  const [areaChartData, setAreaChartData] = useState<AreaChartData[]>([]);

  useEffect(() => {
    setAreaChartData(props.data);
  }, [props.data]);

  return (
    <div
      className={styles.widgetContainer}
      data-testid={"area-chart-container"}
    >
      <div className={styles.header}>
        Charge & Call Count for last week
      </div>
      <div className={styles.overlayTextContainer}>
        {props.isDummyData && (
          <div className={styles.overlayText}>
            Mocked Data
          </div>)}
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={areaChartData}>
            <defs>
              <linearGradient id="violet" x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor="#d87be8" stopOpacity={1}/>
                <stop offset="75%" stopColor="#d87be8" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="blue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor="#b78bff" stopOpacity={1}/>
                <stop offset="75%" stopColor="#b78bff" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <Animation/>
            <CartesianGrid strokeOpacity={0}/>
            <XAxis
              dataKey="name"
              tick={{fill: "#7d78f8"}}
              tickLine={{stroke: "transparent"}}
              axisLine={false}
            />
            <YAxis
              yAxisId="left"
              axisLine={false}
              tick={{fill: "#7d78f8"}}
              tickLine={{stroke: "transparent"}}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              axisLine={false}
              tick={{fill: "#D981E9"}}
              tickLine={{stroke: "transparent"}}
            />
            <Tooltip/>
            <Legend/>
            <Area
              fillOpacity="1"
              type="monotone"
              dataKey="secondDataPoint"
              stackId="1"
              stroke="#a21dbf"
              fill={`url(#violet)`}
              yAxisId={"right"}
              name={"Charges"}
            />
            <Area
              fillOpacity="1"
              type="monotone"
              dataKey="firstDataPoint"
              stackId="1"
              stroke="#4f14af"
              fill={`url(#blue)`}
              yAxisId={"left"}
              name={"Call Count"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AreaChartWidget;
