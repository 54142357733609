import React, { FC, useEffect, useState } from "react";
import styles from "./PasswordVerificationScreen.module.css";
import passwordVerification from "../../images/PasswordVerification.png";
import errorIcon from "../../icons/error-emoji.png";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { completeIPAdd, fetchIPDetails } from "../../api/endpoint";
import DotLoader from "../../components/business/DotLoader/DotLoader";
import { AddIPVerificationDetailsResponse } from "../../models/response/AddIPVerificationDetailsResponse";
import SkyButton, {ButtonSize} from "../../components/base/SkyButton/SkyButton";

enum Status {
  TokenValidationStarted,
  TokenValidationSuccess,
  TokenValidationFailed,
  IPDetailsRetrievalNotStarted,
  IPDetailsRetrievalStarted,
  IPDetailsRetrievalSuccess,
  IPDetailsRetrievalFailed,
  PasswordValidationNotStarted,
  PasswordValidationStarted,
  PasswordValidationSuccess,
  PasswordValidationFailed,
  PasswordValidationNotRequired,
  PasswordValidationRequired,
}

const PasswordVerificationScreen: FC = () => {
  const [ip, setIp] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [requestId, setRequestId] = useState("");
  const [messageHeader, setMessageHeader] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [tokenValidationStatus, setTokenValidationStatus] = useState(
    Status.TokenValidationStarted
  );
  const [ipDetailsRetrievalStatus, setIPDetailsRetrievalStatus] = useState(
    Status.IPDetailsRetrievalNotStarted
  );
  const [passwordValidationStatus, setPasswordValidationStatus] = useState(
    Status.PasswordValidationNotStarted
  );
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState("");

  const urlSearchParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const requestId = urlSearchParams.get("requestId");
    const errorString = urlSearchParams.get("error");

    if (requestId)
      setRequestId(requestId);
    else if (errorString) {
      setMessageHeader(errorString);
      setMessageBody("Unable to add IP");
    }
    else {
      setMessageHeader("Invalid Link!");
      setMessageBody("Unable to add IP");
      setTokenValidationStatus(Status.TokenValidationFailed)

      return;
    }

    errorString
      ? setTokenValidationStatus(Status.TokenValidationFailed)
      : setTokenValidationStatus(Status.TokenValidationSuccess);

    if (tokenValidationStatus === Status.TokenValidationSuccess && requestId) {
      setIPDetailsRetrievalStatus(Status.IPDetailsRetrievalStarted);
      fetchIPDetails(requestId)
        .then((ipDetailsResponse: AddIPVerificationDetailsResponse) => {
          setIPDetailsRetrievalStatus(Status.IPDetailsRetrievalSuccess);
          if (ipDetailsResponse.ipStatus !== "EMAIL_VERIFIED") {
            setPasswordValidationStatus(Status.PasswordValidationNotRequired);
            setMessageHeader("Invalid Link!");
            setMessageBody("This link is not valid anymore");
          } else {
            setIp(ipDetailsResponse.ip);
            setPasswordValidationStatus(Status.PasswordValidationRequired);
          }
        })
        .catch((e) => {
          if(e.response.status === 404)
            setMessageHeader("Invalid Link!");
          else
            setMessageHeader("Something went wrong!");
          setMessageBody("Unable to add IP");
          setIPDetailsRetrievalStatus(Status.IPDetailsRetrievalFailed);
        });
    }
  }, [requestId]);

  const addIP = () => {
    if (!userPassword) return;

    setPasswordValidationStatus(Status.PasswordValidationStarted);
    completeIPAdd({
      requestId: requestId,
      password: userPassword,
    })
      .then((_) => {
        setPasswordValidationStatus(Status.PasswordValidationSuccess);

        setMessageHeader("Submit Successfully");
        setMessageBody(`IP ${ip} has been added`);
      })
      .catch((e) => {
        setPasswordValidationStatus(Status.PasswordValidationFailed);
        if (e.response.status == 403) {
          setPasswordValidationMessage("Invalid Password");
        } else {
          setPasswordValidationMessage("Something went wrong!");
        }
      });
  };

  return (
    <>
      <div
        className={styles.pwdVerificationContainer}
        data-testid={"pwd-verification-container"}
      >
        <div className={styles.pwdVerificationContent}>
          <div className={styles.imageContainer}>
            <img
              src={passwordVerification}
              alt="Password Verification Image"
              className={styles.pwdVerificationImage}
            />
          </div>

          {ipDetailsRetrievalStatus === Status.IPDetailsRetrievalStarted && (
            <DotLoader />
          )}

          {ipDetailsRetrievalStatus === Status.IPDetailsRetrievalSuccess &&
            (passwordValidationStatus === Status.PasswordValidationRequired ||
              passwordValidationStatus === Status.PasswordValidationFailed ||
              passwordValidationStatus ===
                Status.PasswordValidationStarted) && (
              <div data-testid={"password-validation-form"}>
                <div className={styles.pwdVerificationText}>
                  <p>
                    Thanks for verifying your email ID.
                    <br />
                    Please enter your password to add the IP.
                  </p>
                </div>
                <div
                  className={classNames(
                    styles.pwdVerificationText,
                    styles.blueFont
                  )}
                >
                  <span data-testid={"ip-to-be-added"}>IP : {ip}</span>
                </div>
                <div className={styles.pwdVerificationForm}>
                  <p>Enter Password</p>
                  <input
                    className={styles.roundControl}
                    onChange={(ev) => setUserPassword(ev.target.value)}
                    type="password"
                    data-testid={"password-field"}
                  />

                  {passwordValidationStatus ===
                    Status.PasswordValidationFailed && (
                    <div
                      className={styles.errorMessageContainer}
                      data-testid={`password-validation-error-message`}
                    >
                      <img
                        src={errorIcon}
                        alt="error icon"
                        className={styles.errorIcon}
                      />
                      {passwordValidationMessage}
                    </div>
                  )}

                  {passwordValidationStatus ===
                  Status.PasswordValidationStarted ? (
                    <div className={styles.dotLoader}>
                      <DotLoader />
                    </div>
                  ) : (
                      <SkyButton
                          size={ButtonSize.SMALL}
                          onClick={addIP}
                          testId="submit-button"
                          renderer={() => (
                              <div className={styles.roundedButton}>
                                Submit
                              </div>
                          )}
                      />
                  )}
                </div>
              </div>
            )}

          {(tokenValidationStatus === Status.TokenValidationFailed ||
            passwordValidationStatus === Status.PasswordValidationSuccess ||
            passwordValidationStatus === Status.PasswordValidationNotRequired ||
            ipDetailsRetrievalStatus === Status.IPDetailsRetrievalFailed) && (
            <div
              className={styles.messageContainer}
              data-testid={"message-container"}
            >
              <p
                className={classNames(
                  styles.messageHead,
                  passwordValidationStatus === Status.PasswordValidationSuccess
                    ? styles.blueFont
                    : styles.redFont
                )}
                data-testid={"message-header"}
              >
                {messageHeader}
              </p>
              <span className={styles.messageText} data-testid={"message-text"}>
                {messageBody}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PasswordVerificationScreen;
