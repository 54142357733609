import React, { FC, useState } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import styles from "./UploadForm.module.css";
import error from "../../../../icons/error-emoji.png";
import Webcam from "react-webcam";
import SkyButton, { ButtonSize } from "../../../base/SkyButton/SkyButton";
import cameraIcon from "../../../../icons/camera.png";
import galleryIcon from "../../../../icons/gallery.png";
import uploadImage from "../../../../images/upload image.png";

type UploadFormProps = {
  onClose: () => void;
  onFileSelect: (file: File) => void;
};

const UploadForm: FC<UploadFormProps> = (props) => {
  const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const webcamRef = React.useRef(null);

  const capture = () => {
    if (webcamRef && webcamRef.current) {
      // @ts-ignore
      props.onFileSelect(webcamRef.current.getScreenshot());
      props.onClose();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setErrorMessage("Only jpg and png files are allowed.");
        return;
      }

      if (file.size > 150000) {
        setErrorMessage("File size should be less than 150 kB.");
        return;
      }
      props.onFileSelect(file);
      props.onClose();
    }
  };

  return (
    <div className={styles.uploadContainer}>
      <ArrowBackRoundedIcon
        data-testid="back-icon"
        className={styles.arrowBackIcon}
        onClick={props.onClose}
      />
      {isCameraOpen ? (
        <div
          className={styles.webcamContainer}
          data-testid={"webcam-container"}
        >
          <Webcam
            audio={false}
            height={400}
            ref={webcamRef}
            width={400}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: 400,
              height: 400,
              facingMode: "user",
            }}
          />
          <div className={styles.cameraButtonContainer}>
            <SkyButton
              size={ButtonSize.SMALL}
              onClick={capture}
              text={"Capture"}
              testId={"capture-button"}
              disabled={false}
            />
            <SkyButton
              size={ButtonSize.SMALL}
              onClick={() => setIsCameraOpen(false)}
              text={"Cancel"}
              testId={"cancel-button"}
              disabled={false}
            />
          </div>
        </div>
      ) : (
        <>
          <div
            className={styles.uploadButtonContainer}
            data-testid="file-upload-button"
          >
            <img
                src={uploadImage}
                alt="upload image"
                className={styles.uploadImage}
                data-testid="upload-image"
            />
            {errorMessage && (
              <div className={styles.errorMessage}>
                <img src={error} alt="error-icon" />
                {errorMessage}
              </div>
            )}
            <div className={styles.uploadText}>Upload Your Document</div>
            <div className={styles.uploadButton}>
              <label
                  htmlFor="file-input"
                  className={styles.uploadFromDeviceContainer}
              >
                <img
                    src={galleryIcon}
                    alt="gallery icon"
                    className={styles.galleryIcon}
                    data-testid="gallery-icon"
                />
                <input
                    data-testid="file-input"
                    id="file-input"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleFileChange}
                    hidden
                />
              </label>
              <img
                  src={cameraIcon}
                  alt="camera icon"
                  onClick={() => setIsCameraOpen(true)}
                  data-testid="use-camera-button"
                  className={styles.cameraIcon}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default UploadForm;
