import React, { FC, useContext, useEffect, useRef, useState } from "react";
import styles from "./CSRContainer.module.css";
import SkyCalender from "../../business/Calendar/SkyCalender";
import Dialog from "../../business/Dialog/Dialog";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import { fetchDailySummaryReport } from "../../../api/endpoint";
import { formatDateToyyyyMMdd } from "../../../domains/Date";
import { DailyAccountSummaryReportResponse } from "../../../models/response/DailyAccountSummaryReportResponse";
import { notyf } from "../../../notyf";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import { addDays } from "date-fns";
import moment from "moment";
import { CSVLink } from "react-csv";
import SkyButton, {ButtonSize} from "../../base/SkyButton/SkyButton";

const CSRContainer: FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<string[][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const columns = ["Date", "Total Mins", "ASR", "ACD", "Charges", "Call Count"];
  const { accessToken } = useContext(AuthContext);
  const [dataForDownload, setDataForDownload] = useState<string[][]>([]);
  const csvLinkEl = useRef<CSVLink | null>(null);

  useEffect(() => {
    getReportData(addDays(new Date(), -15), addDays(new Date(), -1));
  }, []);

  const getDiffInDays = (from: Date, to: Date) => {
    const diff = to.getTime() - from.getTime();
    return Math.ceil(diff / (1000 * 3600 * 24));
  };

  const getReportData = (fromDate: Date, toDate: Date) => {
    setIsLoading(true);
    fetchDailySummaryReport(
      accessToken ? accessToken : "",
      formatDateToyyyyMMdd(fromDate),
      formatDateToyyyyMMdd(toDate)
    )
      .then((responses: DailyAccountSummaryReportResponse[]) => {
        try {
          const formattedData = responses.map((response) => [
            moment(new Date(response.date)).format("Do MMM, YYYY"),
            response.callDuration + "",
            response.asr + "",
            response.acd + "",
            response.charge + "",
            response.callCount + "",
          ]);
          setTableData(formattedData.reverse());
          setDataForDownload(formattedData);
        } finally {
          setIsLoading(false);
        }
      })
      .catch(() => {
        notyf.error("Unable to fetch report data");
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.csrContainer} data-testid="csr_calender_dataSheet">
      <div className={styles.calendarContainer}>
        <SkyCalender
          onChange={(from, to) => {
            const diffInDays = getDiffInDays(from, to);
            if (diffInDays > 15) {
              setErrorMessage(
                "Please select date range for maximum of 15 days."
              );
              setIsDialogOpen(true);
            } else if (diffInDays < 0) {
              setErrorMessage("End date can not be later than start date.");
              setIsDialogOpen(true);
            } else {
              getReportData(from, to);
            }
          }}
        />
      </div>
      <div className={styles.dataContainer} data-testid="data-sheet">
        <SkyGrid columns={columns} data={tableData} isLoading={isLoading} />
        <div className={styles.buttonContainer}>
          {dataForDownload.length > 0 && (
              <SkyButton
                  text={"Download"}
                  onClick={() => {
                      if (csvLinkEl.current) {
                          try {
                              csvLinkEl.current.link.click();
                          } catch (error) {
                              notyf.error("Unable to download file");
                          }
                      }
                  }}
                  size={ButtonSize.SMALL}
                  disabled={isLoading || dataForDownload.length === 0}
                  testId={"download-button"}
              />
          )}
        </div>
      </div>
      {isDialogOpen && (
        <Dialog
          header={"Alert"}
          message={errorMessage}
          onConfirm={() => setIsDialogOpen(false)}
          testId="alert-dialog"
        />
      )}
      <CSVLink
        headers={columns}
        filename="Daywise_CSR_Report.csv"
        data={dataForDownload}
        ref={csvLinkEl}
        data-testid="csv-link"
      />
    </div>
  );
};

export default CSRContainer;
