import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./CountrySelector.module.css";
import classNames from "classnames";
import * as AllCountry from "react-flags-select";
import internationalFlag from "../../../icons/InternationalFlag.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { Country } from "../../service/IPTrunkList/IPAdd/IPAddForm";

export type Props = {
  countries: Country[];
  maxAllowedSelection?: number;
  onError?: (message: string) => void;
  onChange?: (countryCodes: string[]) => void;
};

const CountrySelector: FC<Props> = (props) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [error, setError] = useState("");
  const [searchString, setSearchString] = useState("");

  const countrySelectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCountries(props.countries);

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [props.countries]);

  useEffect(() => {
    props.onError && props.onError(error);
  }, [error]);

  const countryCodeToPascalCase = (countryCode: string): string => {
    return `${countryCode.slice(0, 1)}${countryCode.charAt(1).toLowerCase()}`;
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      countrySelectorRef.current &&
      !countrySelectorRef.current.contains(event.target as Node)
    ) {
      closeDropdown();
    }
  };

  const closeDropdown = () => {
    setIsExpanded(false);
    setSearchString("");
  };

  const getCountryFlag = (countryCode) => {
    if (countryCode === "INT")
      return (
        <div className={styles.intFlagIconContainer}>
          <img
            src={internationalFlag}
            data-testid={"country-flag-INT"}
            alt="int-flag-icon"
            className={styles.internationalFlagIcon}
          />
        </div>
      );

    const props = {};
    props["data-testid"] = `country-flag-${countryCode}`;
    return React.createElement(
      AllCountry[countryCodeToPascalCase(countryCode)],
      { ...props }
    );
  };

  const removeCountry = (countryCode) => {
    setCountries(
      countries.map((country) => {
        if (country.code === countryCode) country.isSelected = false;
        return country;
      })
    );
    setError("");
  };

  const getSelectedCountries = () =>
    countries.filter((country) => country.isSelected);

  return (
    <div
      className={styles.countrySelectorContainer}
      data-testid={"country-selector"}
    >
      <div
        className={classNames(
          styles.countrySelectorButton,
          styles.roundedContainer
        )}
        data-testid={"country-selector-outer-container"}
      >
        {countries
          .filter((country) => country.isSelected)
          .map((country) => (
            <div
              key={country.code}
              data-testid={`country-card-${country.code}`}
              className={styles.selectedCountryCard}
            >
              {getCountryFlag(country.code)}
              <span data-testid={"country-card-text"}>{country.code}</span>
              <ClearIcon
                onClick={() => {
                  removeCountry(country.code);
                }}
                data-testid={`remove-icon-${country.code}`}
                className={styles.clearIcon}
                style={{ fontSize: "0.5em" }}
              />
            </div>
          ))}
        <input
          type="text"
          placeholder={
            getSelectedCountries().length == 0 ? "Search Country" : ""
          }
          className={styles.searchInputBox}
          data-testid={"country-search-box"}
          value={searchString}
          onChange={(e) => {
            setSearchString((e.target as HTMLInputElement).value);
            setIsExpanded(true);
          }}
        />
      </div>

      {isExpanded &&
        searchString.length > 0 &&
        countries?.filter((country) =>
          country.name.toLowerCase().startsWith(searchString.toLowerCase())
        ).length > 0 && (
          <div
            className={classNames(
              styles.countrySelectorDropdown,
              styles.roundedContainer
            )}
            ref={countrySelectorRef}
            data-testid={"country-selector-options-container"}
          >
            <div className={styles.countrySelectorOptionContainer}>
              {countries
                ?.filter((country) =>
                  country.name
                    .toLowerCase()
                    .startsWith(searchString.toLowerCase())
                )
                .map((country) => {
                  return (
                    <div
                      className={styles.countrySelectorOption}
                      data-testid={`country-option-${country.code}`}
                      key={country.code}
                      onClick={() => {
                        setIsExpanded(false);
                        setSearchString("");
                        if (
                          getSelectedCountries().length ==
                          props.maxAllowedSelection
                        ) {
                          setError(
                            "Max " +
                              props.maxAllowedSelection +
                              " countries are allowed"
                          );
                          return;
                        }
                        setCountries(
                          countries.map((clickedCountry) => {
                            if (clickedCountry.code == country.code)
                              clickedCountry.isSelected = true;
                            return clickedCountry;
                          })
                        );
                      }}
                    >
                      {getCountryFlag(country.code)}
                      <span> {country.name} </span>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
    </div>
  );
};

export default CountrySelector;
