import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import paymentFailed from "../../../images/rechargeFailed.png";
import styles from "./RechargeFailure.module.css";
import SkyButton, {ButtonSize} from "../../../components/base/SkyButton/SkyButton";

export type Props = {
  isBalanceUpdated: boolean;
  gatewayStatus: string;
  errorCode: string;
};

const RechargeFailure: FC<Props> = (props) => {
  const navigate = useNavigate();

  const redirctToPaymentPage = () => {
    navigate("/recharge");
  };

  return (
    <div className={styles.container}>
      <div className={styles.roundWhiteContainer}>
        <p className={styles.headerText}>Recharge Failed</p>
        <img
          alt="payment failed"
          className={styles.failureImage}
          src={paymentFailed}
        />
        <div className={styles.bottomContainer}>
          <p className={styles.messageHeader}>Your payment has failed</p>
          <p className={styles.messageSubHeader}>
            Gateway Status Code:{" "}
            {props.gatewayStatus != "" ? props.gatewayStatus : "UNKNOWN"}
          </p>
          <p className={styles.messageSubHeader}>
            Error Code: {" "}
            {props.errorCode !== "" ? props.errorCode : "UNKNOWN"}
          </p>
          <p className={styles.messageText}>
            {props.gatewayStatus != "SUCCEEDED" && (
              <>You can retry the payment below to continue this.</>
            )}
            {props.gatewayStatus == "SUCCEEDED" && (
              <>
                Please contact with <a>support@skytelservices.com</a> before
                retrying the payment again.
              </>
            )}
          </p>
        </div>
        {props.gatewayStatus != "SUCCEEDED" && (
          <>
            <SkyButton
                text={"Retry"}
                size={ButtonSize.SMALL}
                onClick={redirctToPaymentPage}
            />
            <p className={styles.messageText}>
              Please contact support@skytelservices.com for any assistance.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default RechargeFailure;
