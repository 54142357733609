const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const formatDateToyyyyMMdd = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const updatedMonth = (month + "").length == 1 ? "0" + month : month + "";
  const updatedDay = (day + "").length == 1 ? "0" + day : day;

  return date.getFullYear() + "" + updatedMonth + "" + updatedDay;
};

export const parseSlashedFormat = (value: any): Date => {
  if (typeof value === "string" && value.includes("/")) {
    const str = value.split("/");

    const year = Number(str[2]);
    const month = Number(str[1]) - 1;
    const date = Number(str[0]);

    return new Date(year, month, date);
  } else if (typeof value === "string" && value === "") {
    return new Date();
  }
  const timestamp = typeof value === "number" ? value : Date.parse(value);
  return new Date(timestamp);
};

export const getDayOfWeek = (date: Date) => {
  return weekDays[date.getDay()];
};
