import {createContext} from "react";


export type AuthContextType = {
    accessToken: string | null;
    setAccessToken: (accessToken: string | null) => void;
    removeAccessToken: () => void;

};

export const AuthContext = createContext<AuthContextType>({
    accessToken: null,
    setAccessToken: () => {
    },
    removeAccessToken: () => {
    }
});