import React, { FC, useEffect, useState } from "react";
import styles from "./ListWidget.module.css";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import DotLoader from "../../../components/business/DotLoader/DotLoader";
import SkyButton, {ButtonSize} from "../../../components/base/SkyButton/SkyButton";

export type ListData = {
  header: string;
  text: string;
  buttonText: string;
  buttonOnClick: (date: string) => void;
};

type Props = {
  data: ListData[];
  isLoading: boolean;
};

const ListWidget: FC<Props> = (props) => {
  return (
    <div
      className={styles.widgetContainer}
      data-testid={"list-widget-container"}
    >
      {props.isLoading && <DotLoader />}
      {!props.isLoading && (
        <>
          <div className={styles.header}> Quick Access </div>
          <div className={styles.listContainer}>
            {props.data.map((data) => (
              <div
                className={styles.listItem}
                key={data.header}
                data-testid="list"
              >
                <div className={styles.iconContainer}>
                  <div className={styles.icon}>
                    <PermPhoneMsgIcon className={styles.iconElement} />
                  </div>
                </div>
                <div className={styles.infoContainer}>
                  <div
                    className={styles.infoHeader}
                    data-testid={"list-widget-header"}
                  >
                    {data.header}
                  </div>
                  <div
                    className={styles.infoDetails}
                    data-testid={"list-widget-text"}
                  >
                    {data.text}
                  </div>
                </div>
                <div className={styles.buttonContainer}>
                  <SkyButton
                      text={data.buttonText}
                      onClick={() => data.buttonOnClick(data.header)}
                      size={ButtonSize.SMALL}
                      testId={"cdr-button"}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ListWidget;
