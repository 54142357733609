import React, {FC, useEffect, useState} from "react";
import styles from "./PieChartWidget.module.css";
import {Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {pieChartDummyData} from "../../../utils/dummyData";

export type PieChartData = {
  name: string;
  value: number;
};

export type Props = {
  pieChartData: PieChartData[];
  isDummyData?: boolean;
};

const PieChartWidget: FC<Props> = (props) => {
  const colors = [
    "#6829ec",
    "#865cf6",
    "#4d3896",
    "#e144b4",
    "#e1009c",
    "#c783fd",
    "#eeddfd",
    "#8a43a1",
    "#6ecfef",
    "#2dbfb5",
  ];
  type ChartData = {
    name?: string;
    value?: number;
    fill?: string;
  };
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [legendFontSize, setLegendFontSize] = useState<string>("0.8vw");

  useEffect(() => {
    setChartData(
      props.pieChartData
        .slice(
          0,
          props.pieChartData.length > 10 ? 10 : props.pieChartData.length
        )
        .map((data, index) => {
          return {
            name: data.name,
            value: data.value,
            fill: colors[index],
          };
        })
    );
  }, [props.pieChartData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 425) {
        setLegendFontSize("0.8rem");
      } else if (window.innerWidth > 425 && window.innerWidth <= 769) {
        setLegendFontSize("0.7rem");
      } else {
        setLegendFontSize("0.8vw");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.widgetContainer} data-testid={"pie-chart-container"}>
      <div className={styles.header}>Destination for last week</div>
      {props.isDummyData && (
        <div className={styles.overlayText}>
          Mocked Data
        </div>)}
      <ResponsiveContainer width="100%" height="80%">
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={chartData}
            fill="#8884d8"
          />
          <Legend wrapperStyle={{fontSize: legendFontSize}}/>
          <Tooltip/>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartWidget;
