import React, { FC, useEffect, useState } from "react";
import styles from "./skyGrid.module.css";
import classNames from "classnames";
import DotLoader from "../../business/DotLoader/DotLoader";
import emptyTableImage from "../../../images/emptyTable.png";

export type Props = {
  colorBarSpan?: number;
  columns?: string[];
  headerRenderer?: (() => JSX.Element)[];
  data?: string[][];
  renderer?: (() => JSX.Element)[][];
  isLoading?: boolean;
  hideColumns?: number[];
};

const SkyGrid: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1024);

  useEffect(() => {
    setIsLoading(props.isLoading ? props.isLoading : false);
  }, [props.isLoading]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const isEmpty = () => {
    if (props.data) return props.data.length === 0;
    if (props.renderer) return props.renderer.length === 0;
  };

  const getColumnCount = () => (props.columns ? props.columns.length : 0);
  const getHeaderRendererCount = () => (props.headerRenderer ? props.headerRenderer.length : 0);
  const getColorBarCount = () => ((props.colorBarSpan ?? 0) - (props.hideColumns?.length ?? 0));

  const colorBarColSpan: number = (isMobile ? getColorBarCount() : props.colorBarSpan)
                                  || getColumnCount()
                                  || getHeaderRendererCount()
                                  || 0;

  const colorBarWidth = () => {
    if (props.columns) {
      const adjustedColumns = isMobile ?
        props.columns.length - (props.hideColumns?.length ?? 0) : props.columns.length;
      return (94 / adjustedColumns) * colorBarColSpan;
    } else if (props.headerRenderer) {
      const adjustedHeaders = isMobile ?
        props.headerRenderer.length - (props.hideColumns?.length ?? 0) : props.headerRenderer.length;
      return (94 / adjustedHeaders) * colorBarColSpan;
    }
  };

  const shouldHideColumn = (index: number) => {
    return isMobile && props.hideColumns?.includes(index);
  };

  return (
    <>
      <div
        style={{width: colorBarWidth() + "%"}}
        className={classNames(
          styles.multiColorBar,
          isLoading ? styles.loading : "",
          colorBarColSpan == 3
            ? styles.threeColBar
            : colorBarColSpan == 4
            ? styles.fourColBar
            : colorBarColSpan == 5
            ? styles.fiveColBar
            : colorBarColSpan == 6
            ? styles.sixColBar
            : ""
        )}
        data-testid="multiColorBar"
      />
      <div className={styles.table} data-testid="sky-grid">
        <div className={styles.headerContainer}>
          {props.columns &&
            props.columns.map((column, index) => (
              !shouldHideColumn(index) ? (
                <div className={styles.headerCell} key={index}>
                  {column}
                </div>
              ) : null
            ))}
          {props.headerRenderer &&
            props.headerRenderer.map((header, index) => (
              !shouldHideColumn(index) ? (
                <div className={styles.headerCell} key={index}>
                  {header()}
                </div>
              ) : null
            ))
          }
        </div>
        {isLoading && (
          <div className={styles.empty} data-testid="dotLoader">
            <DotLoader />
          </div>
        )}
        {!isLoading && (
          <>
            {isEmpty() && (
              <div className={styles.bodyContainer}>
                <img src={emptyTableImage} className={styles.emptyTableImage} alt="Empty Table"/>
              </div>
            )}
            {!isEmpty() && (
              <div className={styles.bodyContainer}>
                {props.data &&
                  props.data.map((row, ri) => (
                    <div
                      key={ri}
                      className={styles.rowContainer}
                      data-testid="table-row"
                    >
                      {row.map((col, ci) => (
                        !shouldHideColumn(ci) ? (
                          <div className={styles.bodyCell} key={ri + "-" + ci}>
                            {col}
                          </div>
                        ) : null
                      ))}
                    </div>
                  ))}
                {props.renderer &&
                  props.renderer.map((row, ri) => (
                    <div
                      key={ri}
                      className={styles.rowContainer}
                      data-testid="table-row"
                    >
                      {row.map((cellRenderer, ci) => (
                        !shouldHideColumn(ci) ? (
                          <div className={styles.bodyCell} key={ri + "-" + ci}>
                            {cellRenderer()}
                          </div>
                        ) : null
                      ))}
                    </div>
                  ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SkyGrid;