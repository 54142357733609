import React, {FC, useContext, useEffect, useState} from "react";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import {AuthContext} from "../../../contextApi/AuthContext/authContext";
import {fetchIpTrunkList, switchIPStatus} from "../../../api/endpoint";
import {notyf} from "../../../notyf";
import AddIcon from "@mui/icons-material/Add";
import {IPTrunkListResponse} from "../../../models/response/IPTrunkListResponse";
import styles from "./IPTrunkList.module.css";
import classNames from "classnames";
import Dialog, {Size} from "../../business/Dialog/Dialog";
import IPAddForm from "./IPAdd/IPAddForm";
import SkyTooltip from "../../base/SkyTooltip/SkyTooltip";
import DotLoader from "../../business/DotLoader/DotLoader";
import SkyButton, {ButtonSize} from "../../base/SkyButton/SkyButton";

enum IPStateChangeStatus {
  NOT_STARTED,
  STARTED,
  SUCCESS,
  ERROR,
}

const IPTrunkList: FC = () => {
  const {accessToken} = useContext(AuthContext);
  const [isAddIPDialogOpen, setIsAddIPDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ipStateChangeStatus, setIpStateChangeStatus] = useState(
    IPStateChangeStatus.NOT_STARTED
  );
  const [processingIPForStateChange, setProcessingIPForStateChange] = useState<{
    gatewayName: string;
    ip: string;
    status: number;
  } | null>(null);
  const [ipTrunkList, setIpTrunkList] = useState<IPTrunkListResponse[]>([]);

  const getCustomHeaderRenderer = () => {
    return [
      () => <span className={styles.rowHeader}>{"Type"}</span>,
      () => <span className={styles.rowHeader}>{"End Point"}</span>,
      () => <span className={styles.rowHeader}>{"Capacity"}</span>,
      () => <span className={styles.rowHeader}>{"IPs"}</span>,
      () => (
        <SkyButton
          size={ButtonSize.FIT_CONTENT}
          onClick={openAddIpDialog}
          testId="add-ip-button"
          renderer={() => (
            <div className={styles.addIPBtn}>
              <AddIcon/>
              <span> Add IP </span>
            </div>
          )}
        />
      )
    ];
  };

  const openAddIpDialog = () => {
    setIsAddIPDialogOpen(true);
  };

  const getRowElementStateClass = (isAdminDisabled) => {
    return isAdminDisabled ? styles.disable : styles.enable;
  };

  const onIpStateSwitchConfirm = () => {
    if (!accessToken || !processingIPForStateChange) return;

    setIpStateChangeStatus(IPStateChangeStatus.STARTED);

    const ipStateChangeRequest = {
      gatewayName: processingIPForStateChange.gatewayName,
      ip: processingIPForStateChange.ip,
      status: processingIPForStateChange.status === 1 ? "INACTIVE" : "ACTIVE",
    };
    switchIPStatus(accessToken, ipStateChangeRequest)
      .then((response) => {
        if (response === false) {
          setIpStateChangeStatus(IPStateChangeStatus.ERROR);
          return;
        }

        ipTrunkList.filter(
          (ipTrunk) =>
            ipTrunk.gatewayName === processingIPForStateChange.gatewayName
        )[0].callLevel = processingIPForStateChange.status === 1 ? 1 : 5;
        setProcessingIPForStateChange(null);
        setIpStateChangeStatus(IPStateChangeStatus.SUCCESS);
      })
      .catch(() => setIpStateChangeStatus(IPStateChangeStatus.ERROR));
  };

  useEffect(() => {
    fetchIpTrunkList(accessToken ? accessToken : "")
      .then((ipTrunkResponses: IPTrunkListResponse[]) =>
        setIpTrunkList(ipTrunkResponses)
      )
      .catch(() => {
        notyf.error("Unable to fetch IP Trunk list");
      })
      .finally(() => setIsLoading(false));
  }, [accessToken]);

  function getTooltipElementForAdminDisabledIP() {
    return (
      <div className={styles.toolTip}>
        {"This IP is disabled by Admin."}
        <br/> <br/>
        {"For any assistance, please contact "}{" "}
        <a href="mailto:support@skytelservices.com">
          {"support@skytelservices.com"}
        </a>
      </div>
    );
  }

  const getRowElement = (isAdminDisabled, value) => (
    <span className={getRowElementStateClass(isAdminDisabled)}>{value}</span>
  );

  const getToggleSwitch = (isAdminDisabled, isActive, ipTrunk) => (
    <SkyTooltip
      tooltipRenderer={() => !isAdminDisabled ? null : getTooltipElementForAdminDisabledIP()}
      maxWidth={200}
    >
      <label
        className={classNames(
          styles.switch,
          isAdminDisabled ? styles.disabledSwitch : ""
        )}
        data-testid="enable-disable-ip-toggle"
      >
        <input
          type="checkbox"
          data-testid={"enable-disable-ip-toggle-checkbox"}
          checked={isActive}
          onChange={() => {
            setProcessingIPForStateChange({
              gatewayName: ipTrunk.gatewayName,
              ip: ipTrunk.remoteIP,
              status: isActive ? 1 : 0,
            });
          }}/>

        <span
          className={classNames(
            styles.slider,
            styles.round,
            isAdminDisabled ? styles.sliderGray : styles.sliderBlue
          )}
        ></span>
      </label>
    </SkyTooltip>
  );

  const getIpTrunkCell = () => {
    try {
      return ipTrunkList.map((ipTrunk) => {
        let isActive = ipTrunk.lockType === 0 && ipTrunk.callLevel === 5;
        const isAdminDisabled = ipTrunk.lockType === 3;

        return [
          () => getRowElement(isAdminDisabled, "SIP 2.0"),
          () => getRowElement(isAdminDisabled, ipTrunk.gatewayName),
          () => getRowElement(isAdminDisabled, ipTrunk.capacity),
          () => getRowElement(isAdminDisabled, ipTrunk.remoteIP),
          () => getToggleSwitch(isAdminDisabled, isActive, ipTrunk)
        ];
      });
    } catch (error) {
      notyf.error("Unable to process data");
    }
  };

  const getConfirmationDialogForIPStateChange = () => {
    return (
      <div className={styles.IPStateChangeDialog}>
        {ipStateChangeStatus === IPStateChangeStatus.ERROR ? (
          <span className={styles.ipStateChangeError}>
            {"Something went wrong. Please try again."}
          </span>
        ) : (
          <span>
            {processingIPForStateChange?.status == 1
              ? "Disabling this IP will stop all active routings"
              : "Enabling this IP will start all active routings"}
          </span>
        )}
        <br/>
        {ipStateChangeStatus === IPStateChangeStatus.STARTED ? (
          <DotLoader/>
        ) : (
          <div className={styles.IPStateChangeDialogButtonContainer}>
            {ipStateChangeStatus !== IPStateChangeStatus.ERROR && (
              <>
                <SkyButton
                  text="Confirm"
                  size={ButtonSize.SMALL}
                  testId="confirm-ip-state-update-button"
                  onClick={onIpStateSwitchConfirm}
                />
                &nbsp; &nbsp;
                <SkyButton
                  text="Cancel"
                  size={ButtonSize.SMALL}
                  testId="cancel-ip-state-update-button"
                  onClick={() => setProcessingIPForStateChange(null)}
                />
              </>
            )}

            {ipStateChangeStatus === IPStateChangeStatus.ERROR && (
              <SkyButton
                text="Ok"
                size={ButtonSize.SMALL}
                testId="ok-ip-state-update-button"
                onClick={() => {
                  setProcessingIPForStateChange(null);
                  setIpStateChangeStatus(IPStateChangeStatus.NOT_STARTED);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.ipTrunkBaseContainer}>
      <div className={styles.ipTrunkContainer} data-testid="data-sheet">
        <SkyGrid
          isLoading={isLoading}
          renderer={getIpTrunkCell()}
          headerRenderer={getCustomHeaderRenderer()}
          colorBarSpan={4}
        />
      </div>
      {isAddIPDialogOpen && (
        <Dialog
          testId="add-ip-dialog"
          size={Size.SM}
          header={"IP Profile"}
          overflow={"visible"}
          renderer={() => (
            <IPAddForm
              onCancel={() => setIsAddIPDialogOpen(false)}
              onSuccess={() => setIsAddIPDialogOpen(false)}
            />
          )}
        />
      )}
      {processingIPForStateChange && (
        <Dialog
          testId="IP-state-change-dialog"
          size={Size.S}
          header={
            processingIPForStateChange?.status == 1 ? "Disable IP" : "Enable IP"
          }
          renderer={() => getConfirmationDialogForIPStateChange()}
        />
      )}
    </div>
  );
};

export default IPTrunkList;
