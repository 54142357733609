import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./Dialog.module.css";
import classNames from "classnames";
import SkyButton, { ButtonSize } from "../../base/SkyButton/SkyButton";

export enum Size {
  S,
  SM,
  M,
  L,
  XL,
}

type Props = {
  renderer?: () => JSX.Element;
  header?: string;
  message?: string;
  onConfirm?: () => void;
  size?: Size;
  testId?: string;
  defaultBackgroundColor?: string;
  overflow?: string;
  bringToFront?: boolean;
};

const Dialog: FC<Props> = (props) => {
  const dialogContainerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>(Size.S);
  const [dialogTopPosition, setDialogTopPosition] = useState<string>("");

  useEffect(() => {
    setSize(props.size ? props.size : Size.S);
  }, [props.size]);

  useEffect(() => {
    if (dialogContainerRef && dialogContainerRef.current) {
      const {current} = dialogContainerRef;
      if (window.innerWidth <= 720) {
        setDialogTopPosition("calc(50% - " + current.clientHeight /1.5 + "px)");
      } else {
        setDialogTopPosition("calc(50% - " + current.clientHeight / 2 + "px)");
      }
    }
  }, [dialogContainerRef]);

  return (
    <>
      <div
        className={styles.overlay}
        style={{ zIndex: props.bringToFront ? 99998 : 10 }}
        data-testid={"dialog-overlay"}
      />
      <div
        ref={dialogContainerRef}
        className={classNames(
          styles.dialog,
          size == Size.M
            ? styles.medium
            : size == Size.XL
            ? styles.extraLarge
            : size == Size.L
            ? styles.large
            : size == Size.SM
            ? styles.smallMedium
            : ""
        )}
        style={{
          backgroundColor: props.defaultBackgroundColor
            ? props.defaultBackgroundColor
            : "",
          overflow: props.overflow ? props.overflow : "",
          top: dialogTopPosition,
          zIndex: props.bringToFront ? 99999 : 11,
        }}
        data-testid={props.testId}
      >
        {props.header && (
          <div className={styles.headerContainer}>{props.header}</div>
        )}
        {props.renderer && props.renderer()}
        {!props.renderer && (
          <div className={styles.textContainer}>
            <p>{props.message}</p>
            <div className={styles.buttonContainer}>
              <SkyButton
                text={"OK"}
                onClick={() => props.onConfirm && props.onConfirm()}
                size={ButtonSize.SMALL}
                testId={"dialog-confirm-button"}
                disabled={false}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dialog;
