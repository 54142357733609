import React, { createContext, useState } from "react";

type ViewPortSizeContextType = {
  isMenuBarExpanded: boolean;
  isProfileCardExpanded: boolean;
  setIsMenuBarExpanded: (isMenuBarExpanded: boolean) => void;
  setIsProfileCardExpanded: (isProfileCardExpanded: boolean) => void;
};

export const ViewPortSizeContext = createContext<ViewPortSizeContextType>({
  isMenuBarExpanded: false,
  isProfileCardExpanded: false,
  setIsMenuBarExpanded: () => {},
  setIsProfileCardExpanded: () => {},
});

export const ViewPortSizeContextProvider = ({ children }) => {
  const [isMenuBarExpanded, setIsMenuBarExpanded] = useState<boolean>(false);
  const [isProfileCardExpanded, setIsProfileCardExpanded] =
    useState<boolean>(false);

  return (
    <ViewPortSizeContext.Provider
      value={{
        isMenuBarExpanded,
        setIsMenuBarExpanded,
        isProfileCardExpanded,
        setIsProfileCardExpanded,
      }}
    >
      {children}
    </ViewPortSizeContext.Provider>
  );
};
