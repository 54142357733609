import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./DashboardScreen.module.css";
import classNames from "classnames";
import SquareWidget from "./SquareWidget/SquareWidget";
import OverDraftBg from "../../images/OverDraftBG.png";
import IpTruckIcon from "../../icons/IP trunk.svg";
import NumberWidget from "./NumberWidget/NumberWidget";
import AreaChartWidget, {
  AreaChartData,
} from "./AreaChartWidget/AreaChartWidget";
import PieChartWidget, { PieChartData } from "./PieChartWidget/PieChartWidget";
import ListWidget, { ListData } from "./ListWidget/ListWidget";
import {
  downloadCDR,
  fetchAccountSummary,
  fetchAreaWiseCallReport,
  fetchDailySummaryReport,
  fetchIpTrunkList,
  fetchTodayConsumption,
  fetchUserTrunkList,
} from "../../api/endpoint";
import { AuthContext } from "../../contextApi/AuthContext/authContext";
import { AccountSummaryResponse } from "../../models/response/AccountSummaryResponse";
import { notyf } from "../../notyf";
import { DailyAccountSummaryReportResponse } from "../../models/response/DailyAccountSummaryReportResponse";
import {
  formatDateToyyyyMMdd,
  getDayOfWeek,
  parseSlashedFormat,
} from "../../domains/Date";
import { TodayConsumptionResponse } from "../../models/response/TodayConsumptionResponse";
import { AreaWiseCallReportResponse } from "../../models/response/AreaWiseCallReportResponse";
import { useNavigate } from "react-router-dom";
import { UserTrunkListResponse } from "../../models/response/UserTrunkListResponse";
import { IPTrunkListResponse } from "../../models/response/IPTrunkListResponse";
import walletIcon from "../../icons/wallet dashboard.svg";
import userTruckAnimationIcon from "../../icons/user trunk.svg";
import activeNumberIcon from "../../icons/active numbers.svg";
import overDraftIcon from "../../icons/OVERDRAFT icon.svg"
import consumptionIcon from "../../icons/consumption Icon.svg";
import {UserDetailsContext} from "../../contextApi/UserDetailsContext/UserDetailsContext";
import walletStaticIcon from "../../icons/Wallet static.png";
import consumptionStaticIcon from "../../icons/consumption static Icon.png";
import activeNumbersStaticIcon from "../../icons/active numbers static.png";
import overdraftStaticIcon from "../../icons/OVERDRAFT static icon.png";
import userTrunkStaticIcon from "../../icons/user trunk static.png";
import IpTruckStaticIcon from "../../icons/IP trunk static.png";
import {SelectedScreenContext} from "../../contextApi/SelectedScreenContext/SelectedScreenContext";
import { topMenuList, Menu } from "../../domains/Menu";
import {areaChartDummyData, pieChartDummyData} from "../../utils/dummyData";

const DashboardScreen: FC = () => {
  const [balance, setBalance] = useState<number>();
  const [overDraftLimit, setOverDraftLimit] = useState<number>();
  const [activeNumbers, setActiveNumbers] = useState<number>();
  const [areaChartData, setAreaChartData] = useState<AreaChartData[]>([]);
  const [pieChartData, setPieChartData] = useState<PieChartData[]>([]);
  const [threeDaysCallData, setThreeDaysCallData] = useState<ListData[]>([]);
  const [listWidgetLoading, setListWidgetLoading] = useState<boolean>(true);
  const [todayConsumption, setTodayConsumption] = useState<number>(0);
  const [ipTrunkCount, setIPTrunkCount] = useState<number>(0);
  const [userTrunkCount, setUserTrunkCount] = useState<number>(0);
  const [toShowPieChartDummyData, setToShowPieChartDummyData] = useState<boolean>(false);
  const [toShowAreaChartDummyData, setToShowAreaChartDummyData] = useState<boolean>(false);
  const { accessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const {user}= useContext(UserDetailsContext);
  const { setSelectedItem } = useContext(SelectedScreenContext);

  useEffect(() => {
    setSelectedItem(topMenuList.find((item) =>
      item.name === Menu.DASHBOARD) || null
    );
  },[setSelectedItem]);

  useEffect(() => {
    try {
      fetchBalanceOverDraftLimitAndNumberCount();
      if(user?.status === "ACTIVE") {
      fetchCallSummary();
      loadTodayConsumption();
      fetchAreaWiseCallSummary();
      getSIPTrunkCount();
      }
    } catch (error) {
      notyf.error("Unable to call the API");
    }
  }, [user]);

  const fetchBalanceOverDraftLimitAndNumberCount = () => {
    fetchAccountSummary(accessToken ? accessToken : "")
      .then((accountSummaryResponse: AccountSummaryResponse) => {
        setBalance(accountSummaryResponse.balance);
        setOverDraftLimit(accountSummaryResponse.overDraftLimit);
        setActiveNumbers(accountSummaryResponse.activeNumbers);
      })
      .catch(() => {
        notyf.error("Unable to fetch balance, overdraft & number count");
      });
  };

  const getSIPTrunkCount = () => {
    fetchUserTrunkList(accessToken ? accessToken : "")
      .then((userTrunkResponses: UserTrunkListResponse[]) => {
        setUserTrunkCount(userTrunkResponses.length);
      })
      .catch(() => {
        notyf.error("Unable to fetch User Trunk list");
      });
    fetchIpTrunkList(accessToken ? accessToken : "")
      .then((ipTrunkResponses: IPTrunkListResponse[]) => {
        setIPTrunkCount(ipTrunkResponses.length);
      })
      .catch(() => {
        notyf.error("Unable to fetch IP Trunk list");
      });
  };

  const loadAreaGraphData = (
    responses: DailyAccountSummaryReportResponse[]
  ) => {
    setAreaChartData(
      responses.map((response) => {
        return {
          name: getDayOfWeek(parseSlashedFormat(response.date))
            .substring(0, 3)
            .toUpperCase(),
          firstDataPoint: response.callCount,
          secondDataPoint: response.charge,
        };
      })
    );
  };

  const loadPieChartData = (responses: AreaWiseCallReportResponse[]) => {
    setPieChartData(
      responses
        .sort((r1, r2) => r2.cdrCount - r1.cdrCount)
        .map((response) => {
          return {
            name: response.areaCode,
            value: response.cdrCount,
          };
        })
    );
  };

  const downloadCDRFile = (date: Date) => {
    setListWidgetLoading(true);
    downloadCDR(accessToken ? accessToken : "", formatDateToyyyyMMdd(date))
      .then((response) => {
        const downloadLink = URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = downloadLink;
        link.setAttribute(
          "download",
          "CDR_" + formatDateToyyyyMMdd(date) + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((_) => notyf.error("Unable to download CDR file"))
      .finally(() => setListWidgetLoading(false));
  };

  const loadListWidgetData = (
    responses: DailyAccountSummaryReportResponse[]
  ) => {
    setThreeDaysCallData(
      responses
        .filter((response) => response.callCount > 0)
        .map((response) => {
          return {
            header: response.date,
            text: "Number of Calls : " + response.callCount,
            buttonText: "CDR",
            buttonOnClick: (date: string) => {
              const parsedDate = parseSlashedFormat(date);
              downloadCDRFile(parsedDate);
            },
          };
        })
        .reverse()
        .splice(0, 3)
    );
    setListWidgetLoading(false);
  };

  const fetchCallSummary = () => {
    const fromDate = new Date(new Date().getTime() - 8 * 24 * 60 * 60 * 1000);
    const toDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    setListWidgetLoading(true);
    setToShowAreaChartDummyData(false);
    fetchDailySummaryReport(
      accessToken ? accessToken : "",
      formatDateToyyyyMMdd(fromDate),
      formatDateToyyyyMMdd(toDate)
    )
      .then((responses: DailyAccountSummaryReportResponse[]) => {
        if (responses.length === 0) {
          loadAreaGraphData(areaChartDummyData);
          setToShowAreaChartDummyData(true);
        } else {
          loadAreaGraphData(responses);
        }
        loadListWidgetData(responses);
      })
      .catch(() => {
        notyf.error("Unable to fetch daily summary report");
      });
  };

  const fetchAreaWiseCallSummary = () => {
    const fromDate = new Date(new Date().getTime() - 8 * 24 * 60 * 60 * 1000);
    const toDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    setListWidgetLoading(true);
    setToShowPieChartDummyData(false);
    fetchAreaWiseCallReport(
      accessToken ? accessToken : "",
      formatDateToyyyyMMdd(fromDate),
      formatDateToyyyyMMdd(toDate)
    )
      .then((responses: AreaWiseCallReportResponse[]) => {
        if (responses.length === 0) {
          loadPieChartData(pieChartDummyData);
          setToShowPieChartDummyData(true);
        } else {
          loadPieChartData(responses);
        }
      })
      .catch(() => {
        notyf.error("Unable to fetch area wise summary report");
      });
  };

  const loadTodayConsumption = () => {
    fetchTodayConsumption(accessToken ? accessToken : "")
      .then((response: TodayConsumptionResponse) => {
        try {
          setTodayConsumption(response.todayConsumption);
        } finally {
          // setTimeout(() => {
          //   loadTodayConsumption();
          // }, 5000);
        }
      })
      .catch(() => {
        notyf.error("Unable to fetch today's consumption");
      });
  };

  return (
    <div className={styles.dashBoardScreenContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>Dashboard</div>
      </div>
      <div className={styles.parentStatContainer}>
        <div className={styles.leftWidgetContainer}>
          <div className={styles.upperLeftContainer}>
            <div className={classNames(styles.widgetCell, styles.square)}>
              <SquareWidget
                icon={walletIcon}
                data={`$ ${balance?.toFixed(2)}`}
                label={"Balance"}
                isLoading={balance === undefined}
                staticIcon={walletStaticIcon}
              />
            </div>
            <div className={classNames(styles.widgetCell, styles.square)}>
              <SquareWidget
                icon={overDraftIcon}
                data={`$ ${overDraftLimit?.toFixed(2)}`}
                label={"Over Draft"}
                isLoading={overDraftLimit === undefined}
                background={OverDraftBg}
                dataFontColor={"white"}
                labelFontColor={"white"}
                staticIcon={overdraftStaticIcon}
              />
            </div>
            <div className={classNames(styles.widgetCell, styles.square)}>
              <SquareWidget
                icon={consumptionIcon}
                data={`$ ${todayConsumption.toFixed(2)}`}
                label={"Today's Consumption"}
                staticIcon={consumptionStaticIcon}
              />
            </div>
            <div
              className={classNames(
                styles.widgetCell,
                styles.square,
                styles.clickable
              )}
              onClick={() => navigate("/numbers/virtualNumbers")}
              data-testid="number-widget-container"
            >
              <SquareWidget
                icon={activeNumberIcon}
                data={activeNumbers + ""}
                label={"Active Numbers"}
                isLoading={activeNumbers === undefined}
                staticIcon={activeNumbersStaticIcon}
              />
            </div>
          </div>
          <div className={styles.lowerLeftContainer}>
            <div className={styles.statContainer}>
              <div className={styles.numberWidgetContainer}>
                <div
                  className={classNames(
                    styles.widgetCell,
                    styles.twoByOne,
                    styles.clickable
                  )}
                  onClick={() => navigate("/sipTrunk/IPTrunk")}
                  data-testid="ip-trunk-count"
                >
                  <NumberWidget
                      icon={IpTruckIcon}
                      label={"IP Trunk"}
                      data={ipTrunkCount + ""}
                      staticIcon={IpTruckStaticIcon}
                  />
                </div>
                <div
                  className={classNames(
                    styles.widgetCell,
                    styles.twoByOne,
                    styles.clickablereq
                  )}
                  onClick={() => navigate("/sipTrunk/userTrunk")}
                  data-testid="user-trunk-count"
                >
                  <NumberWidget
                      icon={userTruckAnimationIcon}
                      label={"User Trunk"}
                      data={userTrunkCount + ""}
                      staticIcon={userTrunkStaticIcon}
                  />
                </div>
              </div>
              <div
                className={classNames(
                  styles.graphContainer,
                  styles.hidden
                )}
                data-testid="area-chart"
              >
                <AreaChartWidget data={areaChartData} isDummyData={toShowAreaChartDummyData}/>
              </div>
            </div>
            {/*<div className={styles.activityContainer}>*/}
            {/*  <h1> Activity </h1>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={styles.rightWidgetContainer}>
          <div
            className={classNames(
              styles.widgetCell,
              styles.oneByTwo,
              styles.resizable,
              styles.responsive
            )}
          >
            <PieChartWidget pieChartData={pieChartData} isDummyData={toShowPieChartDummyData}/>
          </div>
          <div
            className={classNames(
              styles.widgetCell,
              styles.square,
              styles.responsive
              )}
            data-testid="list-widget"
          >
            <ListWidget
              data={threeDaysCallData}
              isLoading={user?.status === "ACTIVE" ? listWidgetLoading : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardScreen;
