import { FC, useEffect, useState } from "react";
import addressIcon from "../../../../icons/Address.png";
import bankIcon from "../../../../icons/Bank.png";
import identityIcon from "../../../../icons/Digital identity.png";
import companyIcon from "../../../../icons/Document.png";
import taxIcon from "../../../../icons/Tax.png";
import tickIcon from "../../../../icons/greenTick.png";

import styles from "./SummaryForm.module.css";
import {
  KYCData,
  validateKYCData,
  ValidationResult,
} from "../../../../domains/KYC";
import classNames from "classnames";

export type SummaryProps = {
  kycData: KYCData;
  onValidate: (validationResult: ValidationResult) => void;
  redirectTo: (index: number) => void;
};

const SummaryForm: FC<SummaryProps> = (props) => {
  const [result] = useState<ValidationResult>(validateKYCData(props.kycData));

  useEffect(() => {
    props.onValidate(result);
  }, [result]);

  const handleIconClick = (formIndex: number) => {
    props.redirectTo(formIndex);
  };

  return (
    <div
      className={styles.kycFormWrapperContainer}
      data-testid={"summary-detail-form"}
    >
      <div className={styles.kycContainer}>
        <h2>Verify your KYC</h2>
        <p>before final submit</p>
        <div className={styles.iconsContainer}>
          <div
            className={classNames(
              styles.detailsContainer,
              !result.businessDetailsValidation ? styles.error : ""
            )}
            onClick={() => handleIconClick(0)}
            data-testid="business-details"
          >
            <div>Business Details</div>
            <img
              src={result.businessDetailsValidation ? tickIcon : companyIcon}
              alt="Business Details Icon"
            />
          </div>

          <div
            className={classNames(
              styles.detailsContainer,
              !result.companyDetailsValidation ? styles.error : ""
            )}
            onClick={() => handleIconClick(1)}
            data-testid="company-details"
          >
            <div>Company Details</div>
            <img
              src={result.companyDetailsValidation ? tickIcon : companyIcon}
              alt="Company Icon"
            />
          </div>
          <div
            className={classNames(
              styles.detailsContainer,
              !result.taxDetailsValidation ? styles.error : ""
            )}
            onClick={() => handleIconClick(2)}
            data-testid="tax-details"
          >
            <div>Tax Details</div>
            <img
              src={result.taxDetailsValidation ? tickIcon : taxIcon}
              alt="Tax Icon"
            />
          </div>
          <div
            className={classNames(
              styles.detailsContainer,
              !result.addressDetailsValidation ? styles.error : ""
            )}
            onClick={() => handleIconClick(3)}
            data-testid="address-details"
          >
            <div>Address Proof</div>
            <img
              src={result.addressDetailsValidation ? tickIcon : addressIcon}
              alt="Address Icon"
            />
          </div>
          <div
            className={classNames(
              styles.detailsContainer,
              !result.bankDetailsValidation ? styles.error : ""
            )}
            onClick={() => handleIconClick(4)}
            data-testid="bank-details"
          >
            <div>Bank Details</div>
            <img
              src={result.bankDetailsValidation ? tickIcon : bankIcon}
              alt="Bank Icon"
            />
          </div>
          <div
            className={classNames(
              styles.detailsContainer,
              !result.personalDetailsValidation ? styles.error : ""
            )}
            onClick={() => handleIconClick(5)}
            data-testid="personal-details"
          >
            <div>Personal Details</div>
            <img
              src={result.personalDetailsValidation ? tickIcon : identityIcon}
              alt="Identity Icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryForm;
