import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./OtherChargesList.module.css";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import { fetchOtherChargesList } from "../../../api/endpoint";
import { notyf } from "../../../notyf";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import { OtherCharges } from "../../../models/response/OtherCharges";
import moment from "moment";

type ChargeData = {
  money: string;
  customerMoney: string;
  type: string;
  consumptionName: string;
  time: string;
};

const OtherChargesList: FC = () => {
  const [otherChargesData, setOtherChargesData] = useState<ChargeData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { accessToken } = useContext(AuthContext);

  const columns: string[] = [
    "ID",
    "Amount",
    "Balance",
    "Type",
    "Source",
    "Date",
  ];

  useEffect(() => {
    fetchOtherChargesList(accessToken ? accessToken : "")
      .then((otherCharges: OtherCharges[]) => {
        setOtherChargesData(
          otherCharges.map((charge) => {
            const date = new Date(charge.time);
            return {
              money: charge.money + "",
              customerMoney: charge.customerMoney + "",
              type: charge.type,
              consumptionName: charge.consumptionName,
              time: moment(date).format("Do MMM, YYYY"),
            };
          })
        );
        setIsLoading(false);
      })
      .catch(() => {
        notyf.error("Unable to fetch other charges");
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className={styles.container} data-testid="other-charges-list">
      <div className={styles.tableContainer}>
        <SkyGrid
          columns={columns}
          isLoading={isLoading}
          data={otherChargesData.map((obj, index) => {
            return [
              index + 1 + "",
              obj.money,
              obj.customerMoney,
              obj.type,
              obj.consumptionName,
              obj.time,
            ];
          })}
        />
      </div>
    </div>
  );
};

export default OtherChargesList;
