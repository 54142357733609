import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import styles from "./RateSheet.module.css";
import search from "../../../icons/search.png";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import round from "../../../images/Round.png";
import earth from "../../../images/Earth.png";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import { fetchCallRateData } from "../../../api/endpoint";
import { notyf } from "../../../notyf";

type RateData = {
  areaName: string;
  areaCode: string;
  rate: string;
};

const RateSheet: FC = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rateData, setRateData] = useState<RateData[]>([]);
  const { accessToken } = useContext(AuthContext);
  const columns = ["Country", "Area Code", "Rate"];

  useEffect(() => {
    try {
      setIsLoading(true);
      fetchCallRateData(
        accessToken ? accessToken : "",
        searchText ? searchText : "",
        page
      )
        .then((rateDataResponse) => {
          try {
            setRateData(
              rateDataResponse.callFeeRates.map((fee) => {
                return {
                  areaName: fee.areaName,
                  areaCode: fee.areaCode,
                  rate: fee.rate,
                };
              })
            );
          } catch (error) {
            notyf.error("Unable to fetch rate data");
          } finally {
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      notyf.error("Invalid login ID or password");
    }
  }, [searchText]);

  // const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
  //   if (event.key === "Enter") fetchCallRateData().then();
  // };

  const handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.mapContainer}>
          <div className={styles.roundImageContainer}>
            <img
              src={round}
              alt="Background circle."
              className={styles.round}
            />
          </div>
          <div className={styles.earthImageContainer}>
            <img src={earth} alt="World map." className={styles.earth} />
          </div>
        </div>

        <div className={styles.ratesDataContainer} data-testid="rate-sheet">
          <div className={styles.rateSearchBar}>
            <div className={styles.rateSearchButton}>Search</div>

            <div className={styles.searchInputContainer}>
              <input
                type="search"
                placeholder="Country/Area&nbsp;Code "
                value={searchText}
                id="search"
                data-testid="rate_search"
                onChange={handleSearchBarChange}
                name="searchbar"
                className={styles.rateSearchInput}
                // onKeyUp={handleKeyUp}
              />
            </div>

            <div className={styles.searchIconContainer}>
              <img
                src={search}
                alt="Search-icon"
                className={styles.rateSearchIcon}
                // onClick={() => fetchCallRateData()}
              />
            </div>
          </div>
          <div className={styles.gridContainer}>
            <SkyGrid
              columns={columns}
              data={rateData.map((data) => [
                data.areaName,
                data.areaCode,
                data.rate,
              ])}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RateSheet;
