import React, {FC, useContext, useEffect, useRef, useState} from "react";
import styles from "./IPAddForm.module.css";
import classNames from "classnames";
import CountrySelector from "../../../business/CountrySelector/CountrySelector";
import errorIcon from "../../../../icons/error-emoji.png";
import {
  copyIp,
  fetchDefaultGatewayGroups, fetchIpTrunkList,
  initiateIPAdd,
} from "../../../../api/endpoint";
import {DefaultGatewayGroupsResponse} from "../../../../models/response/DefaultGatewayGroupsResponse";
import {notyf} from "../../../../notyf";
import {AuthContext} from "../../../../contextApi/AuthContext/authContext";
import DotLoader from "../../../business/DotLoader/DotLoader";
import {InitiateAddIPRequest} from "../../../../models/request/InitiateAddIPRequest";
import {isOTPRequired} from "../../../../requester";
import {OTPContext} from "../../../../contextApi/OTPContext/OTPContext";
import SkyButton, {ButtonSize} from "../../../base/SkyButton/SkyButton";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  RadioButtonChecked,
  RadioButtonUnchecked
} from "@mui/icons-material";
import SkyDropdown from "../../../base/SkyDropdown/SkyDropdown";
import {useQuery} from "react-query";
import {FeatureToggleContext} from "../../../../contextApi/FeatureToggleContext/FeatureToggleContext";

export type Country = {
  name: string;
  code: string;
  group: string;
  isSelected?: boolean;
};

export type Props = {
  onCancel: () => void;
  onSuccess: () => void;
};

enum IPConfiguration {
  New,
  Existing
}

const IPAddForm: FC<Props> = (props) => {
  const [availableCountries, setAvailableCountries] = useState<Country[]>([]);
  const [ip, setIp] = useState("");
  const [isValidFormattedIP, setIsValidFormattedIP] = useState(true);
  const [errorFromCountrySelection, setErrorFromCountrySelection] =
    useState<string>("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorMessageOnIpAdd, setErrorMessageOnIpAdd] = useState("");
  const existingIPSelectorRef = useRef<HTMLDivElement>(null);
  const [configuration, setConfiguration] = useState(IPConfiguration.New);
  const [isExistingIPDropdownOpen, setIsExistingIPDropdownOpen] = useState(false);
  const [copyFromIP, setCopyFromIP] = useState<string>("");
  const [isIPAdded, setIsIPAdded] = useState(false);
  const [isIPAddFailed, setIsIPAddFailed] = useState(false);
  const {accessToken} = useContext(AuthContext);
  const {isCopyIpEnabled} = useContext(FeatureToggleContext);
  const {
    setOTPRequired,
    setErrorResponse,
    isOTPVerificationSuccess,
    setIsOTPVerificationSuccess,
  } = useContext(OTPContext);

  const handleAddIPSuccess = () => {
    setIsIPAdded(true);
  };

  useEffect(() => {
    if (isOTPVerificationSuccess) {
      handleAddIPSuccess();
      setIsOTPVerificationSuccess(false);
    }
  }, [isOTPVerificationSuccess]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    if (!accessToken) return;

    fetchDefaultGatewayGroups(accessToken)
      .then((defaultGatewayGroups: DefaultGatewayGroupsResponse[]) => {
        if (defaultGatewayGroups.length == 0) {
          notyf.error("No country found");
          return;
        }
        setAvailableCountries(
          defaultGatewayGroups.map((gatewayGroup) => {
            return {
              name: gatewayGroup.country,
              code: gatewayGroup.countryCode,
              group: gatewayGroup.group,
              isSelected: false,
            };
          })
        );
      })
      .catch(() => notyf.error("Unable to fetch countries"))
      .finally(() => setIsLoaded(true));
  }, [accessToken]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      (existingIPSelectorRef.current &&
        !existingIPSelectorRef.current.contains(event.target as Node))
    ) {
      setIsExistingIPDropdownOpen(false);
    }
  }

  const {
    isFetching: isIPFetching,
    isError: isIPFetchError,
    data: ipTrunkList
  } = useQuery(
    "fetchIPTrunkList",
    () => {
      return fetchIpTrunkList(accessToken ? accessToken : "");
    },
    {
      enabled: isExistingIPDropdownOpen,
      onError: () => notyf.error("Unable to fetch existing IP list")
    }
  );

  const {isFetching: isProcessingForAddIPWithExistingConfig, refetch: addIPWithExistingConfig} =
    useQuery(["addIPWithExistingConfig"], () => {
          return copyIp(accessToken ? accessToken : "", {
            fromIP: copyFromIP,
            toIP: ip
          })
      },
      {
        onSuccess: () => handleAddIPSuccess(),
        onError: (error: any) => {
          if (isOTPRequired(error)) {
            setOTPRequired(true);
            setErrorResponse(error);
          } else {
            handleIPAddError(error);
          }
        },
        enabled: false
      }
    );

  const {isFetching: isProcessingForAddIPWithNewConfig, refetch: addIPWithNewConfig} =
    useQuery(["addIPWithNewConfig"], () => {
        const selectedCountries = availableCountries.filter(
          (country) => country.isSelected
        );

        const addIPRequest: InitiateAddIPRequest = {
          ip: ip,
          routingGatewayGroups: selectedCountries.map((country) => country.group),
        };
        return initiateIPAdd(accessToken ? accessToken : "", addIPRequest);
      },
      {
        onSuccess: () => handleAddIPSuccess(),
        onError: (error: any) => {
          if (isOTPRequired(error)) {
            setOTPRequired(true);
            setErrorResponse(error);
          } else {
            handleIPAddError(error);
          }
        },
        enabled: false
      }
    );

  const onSelectionChanged = (countryCode: string | string[]) => {
    setAvailableCountries(
      availableCountries.map((country) => {
        if (country.code === countryCode)
          country.isSelected = !country.isSelected;
        return country;
      })
    );
  };

  const processIPAddition = () => {
    setIsIPAddFailed(false);
    setErrorFromCountrySelection("");

    if (configuration === IPConfiguration.Existing) {
      if (!ip || !isValidFormattedIP || !copyFromIP) return;
      addIPWithExistingConfig();
    }

    else {
      if (!ip || !isValidFormattedIP || availableCountries.filter(c => c.isSelected).length == 0)
        return;
      addIPWithNewConfig();
    }
  };

  const handleIPAddError = (error: { response: { status: number; }; }) => {
    setIsIPAddFailed(true);
    if (error.response.status === 409) {
      setErrorMessageOnIpAdd("IP already exists to your account");
    } else {
      setErrorMessageOnIpAdd(
        "Unable to add IP. Please contact support@skytelservices.com for any assistance."
      );
    }
  }

  const getErrorMessageElement = (message: string) => {
    return (
      <div
        className={styles.errorMessageContainer}
        data-testid={`error-message`}
      >
        <img src={errorIcon} alt="error icon" className={styles.errorIcon}/>
        {message}
      </div>
    );
  };

  const checkValidFormattedIP = () => {
    setIsValidFormattedIP(
      /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(ip)
    );
  };

  const addIPWithNewConfiguration = () => {
    setConfiguration(IPConfiguration.New);
  }

  const addIPWithExistingConfiguration = () => {
    setConfiguration(IPConfiguration.Existing);
  }

  const existingIPSelectorContent = () => {
    return (
      <SkyDropdown
        data={!ipTrunkList ? [] : ipTrunkList.map(ipTrunk => ({
          id: ipTrunk.gatewayName,
          value: ipTrunk.remoteIP
        }))}
        onSelect={(data) => {
          setCopyFromIP(data.value);
          setIsExistingIPDropdownOpen(false);
        }}
        isSearchable={true}
        isLoading={isIPFetching}
      />);
  }

  return (
    <>
      {!isLoaded && <DotLoader/>}
      {isLoaded && !isIPAdded && (
        <div className={styles.addIPOuterContainer}>
          {isCopyIpEnabled &&
              <div className={classNames(styles.configurationSelector, styles.grayFont)}>
                  <div onClick={addIPWithNewConfiguration}
                       className={styles.clickable}
                       data-testid={"add-ip-with-new-configuration"}>
                    {
                      configuration == IPConfiguration.New ?
                        (<RadioButtonChecked className={classNames(styles.selectedIcon, styles.blue)}/>) :
                        (<RadioButtonUnchecked className={styles.grayFont}/>)
                    }
                      <span>Create New Configuration</span>
                  </div>
                  <div onClick={addIPWithExistingConfiguration}
                       className={styles.clickable}
                       data-testid={"add-ip-with-existing-configuration"}>
                    {
                      configuration == IPConfiguration.Existing ?
                        (<RadioButtonChecked className={classNames(styles.selectedIcon, styles.blue)}/>) :
                        (<RadioButtonUnchecked className={styles.grayFont}/>)
                    }
                      <span>Copy Existing Configuration</span>
                  </div>
              </div>
          }

          <div data-testid={"ip-add-form"} className={styles.addIPContainer}>
            <div className={styles.addIPRow}>
              <div className={styles.addIPCell}>
                {
                  configuration === IPConfiguration.New ? (
                    <div className={styles.callDestinationContainer} data-testid={"add-ip-with-new-config-form"}>
                      <div className={styles.textLabel}> Call Destination</div>
                      <CountrySelector
                        countries={availableCountries}
                        maxAllowedSelection={2}
                        onError={(message) => {
                          setErrorFromCountrySelection(message);
                        }}
                        onChange={(code) => onSelectionChanged(code)}
                      />
                      {errorFromCountrySelection.length > 0 &&
                        getErrorMessageElement(errorFromCountrySelection)}
                    </div>
                  ) : (
                    <div className={styles.existingIPContainer} data-testid={"add-ip-with-existing-config-form"}>
                      <div className={styles.textLabel}> Existing IP</div>
                      <div className={classNames(styles.roundControl, styles.ipSelector)}
                           onClick={() => setIsExistingIPDropdownOpen(!isExistingIPDropdownOpen)}
                           data-testid={"existing-ip-dropdown"}>
                        <span>{copyFromIP}</span>
                        {isExistingIPDropdownOpen
                          ? <KeyboardArrowUp data-testid={"ip-selector-arrow-up"}/>
                          : <KeyboardArrowDown data-testid={"ip-selector-arrow-down"}/>}
                      </div>
                      {
                        isExistingIPDropdownOpen &&
                          <div ref={existingIPSelectorRef}>
                            {existingIPSelectorContent()}
                          </div>
                      }
                      {isIPFetchError &&
                        getErrorMessageElement("Failed to fetch existing IPs")}
                    </div>
                  )
                }
              </div>

              <div className={styles.addIPCell}>
                <div className={styles.ipContainer}>
                  <div className={styles.textLabel}> New IP</div>
                  <input
                    className={styles.roundControl}
                    type="text"
                    placeholder={"0.0.0.0"}
                    data-testid={"ip-input"}
                    onBlur={checkValidFormattedIP}
                    onKeyUp={(e) => {
                      setIp((e.target as HTMLInputElement).value);
                      setIsValidFormattedIP(true);
                    }}
                  />

                  {!isValidFormattedIP &&
                    getErrorMessageElement("Please enter a valid IP")}
                </div>
              </div>
            </div>
          </div>

          {isIPAddFailed && <div className={styles.addIPErrorContainer}>
            {getErrorMessageElement(errorMessageOnIpAdd)}
          </div>}

          {!(isProcessingForAddIPWithNewConfig || isProcessingForAddIPWithExistingConfig) && (
            <div className={classNames(styles.addIPDialogButtonContainer)}>
              <>
                <SkyButton
                  text={"Submit"}
                  onClick={processIPAddition}
                  size={ButtonSize.SMALL}
                  testId={"submit-button"}
                />
                <SkyButton
                  text={"Cancel"}
                  onClick={() => props.onCancel()}
                  size={ButtonSize.SMALL}
                  testId={"cancel-button"}
                />
              </>
            </div>
          )}

          {(isProcessingForAddIPWithNewConfig || isProcessingForAddIPWithExistingConfig) && (
            <div className={styles.dotLoaderContainer}>
              <DotLoader/>
            </div>
          )}
        </div>
      )}

      {isIPAdded && (
        <div className={classNames(styles.addIPOuterContainer, styles.confirmationContainer)}>
              <span className={styles.confirmationHeader}>
                IP Added Successfully
              </span>
          <span
            className={styles.confirmationText}
            data-testid={"confirmation-text"}
          >
            IP {ip} has been successfully added.
          </span>
          <SkyButton
            onClick={() => props.onSuccess()}
            size={ButtonSize.SMALL}
            testId={"ok-button"}
            text={"Ok"}
          />
        </div>
      )}
    </>
  );
};

export default IPAddForm;
