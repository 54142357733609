import React, { FC, useEffect, useState } from "react";
import styles from "./BusinessRequirementForm.module.css";
import error from "../../../../icons/error-emoji.png";
import classNames from "classnames";
import {
  BusinessDetails,
  BusinessType,
  TrafficType,
} from "../../../../domains/KYC";

type BusinessRequirementProps = {
  kycData: BusinessDetails;
  onChange: (details: BusinessDetails) => void;
};

const BusinessRequirementForm: FC<BusinessRequirementProps> = (props) => {
  const [businessType, setBusinessType] = useState<BusinessType>(
    props.kycData.businessType
  );
  const [trafficType, setTrafficType] = useState<TrafficType>(
    props.kycData.trafficType
  );
  const [tradingName, setTradingName] = useState(props.kycData.tradingName);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isTradingNameValid, setIsTradingNameValid] = useState<boolean>(false);

  useEffect(() => {
    props.onChange({
      businessType: businessType,
      trafficType: trafficType,
      tradingName: tradingName,
    });
  }, [businessType, trafficType, tradingName]);

  return (
    <div
      className={styles.businessRequirementFormContainer}
      data-testid="business-details-form"
    >
      <h2>KYC Submission</h2>
      <div className={styles.businessRequirementContainer}>
        <p>
          Let's catch up quickly.
        </p>

        <div className={styles.proprietaryBusinessContainer}>
          <input
            type="radio"
            value={BusinessType.PROPRIETARY}
            checked={businessType === BusinessType.PROPRIETARY}
            onChange={() => setBusinessType(BusinessType.PROPRIETARY)}
            data-testid="kyc-proprietary-business"
          />
          <span className={styles.proprietaryBusinessText}>
            We are a <span className={styles.boldText}>Proprietary </span>firm
          </span>
        </div>
        <div className={styles.partnershipBasedBusinessContainer}>
          <input
            type="radio"
            value={BusinessType.PARTNERSHIP}
            checked={businessType === BusinessType.PARTNERSHIP}
            onChange={() => setBusinessType(BusinessType.PARTNERSHIP)}
            data-testid="kyc-partnership-based-business"
          />
          <span className={styles.partnershipBasedBusinessText}>
            We are a <span className={styles.boldText}>Partnership</span> firm.
          </span>
        </div>
        <div className={styles.privateBusinessContainer}>
          <input
            type="radio"
            value={BusinessType.PRIVATE}
            checked={businessType === BusinessType.PRIVATE}
            onChange={() => setBusinessType(BusinessType.PRIVATE)}
            data-testid="kyc-private-business"
          />
          <span className={styles.privateBusinessText}>
            We are a <span className={styles.boldText}>Private</span> / {" "}
            <span className={styles.boldText}>Limited </span> company.
          </span>
        </div>
        <div className={styles.resellerBusinessContainer}>
          <input
            type="radio"
            value={BusinessType.RESELLER}
            checked={businessType === BusinessType.RESELLER}
            onChange={() => setBusinessType(BusinessType.RESELLER)}
            data-testid="kyc-reseller-business"
          />
          <span className={styles.resellerBusinessText}>
            We are an {" "} <span className={styles.boldText}>Agent</span> / {" "}
            <span className={styles.boldText}>Reseller</span>.
          </span>
        </div>
      </div>
      <div className={styles.trafficTypeContainer}>
        <label>Traffic Type</label>
        <div className={styles.trafficType}>
          <div className={styles.trafficTypeDialer}>
            <input
              type="radio"
              value={TrafficType.DIALER}
              checked={trafficType === TrafficType.DIALER}
              onChange={() => setTrafficType(TrafficType.DIALER)}
              data-testid="kyc-traffic-dialer"
            />
            <span>Dialer</span>
          </div>
          <div className={styles.trafficTypeManual}>
            <input
              type="radio"
              value={TrafficType.MANUAL}
              checked={trafficType === TrafficType.MANUAL}
              onChange={() => setTrafficType(TrafficType.MANUAL)}
              data-testid="kyc-traffic-manual"
            />
            <span>Manual</span>
          </div>
          <div className={styles.trafficTypeRetail}>
            <input
              type="radio"
              value={TrafficType.RETAIL}
              checked={trafficType === TrafficType.RETAIL}
              onChange={() => setTrafficType(TrafficType.RETAIL)}
              data-testid="kyc-traffic-retail"
            />
            <span>Retail</span>
          </div>
        </div>
      </div>
      <div className={styles.tradingNameContainer}>
        <label htmlFor="tradingNameInput">
          Trading Name / DBA (Doing Business As)
        </label>
        <input
          type="input"
          id="tradingNameInput"
          value={tradingName}
          placeholder="Trading Name"
          className={classNames(
            styles.tradingNameInput,
            isTradingNameValid && styles.errorBorder
          )}
          onChange={(e) => setTradingName(e.target.value)}
        />
      </div>

      {errorMessage && (
        <div className={styles.error}>
          <img src={error} alt="error-icon" />
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default BusinessRequirementForm;
