import React, {FC, useContext, useEffect, useState} from "react";
import arrow from "../../../icons/arrow.png";
import styles from "./headerComponent.module.css";
import classNames from "classnames";
import { ViewPortSizeContext } from "../../../contextApi/ViewportSizeContext/ViewPortSizeContext";

export type Props = {
  userName: string;
  handleArrowClick: (openProfileCard: boolean) => void;
};

const Header: FC<Props> = (props) => {
  const [openProfileCard, setOpenProfileCard] = useState(false);
  const { isProfileCardExpanded , setIsProfileCardExpanded } = useContext(ViewPortSizeContext);

  useEffect(() => {
    setOpenProfileCard(isProfileCardExpanded);
  },[isProfileCardExpanded]);

  const showProfileCard = () => {
    props.handleArrowClick(!openProfileCard);
    setOpenProfileCard(!openProfileCard);
    setIsProfileCardExpanded(!openProfileCard);
  };

  return (
    <>
      <div className={styles.header} data-testid="header_component">
        <div className={styles.greetContainer}>
          <p className={styles.greetText}>Hello, {props.userName}!</p>
        </div>

        <div
          className={styles.profileIconContainer}
          onClick={() => showProfileCard()}
          data-testid="profile_icon_container"
        >
          <div className={styles.profileCircle}>
            <span className={styles.initialText}>
              {props.userName.substring(0, 1).toUpperCase()}
            </span>
          </div>
          <img
            src={arrow}
            alt="arrow-icon"
            className={classNames(
              styles.arrowIcon,
              openProfileCard ? styles.rotate : ""
            )}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
