import React, { FC, useEffect, useState } from "react";
import styles from "./ResetPassword.module.css";
import SkyInputBox, {
  isValidEmail,
  isValidPassword,
} from "../../base/SkyInputBox/SkyInputBox";
import classNames from "classnames";
import SkyButton, { ButtonSize } from "../../base/SkyButton/SkyButton";
import DotLoader from "../DotLoader/DotLoader";

export type ResetPasswordFormData = {
  username: string;
  password: string;
};

export type Props = {
  onCancel: () => void;
  onConfirm: (formData: ResetPasswordFormData) => void;
  isPasswordResetSuccess: boolean;
  isLoading: boolean;
};

const ResetPasswordForm: FC<Props> = (props) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [retypePassword, setRetypePassword] = useState<string>("");

  const handleCancel = () => {
    setUsername("");
    setPassword("");
    setRetypePassword("");
    props.onCancel();
  };

  useEffect(() => {
    if (props.isPasswordResetSuccess) handleCancel();
  }, [props.isPasswordResetSuccess]);

  return (
    <div
      data-testid="reset-password-form"
      className={styles.resetPasswordContainer}
    >
      <div className={styles.infoTextContainer}>
        One Time Password will be sent to your provided email id, <br />
        if you are a registered user.
      </div>
      <div className={styles.resetPasswordFormRow}>
        <SkyInputBox
          type={"text"}
          label={"Username / Email"}
          placeholder={"Username / Email"}
          value={username}
          isValid={isValidEmail(username)}
          onChange={(value) => setUsername(value as string)}
        />
      </div>
      <div className={styles.resetPasswordFormRow}>
        <SkyInputBox
          type={"password"}
          label={"New Password"}
          placeholder={"New Password"}
          value={password}
          isValid={isValidPassword(password)}
          onChange={(value) => setPassword(value as string)}
        />
      </div>
      <div className={styles.resetPasswordFormRow}>
        <SkyInputBox
          type={"password"}
          label={"Re-type New Password"}
          placeholder={"Re-type New Password"}
          value={retypePassword}
          isValid={
            retypePassword.trim().length == 0 || password == retypePassword
          }
          onChange={(value) => setRetypePassword(value as string)}
        />
      </div>
      <div
        className={classNames(styles.resetPasswordFormRow, styles.buttonRow)}
      >
        {props.isLoading && <DotLoader />}
        {!props.isLoading && (
          <>
            <div className={styles.buttonWrapper}>
              <SkyButton
                testId={"confirm"}
                onClick={() => {
                  if (
                    isValidEmail(username) &&
                    isValidPassword(password) &&
                    password == retypePassword
                  )
                    props.onConfirm({ username: username, password: password });
                }}
                size={ButtonSize.SMALL}
                text={"Confirm"}
                disabled={false}
              />
            </div>

            <div className={styles.buttonWrapper}>
              <SkyButton
                testId={"cancel"}
                onClick={() => handleCancel()}
                size={ButtonSize.SMALL}
                text={"Cancel"}
                disabled={false}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
