import React, { FC, useEffect, useState } from "react";
import styles from "../DetailForm.module.css";
import UploadForm from "../UploadForm/UploadForm";
import error from "../../../../icons/error-emoji.png";
import { BankDetails, documentToBase64 } from "../../../../domains/KYC";
import SkyInputBox from "../../../base/SkyInputBox/SkyInputBox";
import DocumentUploader from "../DocumentUploader/DocumentUploader";

type BankDetailsFormProps = {
  kycData: BankDetails;
  onChange: (details: BankDetails) => void;
};

const BankDetailsForm: FC<BankDetailsFormProps> = (props) => {
  const [bankName, setBankName] = useState<string>(
    props.kycData.bankName
  );
  const [bankAddress, setBankAddress] = useState<string>(
    props.kycData.bankAddress
  );
  const [accountNumber, setAccountNumber] = useState<string>(
    props.kycData.accountNumber
  );
  const [beneficiaryName, setBeneficiaryName] = useState<string>(
    props.kycData.beneficiaryName
  );
  const [beneficiaryAddress, setBeneficiaryAddress] = useState<string>(
    props.kycData.beneficiaryAddress
  );
  const [swiftOrIfscCode, setSwiftOrIfscCode] = useState<string>(
    props.kycData.swiftOrIfscCode
  );
  const [financeEmailId, setFinanceEmailId] = useState<string>(
    props.kycData.financeEmailId
  );
  const [supportingDocument, setSupportingDocument] = useState<string>(
    props.kycData.supportingDocument
  );
  const [isOpenUploadOption] = useState<boolean>(false);
  const [errorMessage] = useState<string | null>(null);

  useEffect(() => {
    props.onChange({
      bankName: bankName,
      bankAddress: bankAddress,
      accountNumber: accountNumber,
      beneficiaryName: beneficiaryName,
      beneficiaryAddress: beneficiaryAddress,
      swiftOrIfscCode: swiftOrIfscCode,
      financeEmailId: financeEmailId,
      supportingDocument: supportingDocument,
    });
  }, [
    bankName,
    bankAddress,
    accountNumber,
    beneficiaryName,
    beneficiaryAddress,
    swiftOrIfscCode,
    financeEmailId,
    supportingDocument]);

  return (
    <div className={styles.formContainer} data-testid="bank-details-form">
      {isOpenUploadOption ? (
        <UploadForm
          onClose={()=>{}}
          onFileSelect={() => {}}
        />
      ) : (
        <div className={styles.wrapperContainer}>
          <div className={styles.leftContainer}>
            <DocumentUploader
              handleUploadDoc={()=>{}}
              supportingDocument={supportingDocument}
              disabled={true}
            />
          </div>
          <div className={styles.rightContainer}>
            <label>
              Required for bank details purpose <br /> (Optional)
            </label>
            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setBankName(value as string)}
                placeholder={"Bank Name"}
                type={"text"}
                value={bankName}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setBankAddress(value as string)}
                placeholder={"Bank Address"}
                type={"text"}
                value={bankAddress}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setAccountNumber(value as string)}
                placeholder={"Bank Account Number"}
                type={"text"}
                value={accountNumber}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setBeneficiaryName(value as string)}
                placeholder={"Beneficiary Name"}
                type={"text"}
                value={beneficiaryName}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setBeneficiaryAddress(value as string)}
                placeholder={"Beneficiary Address"}
                type={"text"}
                value={beneficiaryAddress}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setSwiftOrIfscCode(value as string)}
                placeholder={"SWIFT / IFSC Code"}
                type={"text"}
                value={swiftOrIfscCode}
              />
            </div>

            <div className={styles.inputBoxContainer}>
              <SkyInputBox
                isValid={true}
                onChange={(value) => setFinanceEmailId(value as string)}
                placeholder={"Finance Email ID"}
                type={"text"}
                value={financeEmailId}
              />
            </div>

            {errorMessage && (
              <div className={styles.error}>
                <img src={error} alt="error-icon" />
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default BankDetailsForm;
