import React, { FC, useState } from "react";
import styles from "./DialCodeSelector.module.css";
import classNames from "classnames";
import * as AllCountry from "react-flags-select";
import { Block } from "@mui/icons-material";

export type Country = {
  id: string;
  name: string;
  code: string;
  dialCode: string;
  flag?: JSX.Element;
}

export type Props = {
  countries: Country[];
  backgroundColor?: string;
  fontColor?: string;
  onSelect: (country: Country) => void;
};

const getCountryFlag = (countryCode) => {
  const countryFlag = AllCountry[countryCodeToPascalCase(countryCode)];
  if (!countryFlag)
    return (<Block data-testid={"no-flag-found-icon"}/>);
  else {
    const props = {};
    props["data-testid"] = `country-flag-${countryCode}`;
    return(
      React.createElement(countryFlag, {
        ...props,
      })
    );
  }
}

const countryCodeToPascalCase = (countryCode: string): string => {
  return `${countryCode.slice(0, 1)}${countryCode.charAt(1).toLowerCase()}`;
};

const DialCodeSelector: FC<Props> = (props: Props) => {
  const [searchDialCode, setSearchDialCode] = useState("");

  return (
    <div className={styles.countryCodeDropdownMenuContainer}
    data-testid={"dial-code-selector"}>
      <input
        type="text"
        className={classNames(styles.font, styles.countryCodeSearch)}
        placeholder="Search..."
        value={searchDialCode}
        onChange={(e) => setSearchDialCode(e.target.value)}
        data-testid="dial-code-selector-search-input"
      />
      <div className={styles.countryCodeDropdownMenu}
        style={{backgroundColor: props.backgroundColor ? props.backgroundColor : ""}}
           data-testid={"dial-code-selector-options"}
      >
        {props.countries
          .filter(
            (country) =>
              country.name
                .toLowerCase()
                .startsWith(searchDialCode.toLowerCase()) ||
              country.dialCode.startsWith(searchDialCode)
          )
          .map((country, index) => (
            <div
              key={index}
              className={styles.countryCodeDropdownOption}
              style={{color: props.fontColor ? props.fontColor : ""}}
              onClick={() => props.onSelect({
                id: country.id,
                name: country.name,
                code: country.code,
                dialCode: country.dialCode,
                flag: getCountryFlag(country.code)
              })}
            >
              <div>{getCountryFlag(country.code)}</div>
              <div>{country.name} ( {country.dialCode} ) </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DialCodeSelector;
