import React, { FC, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import styles from "../CDRSearch/CDRSearch.module.css";
import { notyf } from "../../../notyf";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import { fetchCDRSearchList } from "../../../api/endpoint";
import { CDRSearchResponse } from "../../../models/response/CDRSearchResponse";
import moment from "moment";

type Props = {
  date: Date;
  startTime: string;
  endTime: string;
  callerNumber?: number;
  calledNumber?: number;
};
const CDRSearch: FC<Props> = (props) => {
  const [page] = useState<number>(1);
  const [tableCells, setTableCells] = useState<(() => JSX.Element)[][]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const columns = [
    "Start Time",
    "End Time",
    "Caller",
    "Called Number",
    "Origination IP",
  ];
  const { accessToken } = useContext(AuthContext);
  useEffect(() => {
    fetchCDRSearchList(
      accessToken ? accessToken : "",
      moment(props.date).format("YYYY-MM-DD"),
      page,
      props.startTime,
      props.endTime,
      props.callerNumber ? props.callerNumber + "" : "",
      props.calledNumber ? props.calledNumber + "" : ""
    )
      .then((cdrSearchResponse: CDRSearchResponse) => {
        try {
          setTableCells(
            cdrSearchResponse.data.map((response) => {
              return [
                () => <>{response.startTime}</>,
                () => <>{response.endTime}</>,
                () => <>{response.cli}</>,
                () => <>{response.calledNumber}</>,
                () => <>{response.originationIp}</>,
              ];
            })
          );
        } finally {
          setIsLoading(false);
        }
      })
      .catch(() => {
        notyf.error("Unable to fetch CDR data");
      });
  }, [
    props.callerNumber,
    props.calledNumber,
    props.date,
    props.endTime,
    props.startTime,
  ]);

  return (
    <div className={styles.cdrContainer} data-testid="cdr-search-container">
      <SkyGrid isLoading={isLoading} columns={columns} renderer={tableCells} />
    </div>
  );
};

export default CDRSearch;
