import React, {
  FunctionComponent,
  useContext,
  useState,
} from "react";
import leftside from "../../images/login.svg";
import styles from "./IdentityScreen.module.css";
import pegionlogo from "../../images/pegionlogo.png";
import SignUpForm from "../../components/business/SignUpForm/SignUpForm";
import classNames from "classnames";
import Login from "../../components/business/Login/Login";
import {FeatureToggleContext} from "../../contextApi/FeatureToggleContext/FeatureToggleContext";

enum IdentityLevel {
  LOGIN,
  SIGNUP
}

const IdentityScreen: FunctionComponent = () => {
  const [identityLevel, setIdentityLevel] = useState(IdentityLevel.LOGIN);
  const {isSignUpEnabled} = useContext(FeatureToggleContext);

  return (
    <div className={styles.container}>
      <div className={styles.welcomeContainer}>
        <div className={styles.welcomeHeading}>Welcome</div>
        <div className={styles.imageContainer}>
          <img
            src={leftside}
            alt="A girl standing in front of a computer wearing headphone with a tab in her hand."
            className={styles.welcomeImage}
          />
        </div>
      </div>

      <div className={classNames(styles.formOuterContainer,
        identityLevel === IdentityLevel.SIGNUP ? styles.signUpFormContainer : styles.loginFormContainer)}>
        <div className={styles.formInnerContainer}>
          <img
            src={pegionlogo}
            alt="logo"
            className={classNames(styles.skyFormLogo, styles.logoImageLoading)}
          />
          {isSignUpEnabled && <div className={styles.navigationContainer} data-testid={"navigation-bar"}>
              <div
                  className={classNames(
                    styles.navigationItem,
                    identityLevel === IdentityLevel.LOGIN && styles.selectedNavigationItem)
                  }
                  data-testid={"login-navigation-item"}
                  onClick={() => setIdentityLevel(IdentityLevel.LOGIN)}
              >Login
              </div>
              <div
                  className={classNames(
                    styles.navigationItem,
                    identityLevel === IdentityLevel.SIGNUP && styles.selectedNavigationItem)
                  }
                  data-testid={"sign-up-navigation-item"}
                  onClick={() => setIdentityLevel(IdentityLevel.SIGNUP)
                  }>Register
              </div>
          </div>
          }

          {identityLevel === IdentityLevel.LOGIN && <div className={styles.formContainer}>
              <Login/>
          </div>}

          {identityLevel === IdentityLevel.SIGNUP && <div className={styles.formContainer}>
              <SignUpForm/>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default IdentityScreen;
