import { FC, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./SkyButton.module.css";
import { UserDetailsContext } from "../../../contextApi/UserDetailsContext/UserDetailsContext";

export enum ButtonSize {
  LARGE,
  MEDIUM,
  SMALL,
  FIT_CONTENT,
}

export enum ButtonType {
  DEFAULT,
}

export type Props = {
  text?: string;
  renderer?: () => JSX.Element;
  size: ButtonSize;
  type?: ButtonType;
  onClick: () => void;
  testId?: string;
  disabled?: boolean;
};
const SkyButton: FC<Props> = (props: Props) => {
  const { user } = useContext(UserDetailsContext);

  const computeDisabled = () => {
    return props.disabled != undefined
      ? props.disabled
      : user?.status !== "ACTIVE";
  };

  const [isDisabled, setIsDisabled] = useState<boolean>(computeDisabled());

  useEffect(() => {
    setIsDisabled(computeDisabled);
  }, [props.disabled, user]);

  const getButtonSizeClass = (size: ButtonSize) => {
    switch (size) {
      case ButtonSize.LARGE:
        return styles.largeBtn;
      case ButtonSize.MEDIUM:
        return styles.mediumBtn;
      case ButtonSize.SMALL:
        return styles.smallBtn;
      case ButtonSize.FIT_CONTENT:
        return styles.fitContentBtn;
    }
  };

  const getButtonTypeClass = (type: ButtonType | undefined) => {
    switch (type) {
      case ButtonType.DEFAULT:
      case undefined:
        return styles.defaultBtnType;
    }
  };

  return (
    <button
      className={classNames(
        styles.skyButton,
        getButtonTypeClass(props.type),
        getButtonSizeClass(props.size),
        isDisabled ? styles.disabled : ""
      )}
      onClick={props.onClick}
      data-testid={props.testId}
      disabled={isDisabled}
    >
      {props.text ? props.text : props.renderer ? props.renderer() : ""}
    </button>
  );
};

export default SkyButton;
