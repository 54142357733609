import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./subMenuCardsContainer.module.css";
import { useNavigate } from "react-router-dom";
import { SubMenuItem } from "../../../domains/Menu";

export type Props = {
  subMenuItems: SubMenuItem[];
  setFontSize?: (size: number) => void;
};

const SubMenuCardsContainer: FC<Props> = (props) => {
  const [fontSize, setFontSize] = useState<number>();
  const navigate = useNavigate();
  const card = useRef(null);

  useEffect(() => {
    const interval = setInterval(function () {
      if (card.current) {
        setFontSize(card.current["offsetWidth"] / 6);
        clearInterval(interval);
      }
    }, 100);
  }, [card.current]);

  const handleClickCard = (route: string) => {
    navigate(route);
  };

  return (
    <div className={styles.menuItemCardsContainer} data-testid="sip-container">
      {props.subMenuItems?.map((subMenu, index) => (
        <div
          className={styles.cardOuterDiv}
          style={{ background: subMenu.squareShadowColor }}
          onClick={() => handleClickCard(subMenu.route)}
          data-testid="info-box-outer"
          key={index}
        >
          <div
            className={styles.cardInnerDiv}
            data-testid="info-box-inner"
            style={{ background: subMenu.squareColor }}
          >
            <div className={styles.cardContentDiv}>
              <div className={styles.circle} />
              <div
                ref={card}
                className={styles.titleText}
                style={{ fontSize: fontSize }}
              >
                <span> {subMenu.title} </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubMenuCardsContainer;
