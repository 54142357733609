import React, { FC } from "react";
import styles from "./SkyInputBox.module.css";
import classNames from "classnames";

export type Props = {
  testId?: string;
  type: string;
  label?: string;
  placeholder: string;
  value: string;
  isValid: boolean;
  onChange: (value: string | number) => void;
};

const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*._-])[A-Za-z\d!@#$%^&*._-]{8,15}$/;
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]{1,30}@[a-zA-Z0-9-]{1,30}(?:\.[a-zA-Z0-9-]{1,30})+$/;

export const isEmpty = (value: string) => value.trim().length == 0;
export const isValidEmail = (value: string) =>
  isEmpty(value) || emailRegex.test(value);
export const isValidPassword = (value: string) =>
  isEmpty(value) || passwordRegex.test(value);

const SkyInputBox: FC<Props> = (props: Props) => {
  return (
    <>
      {props.label && (
        <div
          data-testid={"label-" + props.testId}
          className={styles.skyInputLabel}
        >
          {props.label}
        </div>
      )}
      <input
        data-testid={props.testId}
        type={props.type}
        className={classNames(
          styles.skyInput,
          props.isValid ? "" : styles.errorInput
        )}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      />
    </>
  );
};

export default SkyInputBox;
