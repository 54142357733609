import { toast } from "react-toastify";

export const notyf = {
  error: (message: string) => {
    toast.error(message, {
      toastId: message,
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  },

  success: (message: string) => {
    toast.success(message, {
      toastId: message,
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  },
};
