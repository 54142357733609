import React, { FC, useState, useRef, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import classNames from "classnames";
import styles from "./UserTrunkList.module.css";
import { CLIDetails } from "../../../models/response/UserTrunkListResponse";

type Props = {
  cliDetails: CLIDetails[];
};

const CLIDetailsContainer: FC<Props> = (props) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      {props.cliDetails.length}
      <span onClick={handleDropdownToggle}>
        <ArrowBackIosIcon
          data-testid="arrow-icon"
          className={classNames(
            styles.arrowDownIcon,
            showDropdown ? styles.upward : ""
          )}
        />
      </span>
      {showDropdown && (
        <div ref={dropdownRef} className={styles.dropdownTableContainer}
             data-testid="dropdown-container">
          <table cellSpacing={0}>
            <thead>
            <tr>
              <th>Country Code</th>
              <th>CLI</th>
            </tr>
            </thead>
            <tbody>
            {props.cliDetails.map((cliDetail) => (
              <tr className={styles.ipContainer} key={cliDetail.cli}>
                <td>{cliDetail.countryCode}</td>
                <td>{cliDetail.cli}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CLIDetailsContainer;
