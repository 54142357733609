import React, {FunctionComponent, useContext, useEffect, useState,} from "react";
import styles from "./LoginForm.module.css";
import {SkyInputField} from "../../../base/SkyForm/skyForm";
import {useNavigate} from "react-router-dom";
import {authenticateUser} from "../../../../api/endpoint";
import {UserLoginRequest} from "../../../../models/request/UserLoginRequest";
import {AuthContext} from "../../../../contextApi/AuthContext/authContext";
import {UserLoginResponse} from "../../../../models/response/UserLoginResponse";
import cookie from "react-cookies";
import loaderClip from "../../../../images/Loader.mp4";
import errorIcon from "../../../../icons/error-emoji.png";
import SkyButton, {ButtonSize} from "../../../base/SkyButton/SkyButton";

type Props = {
  onSubmit?: (loginRequest: UserLoginRequest) => void;
  onErrorResponse: (message: string) => void;
  onResetPassword: () => void;
};

enum LoginFromLevel {
  USERNAME_INPUT = "USERNAME_INPUT",
  PASSWORD_INPUT = "PASSWORD_INPUT",
}

const LoginForm: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [submitButtonText, setSubmitButtonText] = useState<string>("Next");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const usernameField: SkyInputField = {
    label: "Email / Username",
    type: "text",
    id: "username",
    name: "username",
    placeholder: "username / email",
    onchange: (e) => setUsername((e.target as HTMLInputElement).value),
  };
  const passwordField: SkyInputField = {
    label: "Password",
    type: "password",
    id: "password",
    name: "password",
    placeholder: "********",
    onchange: (e) => setPassword((e.target as HTMLInputElement).value),
  };
  const [formFields, setFormFields] = useState<SkyInputField[]>([
    usernameField,
  ]);
  const [formLevel, setFormLevel] = useState<LoginFromLevel>(
    LoginFromLevel.USERNAME_INPUT
  );

  const { setAccessToken, removeAccessToken } = useContext(AuthContext);

  const handleUsernameInput = () => {
    if (username.trim() === "") {
      setErrorMessage("Email/Username should not be blank");
      return;
    }
    setFormLevel(LoginFromLevel.PASSWORD_INPUT);
    setFormFields([usernameField, passwordField]);
    setSubmitButtonText("Login");
  };

  const handlePasswordInput = () => {
    if (password.trim() === "") {
      setErrorMessage("Password should not be blank");
      return;
    }
    login();
  };

  const handleSubmit = () => {
    setErrorMessage("");
    if (formLevel === LoginFromLevel.USERNAME_INPUT) {
      handleUsernameInput();
    } else {
      handlePasswordInput();
    }
  };

  const login = () => {
    const loginRequest: UserLoginRequest = {
      username: username,
      password: password,
    };

    setIsLoading(true);
    authenticateUser(loginRequest)
      .then((loginResponse: UserLoginResponse) => {
        try {
          if (loginResponse.accessToken) {
            setAccessToken(loginResponse.accessToken);
            const cookieOptions = {
              path: "/",
              expires: new Date(Date.now() + 60 * 60 * 1000),
            };
            cookie.save(
              "accessToken",
              loginResponse.accessToken,
              cookieOptions
            );
            navigate("/");
          }
        } finally {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        if (
          e.response != undefined &&
          e.response.data != undefined &&
          e.response.data.error != undefined
        ) {
          props.onErrorResponse(e.response.data.error);
        } else setErrorMessage("Incorrect username or password");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const handleUnload = () => {
      removeAccessToken();
      cookie.remove("accessToken", { path: "/" });
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [removeAccessToken]);

  const getErrorMessage = (fieldName: string) => {
    if (errorMessage && errorMessage.length > 0) {
      return (
        <div
          className={styles.errorMessageContainer}
          data-testid={`error-message_${fieldName}`}
        >
          <img src={errorIcon} alt="error icon" className={styles.errorIcon} />
          {errorMessage}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <video className={styles.loader} autoPlay muted loop>
            <source src={loaderClip} />
          </video>
        </div>
      )}
      {!isLoading && (
        <div className={styles.fieldContainer} data-testid="login-form">
          {formFields.map((field) => {
            return (
              <div key={field.id} className={styles.fieldContainer}>
                <label htmlFor={field.name} className={styles.formLabel}>
                  {field.label}
                </label>
                <input
                  className={styles.formInput}
                  value={field.value}
                  type={field.type}
                  onChange={field.onchange}
                  id={field.id}
                  name={field.name}
                  placeholder={field.placeholder}
                />
              </div>
            );
          })}
          {getErrorMessage("username")}

          <div className={styles.formButtonContainer}>
            <SkyButton
              size={ButtonSize.LARGE}
              onClick={() => handleSubmit()}
              text={submitButtonText}
              disabled={false}/>
          </div>

          <div
            data-testid="reset-password-text"
            className={styles.forgotPasswordText}
            onClick={() => props.onResetPassword()}
          >
            Reset Password
          </div>
        </div>
      )}
    </>

  );
};

export default LoginForm;
