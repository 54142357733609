import React, { FC, useContext, useState } from "react";
import waitingimage from "../../../../images/downloading.gif";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { formatDateToyyyyMMdd } from "../../../../domains/Date";
import { notyf } from "../../../../notyf";
import { downloadCDR } from "../../../../api/endpoint";
import { AuthContext } from "../../../../contextApi/AuthContext/authContext";
import styles from "./DownloadIcon.module.css";

type Props = {
  date: Date;
};

const DownloadIcon: FC<Props> = (props) => {
  const [isDownloadInProgress, setIsDownloadInProgress] =
    useState<boolean>(false);
  const { accessToken } = useContext(AuthContext);

  const downloadCDRFile = (date: Date) => {
    setIsDownloadInProgress(true);
    if (accessToken)
      downloadCDR(accessToken, formatDateToyyyyMMdd(date))
        .then((response) => {
          const downloadLink = URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = downloadLink;
          link["data-testid"] = "downloadLink";
          link.setAttribute(
            "download",
            "CDR_" + formatDateToyyyyMMdd(date) + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((e) => notyf.error("Unable to download CDR file"))
        .finally(() => setIsDownloadInProgress(false));
  };

  return (
    <>
      {!isDownloadInProgress && (
        <div
          onClick={() => downloadCDRFile(props.date)}
          data-testid="download-icon"
        >
          <CloudDownloadOutlinedIcon className={styles.downloadIcon} />
        </div>
      )}
      {isDownloadInProgress && (
        <div data-testid="download-icon-waiting">
          <img src={waitingimage} className={styles.downloadIconWaiting} />
        </div>
      )}
    </>
  );
};

export default DownloadIcon;
