import React, {FC, useState} from "react";
import styles from "../BaseScreen.module.css";
import loudspeaker from "../../../icons/speaker.svg";
import CommentIcon from '@mui/icons-material/Comment';
import Dialog, {Size} from "../../../components/business/Dialog/Dialog";
import kycButton from "../../../icons/KYC Button.svg"
import resubmitButton from "../../../icons/resubmit kyc button.svg" ;

type Props = {
  clientName: string;
  isNonProd: boolean;
  onClick: () => void;
  userStatus?: string;
  rejectionReason?: string;
};

const KYCBanner: FC<Props> = (props) => {
  const [isCommentBoxOpen, setIsCommentBoxOpen] = useState<boolean>(false);
  let content;

  if (props.userStatus === "KYC_REJECTED") {
    content = (
      <>
        <p>
          Dear {props.clientName}, your KYC request has been rejected. Please check the comment & resubmit
          your KYC.
        </p>
        <img
          className={styles.kycButton}
          src={resubmitButton}
          alt="resubmit-button"
          onClick={props.onClick}
          data-testid="resubmit-kyc"
        />

        <CommentIcon
          className={styles.commentIcon}
          onClick={() => setIsCommentBoxOpen(true)}
          data-testid="kyc-comment-box"
        />
      </>
    );
  } else if (props.userStatus === "KYC_PENDING") {
    content = (
      <>
        <p>
          Dear {props.clientName}, you can explore our offerings with limited access. Please submit KYC
          details for availing all the services.
        </p>
        <img
          className={styles.kycButton}
          src={kycButton}
          alt="kyc-button"
          onClick={props.onClick}
          data-testid="submit-kyc"
        />
      </>
    );
  } else if (props.userStatus === "KYC_SUBMITTED") {
    content = (
      <>
        <p>Dear {props.clientName} thanks for submitting KYC. KYC details are being reviewed.</p>
      </>
    )
  } else {
    content = <></>
  }

  const backgroundColor = (status: string | undefined) => {
    switch (status) {
      case "KYC_REJECTED":
        return "#FF003D";
      case "KYC_PENDING":
        return "#B68AFF";
      case "KYC_SUBMITTED":
        return "#02a861";
    }
  }

  return (
    <>
      {isCommentBoxOpen && (
        <Dialog
          size={Size.S}
          header={"Reason for Rejection"}
          message={props.rejectionReason
            ? props.rejectionReason
            : "Unknown reason. Please contact support@skytelservices.com for assistance."}
          onConfirm={() => setIsCommentBoxOpen(false)}
          testId={"kyc-rejection-reason-dialog"}
        />
      )}
      <div
        className={styles.kycPendingBannerContainer}
        data-testid="kycPending-banner"
        style={{
          backgroundColor: backgroundColor(props.userStatus)
        }}
      >
        <div className={styles.kycBanner}>
          <img className={styles.loudspeaker} src={loudspeaker} alt="loudspeaker"/>
          {content}
          {props.isNonProd && (
            <div className={styles.kycBannerDev}>
              This is Development Environment
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default KYCBanner;