import {DailyAccountSummaryReportResponse} from "../models/response/DailyAccountSummaryReportResponse";
import {AreaWiseCallReportResponse} from "../models/response/AreaWiseCallReportResponse";

export const areaChartDummyData:DailyAccountSummaryReportResponse[] = [
  {
    date: "2024-05-06",
    charge: 5,
    callCount: 10,
    callDuration: "02:20:45",
    acd: 1.70,
    asr: 82,
  },
  {
    date: "2024-05-07",
    charge: 5,
    callCount: 10,
    callDuration: "02:35:15",
    acd: 1.80,
    asr: 86,
  },
  {
    date: "2024-05-01",
    charge: 5,
    callCount: 20,
    callDuration: "02:30:45",
    acd: 1.75,
    asr: 85,
  },
  {
    date: "2024-05-02",
    charge: 10,
    callCount: 40,
    callDuration: "02:15:30",
    acd: 1.65,
    asr: 80,
  },
  {
    date: "2024-05-03",
    charge: 10,
    callCount: 5,
    callDuration: "02:45:00",
    acd: 1.85,
    asr: 88,
  },
  {
    date: "2024-05-04",
    charge: 40,
    callCount: 25,
    callDuration: "02:05:15",
    acd: 1.55,
    asr: 78,
  },
];

export const pieChartDummyData:AreaWiseCallReportResponse[] = [
  {
    areaCode: "2000018",
    cdrCount: 10,
    totalFee: 0,
    totalTime: 90,
  },
  {
    areaCode: "2002089",
    cdrCount: 20,
    totalFee: 10,
    totalTime: 180,
  },
  {
    areaCode: "1008020",
    cdrCount: 15,
    totalFee: 5,
    totalTime: 150,
  },
  {
    areaCode: "2000021",
    cdrCount: 8,
    totalFee: 0,
    totalTime: 80,
  },
  {
    areaCode: "2000022",
    cdrCount: 12,
    totalFee: 7,
    totalTime: 110,
  },
];
