import React, {FC, useState} from "react";
import styles from "./SkyDropdown.module.css";
import classNames from "classnames";
import DotLoader from "../../business/DotLoader/DotLoader";

type Option = {
  id?: string;
  value: string;
}

export type Props = {
  data: Option[];
  isSearchable?: boolean;
  onSelect: (data: any) => void;
  testId?: string;
  isLoading?: boolean;
};

const SkyDropdown: FC<Props> = (props: Props) => {
  const [searchText, setSearchText] = useState("");

  return (
    <div className={styles.dropdownContainer}
         data-testid={props.testId ? props.testId : "sky-dropdown"}
    >
      {props.isSearchable && (
        <div className={styles.searchBar}
             data-testid={props.testId ? `${props.testId}-searchbar` : "sky-dropdown-searchbar"}>
          <input
            type="text"
            data-testid={props.testId ? `${props.testId}-search-input` : "sky-dropdown-search-input"}
            className={classNames(styles.font, styles.searchInput)}
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      )}
      {props.isLoading && <DotLoader/>}

      <div className={styles.dropdownOptionContainer}
           data-testid={props.testId ? `${props.testId}-options` : "sky-dropdown-options"}
      >
        {!props.isLoading && (props.data.length > 0 ?
          props.data
            .filter((data) =>
              data.value.toLowerCase().startsWith(searchText.toLowerCase())
            )
            .map((data) => (
              <div key={data.id} onClick={() => props.onSelect(data)}>{data.value}</div>
            )) : (
            <div>
              <span className={classNames(styles.font, styles.dataNotFoundMessage)}>No Data Found!</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SkyDropdown;
