import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./MigrateUserForm.module.css";
import welcomeIcon from "../../../icons/welcome.png";
import errorIcon from "../../../icons/error-emoji.png";
import { notyf } from "../../../notyf";
import { migrateUser } from "../../../api/endpoint";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import { useNavigate } from "react-router-dom";
import DotLoader from "../DotLoader/DotLoader";
import classNames from "classnames";
import { isOTPRequired } from "../../../requester";
import { OTPContext } from "../../../contextApi/OTPContext/OTPContext";
import SkyButton, { ButtonSize } from "../../base/SkyButton/SkyButton";

type Props = {
  onClose: () => void;
};

const MigrateUserForm: FC<Props> = (props) => {
  const navigate = useNavigate();
  const { accessToken } = useContext(AuthContext);

  const [email, setEmail] = useState<string>("");
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(true);
  const [isEmailMatch, setIsEmailMatch] = useState<boolean>(true);
  const [showThankYouDialog, setShowThankYouDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commonError, setCommonError] = useState<string>("");
  const validPasswordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*.-])[A-Za-z\d!@#$%^&*.-]{8,}$/;
  const validEmailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,30}@[a-zA-Z0-9-]{1,30}(?:\.[a-zA-Z0-9-]{1,30})+$/


  const {
    setOTPRequired,
    setErrorResponse,
    isOTPVerificationSuccess,
    setIsOTPVerificationSuccess,
  } = useContext(OTPContext);

  useEffect(() => {
    if (isOTPVerificationSuccess) {
      setShowThankYouDialog(true);
      setIsOTPVerificationSuccess(false);
    }
  }, [isOTPVerificationSuccess]);

  const areRequiredFieldsBlank = () =>
    email === "" ||
    confirmPassword === "" ||
    password === "" ||
    confirmEmail === "";

  const isFormDataValid = () => {
    const isBlank = areRequiredFieldsBlank();
    setCommonError(isBlank ? "All fields are required" : "");

    setIsEmailValid(validEmailRegex.test(email));
    setIsPasswordValid(validPasswordRegex.test(password));
    setIsEmailMatch(email == confirmEmail);
    setIsPasswordMatch(password == confirmPassword);

    return (
      email == confirmEmail &&
      password == confirmPassword &&
      validEmailRegex.test(email) &&
      validPasswordRegex.test(password) &&
      !isBlank
    );
  };

  const handleSubmit = () => {
    if (isFormDataValid()) {
      try {
        setIsLoading(true);
        migrateUser(accessToken ? accessToken : "", {
          username: email,
          password: password,
        })
          .then((_) => {
            setShowThankYouDialog(true);
          })
          .catch((error) => {
            if (isOTPRequired(error)) {
              setOTPRequired(true);
              setErrorResponse(error);
            } else if (error.response && error.response.status === 409) {
              setCommonError("Email id is already registered.");
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
        notyf.error("Failed to connect to the server. Please try again later.");
      }
    }
  };

  const handleThankYouDialogClose = () => {
    setShowThankYouDialog(false);
    props.onClose();
    navigate("/login");
  };

  const renderErrorText = (text: string) =>
    text.trim().length > 0 && (
      <>
        <img src={errorIcon} alt="error icon" className={styles.errorIcon} />
        <div className={styles.errorText}>{text}</div>
      </>
    );

  return (
    <div className={styles.migrateUserFormContainer}>
      {showThankYouDialog ? (
        <div className={styles.migrateUserFormThankYouContainer}>
          <img
            src={welcomeIcon}
            alt={"welcome Icon"}
            className={styles.welcomeIcon}
          />
          <h2 className={styles.thankYouHeader}>Thank You!</h2>
          <p className={styles.thankYouText}>
            Thanks for updating your login credentials. You can use your updated
            credentials for login.
          </p>
          <div className={styles.infoText}>
            You will be redirected to the login page.
          </div>
          <div className={styles.thankYouOkButton}>
            <SkyButton
              text={"OK"}
              onClick={handleThankYouDialogClose}
              size={ButtonSize.SMALL}
              testId={"baseScreen-dialog-thankYou-ok-button"}
              disabled={false}
            />
          </div>
        </div>
      ) : (
        <div className={styles.migrateUserFormUpdateContainer}>
          <br />
          <p className={classNames(styles.infoText,styles.padding)}>
            Please provide a valid email. The provided details will be used as
            login credentials.
          </p>
          <div className={styles.migrateUserEmailContainer}>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your Email Id"
              onChange={(e) => {
                setEmail(e.target.value);
                setIsEmailValid(validEmailRegex.test(e.target.value));
              }}
              data-testid="migrateUser-email"
              className={classNames(
                styles.inputField,
                !isEmailValid ? styles.errorInputField : ""
              )}
            />

            <div className={styles.errorContainer}>
              {!isEmailValid && renderErrorText("Invalid Email")}
            </div>
          </div>

          <div className={styles.migrateUserConfirmEmailContainer}>
            <input
              type="email"
              id="confirmEmail"
              name="confirmEmail"
              placeholder="Retype your Email Id"
              onChange={(e) => {
                setConfirmEmail(e.target.value);
                setIsEmailMatch(email == e.target.value);
              }}
              data-testid="migrateUser-confirm-email"
              className={classNames(
                styles.inputField,
                !isEmailMatch ? styles.errorInputField : ""
              )}
            />
            <div className={styles.errorContainer}>
              {!isEmailMatch && renderErrorText("Email ID doesn't match")}
            </div>
          </div>
          <div className={styles.migrateUserPasswordBoxContainer}>
            <div className={styles.migrateUserPasswordContainer}>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setIsPasswordValid(validPasswordRegex.test(e.target.value));
                }}
                data-testid="migrateUser-password"
                className={classNames(
                  styles.inputField,
                  !isPasswordValid ? styles.errorInputField : ""
                )}
              />
              <p className={styles.infoText}>
                Min. 8 chars: Include UPPER & lower cases, numbers, & special
                symbols.
              </p>
            </div>
            <div className={styles.migrateUserConfirmPasswordContainer}>
              <input
                type="password"
                id="ConfirmPassword"
                name="confirmPassword"
                placeholder="Retype your Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setIsPasswordMatch(password == e.target.value);
                }}
                data-testid="migrateUser-confirm-password"
                className={classNames(
                  styles.inputField,
                  !isPasswordMatch ? styles.errorInputField : ""
                )}
              />
              <div className={styles.errorContainer}>
                {!isPasswordMatch && renderErrorText("Password doesn't match")}
              </div>
            </div>
          </div>
          <div className={styles.errorContainer}>
            {renderErrorText(commonError)}
          </div>
          {isLoading ? (
            <div className={styles.migrateDotLoader}>
              <DotLoader />
            </div>
          ) : (
            <div className={styles.migrateUpdateButton}>
              <SkyButton
                text={"Update"}
                onClick={handleSubmit}
                size={ButtonSize.SMALL}
                testId={"migrateUser-dialog-update-button"}
                disabled={false}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default MigrateUserForm;
