import React, { FC, useState } from "react";
import { DateRange } from "react-date-range";
import { addMonths, addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styles from "./calender.module.css";

export type Props = {
  onChange: (from: Date, to: Date) => void;
};

const SkyCalender: FC<Props> = (props) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [range, setRange] = useState([
    {
      startDate: addDays(today, -15),
      endDate: today,
      key: "selection",
    },
  ]);

  return (
    <>
      <div className={styles.calendarContainer} data-testid="calendarContainer">
        <DateRange
          onChange={(item) => {
            const fromDate =
              item.selection.startDate != undefined
                ? item.selection.startDate
                : today;
            const toDate =
              item.selection.endDate != undefined
                ? item.selection.endDate
                : today;
            setRange([
              {
                startDate: fromDate,
                endDate: toDate,
                key: item.selection.key ? item.selection.key : "",
              },
            ]);
            props.onChange(fromDate, toDate);
          }}
          maxDate={addDays(new Date(), -1)}
          minDate={addMonths(new Date(), -2)}
          moveRangeOnFirstSelection={false}
          ranges={range}
          rangeColors={["#6f6af7"]}
          data-testid="calendar"
        />
      </div>
    </>
  );
};

export default SkyCalender;
