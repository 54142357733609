import React, {FC, useContext, useEffect, useState} from "react";
import {redirectToCheckout} from "airwallex-payment-elements";
import {AuthContext} from "../../contextApi/AuthContext/authContext";
import callnxtlogo from "../../images/pegionlogo.png";
import styles from "./RechargeScreen.module.css";
import {fetchFeatureTogglesList, initiatePayment} from "../../api/endpoint";
import {notyf} from "../../notyf";
import {InitiatePaymentResponse} from "../../models/response/InitiatePaymentResponse";
import DotLoader from "../../components/business/DotLoader/DotLoader";
import SkyButton, {ButtonSize} from "../../components/base/SkyButton/SkyButton";
import {UserDetailsContext} from "../../contextApi/UserDetailsContext/UserDetailsContext";

const RechargeScreen: FC = () => {
  const [amount, setAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [env, setEnv] = useState<"demo" | "prod">("demo");
  const { accessToken } = useContext(AuthContext);
  const { user} = useContext(UserDetailsContext);
  const logoURL =
    "https://bitbucket.org/pigeon-cloud/pigeon-ui/raw/e8dcaec4bacc8ad915351326c626a241d6bda5a4/src/images/pegionlogo.png";
  const paymentGatewayIntegrationScriptSource =
    "https://checkout.airwallex.com/assets/elements.bundle.min.js";

  useEffect(() => {
    if (accessToken)
      fetchFeatureTogglesList().then((featureToggles) => {
        setEnv(
          featureToggles.filter((ft) => ft.name == "IS_PROD_ENV")[0].value
            ? "prod"
            : "demo"
        );
      });
  });

  function redirectToPaymentPage(response: InitiatePaymentResponse) {
    const paymentGatewayIntegrationScript = document.createElement("script");

    paymentGatewayIntegrationScript.onload = () => {
      redirectToCheckout({
        env: env,
        mode: "payment",
        currency: response.currency,
        intent_id: response.id,
        client_secret: response.clientSecret,
        successUrl: `${window.location.origin}/recharge/confirmation`,
        failUrl: `${window.location.origin}/recharge/confirmation`,
        logoUrl: logoURL,
        googlePayRequestOptions: {
          countryCode: "US",
          buttonColor: "black",
        },
      });
    };
    paymentGatewayIntegrationScript.src = paymentGatewayIntegrationScriptSource;
    document.head.appendChild(paymentGatewayIntegrationScript);
  }

  const processPayment = () => {
    if (amount < 30) {
      notyf.error("Minimum amount should be $30.");
      return;
    }
    setIsLoading(true);
    initiatePayment(accessToken ? accessToken : "", amount)
      .then((response) => {
        try {
          redirectToPaymentPage(response);
        } finally {
          setIsLoading(false);
        }
      })
      .catch(() => {
        notyf.error("Unable to initiate payment.");
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.outerContainer} data-testid="recharge">
      <div className={styles.rechargeContainer}>
        <div className={styles.topBar}>
          <img src={callnxtlogo} alt="logo" className={styles.logoImage} />
        </div>

        <div className={styles.rechargeHeading}>
          <h2>Recharge Amount</h2>

          <p className={styles.rechargePara}>
            We will be processing the same amount
            <br />
            and you will be notified via email
          </p>
        </div>

        <div className={styles.formFieldContainer}>
          <div className={styles.row}>
            <div className={styles.label}>Currency</div>
            <div className={styles.usd}>: USD</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Amount</div>
            <div className={styles.amountFieldContainer}>
              <input
                className={styles.amountField}
                type="number"
                value={amount == 0 ? "" : amount}
                onChange={(event) => setAmount(parseFloat(event.target.value))}
                placeholder=" $ 00.00"
                data-testid={"amount-field"}
              />
            </div>
          </div>
        </div>
        {!isLoading && (
            <SkyButton
                text={"Pay"}
                size={ButtonSize.SMALL}
                onClick={processPayment}
                disabled={!amount || (user?.status !== "ACTIVE")}
            />
        )}
        {isLoading && <DotLoader />}
      </div>
    </div>
  );
};

export default RechargeScreen;
