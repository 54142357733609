import React, { FC, Fragment } from "react";
import styles from "./subMenuInfoContainer.module.css";
import Bottom from "../../../images/bottomimageintial.png";
import topIcon from "../../../images/trunkboxtopicon.png";
import { SubMenuItem } from "../../../domains/Menu";

interface Props {
  subMenuItems: SubMenuItem[];
  graphics?: string;
}

const SubMenuInfoContainer: FC<Props> = (props) => {
  return (
    <div className={styles.infoContainer} data-testid="info-container">
      {props.subMenuItems.map((subMenuItem, index) => (
        <Fragment key={index}>
          <div className={styles.titleContainer}>
            <span
              className={styles.titleText}
              style={{ color: subMenuItem.headerColor }}
            >
              {subMenuItem.title}
            </span>
            {index == 0 && (
              <img
                src={topIcon}
                alt="two circle collapse in each other"
                className={styles.circleImage}
              />
            )}
          </div>
          <div className={styles.descriptionContainer}>
            <p>{subMenuItem.description}</p>
          </div>
        </Fragment>
      ))}

      <div>
        <img
          src={props.graphics ? props.graphics : Bottom}
          alt=" a line that is connecting dots"
          className={styles.bottomImage}
        />
      </div>
    </div>
  );
};

export default SubMenuInfoContainer;
