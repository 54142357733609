export type KYCData = {
  businessDetails: BusinessDetails;
  companyDetails: CompanyDetails;
  bankDetails: BankDetails;
  addressDetails: AddressDetails;
  taxDetails: TaxDetails;
  personalDetails: PersonalDetails;
};

export interface KYCDetails {}

export enum BusinessType {
  PROPRIETARY = "PROPRIETARY",
  PARTNERSHIP = "PARTNERSHIP",
  PRIVATE = "PRIVATE",
  RESELLER = "RESELLER",
  NONE = "",
}

export enum TrafficType {
  DIALER = "DIALER",
  MANUAL = "MANUAL",
  RETAIL = "RETAIL",
  NONE = "",
}

export interface BusinessDetails extends KYCDetails {
  businessType: BusinessType;
  trafficType: TrafficType;
  tradingName: string;
}

export interface CompanyDetails extends KYCDetails {
  companyName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  supportingDocument: string;
}

export interface BankDetails extends KYCDetails {
  bankName: string,
  bankAddress: string,
  accountNumber: string,
  beneficiaryName: string,
  beneficiaryAddress: string,
  swiftOrIfscCode: string,
  financeEmailId: string,
  supportingDocument: string,
}

export interface AddressDetails extends KYCDetails {
  ownerName: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  supportingDocument: string;
}

export interface TaxDetails extends KYCDetails {
  companyNumber: string;
  taxNumber: string;
  supportingDocument: string;
}

export interface PersonalDetails extends KYCDetails {
  attnName: string;
  email: string;
  chatId: string;
  directNo: string;
  supportingDocument: string;
}

export const blankKYCData: KYCData = {
  businessDetails: {
    businessType: BusinessType.NONE,
    trafficType: TrafficType.NONE,
    tradingName: "",
  },
  companyDetails: {
    companyName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    supportingDocument: "",
  },
  bankDetails: {
    bankName: "",
    bankAddress: "",
    accountNumber: "",
    beneficiaryName: "",
    beneficiaryAddress: "",
    swiftOrIfscCode: "",
    financeEmailId: "",
    supportingDocument: "",
  },
  addressDetails: {
    ownerName: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    state: "",
    country: "",
    supportingDocument: "",
  },
  taxDetails: {
    companyNumber: "",
    taxNumber: "",
    supportingDocument: "",
  },
  personalDetails: {
    attnName: "",
    email: "",
    chatId: "",
    directNo: "",
    supportingDocument: ""
  },
};

export type ValidationResult = {
  businessDetailsValidation: boolean;
  companyDetailsValidation: boolean;
  bankDetailsValidation: boolean;
  addressDetailsValidation: boolean;
  taxDetailsValidation: boolean;
  personalDetailsValidation: boolean;
};

export const validateKYCData = (kycData: KYCData): ValidationResult => {
  return {
    businessDetailsValidation: isNotEmpty(kycData.businessDetails),
    companyDetailsValidation: isNotEmpty(kycData.companyDetails),
    bankDetailsValidation: true,
    addressDetailsValidation: isNotEmpty(kycData.addressDetails),
    taxDetailsValidation: isNotEmpty(kycData.taxDetails),
    personalDetailsValidation: true,
  };
};

export const isAllValid = (validationResult: ValidationResult) => {
  return (
    validationResult.businessDetailsValidation &&
    validationResult.companyDetailsValidation &&
    validationResult.bankDetailsValidation &&
    validationResult.addressDetailsValidation &&
    validationResult.taxDetailsValidation &&
    validationResult.personalDetailsValidation
  );
};

export const isNotEmpty = (object) => {
  const values: string[] = Object.values(object);
  const emptyValues = values.filter((value) => value.trim().length == 0);
  return emptyValues.length === 0;
};

export const documentToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
