import React, {FC, useContext, useEffect, useRef, useState} from "react";
import styles from "./NumberWidget.module.css";
import DotLoader from "../../../components/business/DotLoader/DotLoader";
import {ViewPortSizeContext} from "../../../contextApi/ViewportSizeContext/ViewPortSizeContext";

type Props = {
    label: string;
    data: string;
    icon:string;
    staticIcon:string;
};

const NumberWidget: FC<Props> = (props) => {
    const [dataAreaFontSize, setDataAreaFontSize] = useState<number>(32);
    const [labelFontSize, setLabelFontSize] = useState<number>(18);
    const [data, setData] = useState<string | null>(null);
    const dataArea = useRef<HTMLDivElement>(null);
    const labelArea = useRef<HTMLDivElement>(null);
    const iconArea = useRef<HTMLDivElement>(null);
    const [iconZoom, setIconZoom] = useState<number>(1.3);
    const { isMenuBarExpanded, isProfileCardExpanded } =
        useContext(ViewPortSizeContext);
  const [isHovered,setIsHovered]= useState<boolean>(false);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

    useEffect(() => {
        setDataAreaFontSize(
            dataArea.current ? dataArea.current.offsetWidth / 2 : 32
        );
        setLabelFontSize(
            labelArea.current ? labelArea.current.offsetWidth / 10 : 18
        );
        setIconZoom(
            iconArea.current ? iconArea.current.offsetWidth / 115 : 1.3
        );
    }, [isMenuBarExpanded, isProfileCardExpanded]);


  return (
      <div
        className={styles.numberWidgetContainer}
        data-testid="number-widget"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div ref={iconArea} className={styles.iconArea}>
            <img
                src={isHovered ? props.icon : props.staticIcon}
                className={styles.icon}
                data-testid={`${props.label.replace(/[^a-z0-9]/gi, "")}-icon`}
                alt={isHovered ? props.icon : props.staticIcon}
                style={{zoom:iconZoom}}
            />
        </div>
        <div className={styles.numberWidgetContainerRight}>
          {!data && <DotLoader />}
          {data && (
              <>
                <div
                    className={styles.dataArea}
                    style={{
                        fontSize: dataAreaFontSize,
                    }}
                    data-testid={`${props.label.replace(/[^a-z0-9]/gi, "")}-value`}
                >
                  {props.data}
                </div>
                <div
                    className={styles.labelArea}
                    ref={dataArea}
                    style={{
                        fontSize: labelFontSize,
                    }}
                    data-testid={`${props.label.replace(/[^a-z0-9]/gi, "")}-label`}
                >
                  {props.label}
                </div>
              </>
          )}
        </div>
      </div>
  );
};

export default NumberWidget;
