import React, { FC } from "react";
import styles from "../BaseScreen.module.css";

const DevBanner: FC = () => {
  return (
    <div className={styles.bannerContainer} data-testid="dev-env-banner">
      <p>
        <b className={styles.bannerTextWhite}>This is </b>
        <br />
        <b className={styles.bannerTextYellow}>Development Environment</b>
      </p>
    </div>
  );
};

export default DevBanner;
