import {Route, Routes, useLocation} from "react-router-dom";
import React, {FunctionComponent, useEffect, useState} from "react";
import BaseScreen from "./containers/BaseScreen/BaseScreen";
import Cookies from "universal-cookie";
import SubMenuScreen from "./containers/SubMenuScreen/SubMenuScreen";
import { AuthContext } from "./contextApi/AuthContext/authContext";
import ProfileScreen from "./containers/ProfileScreen/ProfileScreen";
import { Menu } from "./domains/Menu";
import DashboardScreen from "./containers/DashboardScreen/DashboardScreen";
import ServiceScreen from "./containers/ServiceScreen/ServiceScreen";
import { UIService } from "./domains/UIService";
import RechargeScreen from "./containers/RechargeScreen/RechargeScreen";
import RechargeConfirmationScreen from "./containers/RechargeConfirmationScreen/RechargeConfirmationScreen";
import { ViewPortSizeContextProvider } from "./contextApi/ViewportSizeContext/ViewPortSizeContext";
import RegistrationScreen from "./containers/RegistrationScreen/RegistrationScreen";
import PasswordVerificationScreen from "./containers/PasswordVerificationScreen/PasswordVerificationScreen";
import { UserDetailsContextProvider } from "./contextApi/UserDetailsContext/UserDetailsContext";
import { OTPContextProvider } from "./contextApi/OTPContext/OTPContext";
import { QueryClient, QueryClientProvider } from "react-query";
import IdentityScreen from "./containers/IdentityScreen/IdentityScreen";
import {SelectedScreenContextProvider} from "./contextApi/SelectedScreenContext/SelectedScreenContext";
import {sendSiteAnalytics} from "./api/endpoint";
import {FeatureToggleContextProvider} from "./contextApi/FeatureToggleContext/FeatureToggleContext";

const App: FunctionComponent = () => {
  const cookies = new Cookies();
  const location = useLocation();

  const [accessToken, setAccessToken] = useState<string | null>(() => {
    const cookieAccessToken = cookies.get("accessToken");
    return cookieAccessToken || null;
  });

  const removeAccessToken = () => {
    setAccessToken(null);
  };

  useEffect(() => {
    if(!accessToken) return;
    sendSiteAnalytics(accessToken, location.pathname)
      .then(() => {})
      .catch(() => {});
  }, [location.pathname]);

  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider
        value={{ accessToken, setAccessToken, removeAccessToken }}
      >
        <FeatureToggleContextProvider>
          <ViewPortSizeContextProvider>
            <UserDetailsContextProvider>
              <OTPContextProvider>
                <SelectedScreenContextProvider>
                  <Routes>
                    <Route path="/login" element={<IdentityScreen/>}/>
                    <Route path="/signup" element={<RegistrationScreen/>}/>
                    <Route
                      path="/sipTrunk/verifySuccess"
                      element={<PasswordVerificationScreen/>}
                    />
                    <Route path="/*" element={<BaseScreen/>}>
                      <Route path="" element={<DashboardScreen/>}/>
                      <Route path="dashboard" element={<DashboardScreen/>}/>
                      <Route path="recharge" element={<RechargeScreen/>}/>
                      <Route path="profile" element={<ProfileScreen/>}/>
                      <Route
                        path="recharge/confirmation"
                        element={<RechargeConfirmationScreen/>}
                      />
                      <Route
                        path="sipTrunk"
                        element={<SubMenuScreen menu={Menu.SIP_TRUNK}/>}
                      />
                      <Route
                        path="pricing"
                        element={<SubMenuScreen menu={Menu.PRICING}/>}
                      />
                      <Route
                        path="numbers"
                        element={<SubMenuScreen menu={Menu.NUMBERS}/>}
                      />
                      <Route
                        path="report"
                        element={<SubMenuScreen menu={Menu.REPORT}/>}
                      />
                      <Route
                        path="numbers/virtualNumbers"
                        element={
                          <ServiceScreen
                            service={UIService.VIRTUAL_NUMBER_LIST}
                          />
                        }
                      />
                      <Route
                        path="numbers/purchaseVirtualNumber"
                        element={
                          <ServiceScreen
                            service={UIService.PURCHASE_VIRTUAL_NUMBER}
                          />
                        }
                      />
                      <Route
                        path="transactions/recharge"
                        element={
                          <ServiceScreen service={UIService.SHOW_RECHARGES}/>
                        }
                      />
                      <Route
                        path="transactions/others"
                        element={
                          <ServiceScreen
                            service={UIService.SHOW_OTHER_TRANSACTIONS}
                          />
                        }
                      />
                      <Route
                        path="pricing/rates"
                        element={<ServiceScreen service={UIService.RATES}/>}
                      />
                      <Route
                        path="report/cdr"
                        element={<ServiceScreen service={UIService.VIEW_CDR}/>}
                      />
                      <Route
                        path="report/csr"
                        element={<ServiceScreen service={UIService.VIEW_CSR}/>}
                      />
                      <Route
                        path="sipTrunk/ipTrunk"
                        element={
                          <ServiceScreen service={UIService.IP_TRUNK_LIST}/>
                        }
                      />
                      <Route
                        path="sipTrunk/userTrunk"
                        element={
                          <ServiceScreen service={UIService.USER_TRUNK_LIST}/>
                        }
                      />
                      <Route
                        path="transactions/others"
                        element={
                          <ServiceScreen
                            service={UIService.SHOW_OTHER_TRANSACTIONS}
                          />
                        }
                      />
                    </Route>
                  </Routes>
                </SelectedScreenContextProvider>
              </OTPContextProvider>
            </UserDetailsContextProvider>
          </ViewPortSizeContextProvider>
        </FeatureToggleContextProvider>
      </AuthContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
