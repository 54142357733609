import React, { FC, useState } from "react";
import styles from "./CDRContainer.module.css";
import TimePicker from "react-time-picker";
import { formatDateToyyyyMMdd } from "../../../domains/Date";
import moment from "moment";
import SkyButton, {ButtonSize} from "../../base/SkyButton/SkyButton";

export type Props = {
  selectedDate: Date;
  onSearch: (
    startTime: string,
    endTime: string,
    callerNumber?: number,
    calledNumber?: number
  ) => void;
  onValidationError: (error: string) => void;
  onCancel: () => void;
};

const CDRSearchForm: FC<Props> = (props) => {
  const [startTime, setStartTime] = useState<string>("00:00");
  const [endTime, setEndTime] = useState<string>("00:00");
  const [callerNumber, setCallerNumber] = useState<string>("");
  const [calledNumber, setCalledNumber] = useState<string>("");

  const handleSearch = () => {
    const formattedDate = formatDateToyyyyMMdd(props.selectedDate);
    const parsedStartTime = moment(
      formattedDate + " " + startTime,
      "YYYYMMDD H:m"
    );
    const parsedEndTime = moment(formattedDate + " " + endTime, "YYYYMMDD H:m");
    const diffInHours = moment
      .duration(parsedEndTime.diff(parsedStartTime))
      .asHours();

    if (diffInHours > 6) {
      props.onValidationError("The time span must be less than 6 hours");
    } else if (diffInHours <= 0) {
      props.onValidationError(
        "End time can't be earlier than or same with start time"
      );
    } else if (calledNumber == "" && callerNumber == "") {
      props.onValidationError(
        "Either called number or caller number is mandatory"
      );
    } else
      props.onSearch(
        startTime,
        endTime,
        parseInt(callerNumber),
        parseInt(calledNumber)
      );
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchFormRow}>
        <div className={styles.searchFormCell}>
          <div className={styles.timepickerWrapper}>
            <div className={styles.roundLabelBox}> From </div>
            <TimePicker
              disableClock={true}
              onChange={(value) =>
                setStartTime(value ? value.toString() : "00:00")
              }
              value={startTime}
              format={"H:m"}
              className={styles.timePicker}
              data-testid={"start-time"}
            />
          </div>
        </div>
        <div className={styles.searchFormCell}>
          <div className={styles.timepickerWrapper}>
            <div className={styles.roundLabelBox}> To </div>
            <TimePicker
              disableClock={true}
              onChange={(value) =>
                setEndTime(value ? value.toString() : "00:00")
              }
              value={endTime}
              format={"H:m"}
              className={styles.timePicker}
              data-testid={"end-time"}
            />
          </div>
        </div>
      </div>
      <div className={styles.searchFormRow}>
        <div className={styles.searchFormCell}>
          <div className={styles.textLabel}> Caller </div>
          <input
            className={styles.roundInputBox}
            type="number"
            value={callerNumber}
            onChange={(event) => setCallerNumber(event.target.value)}
            placeholder={"(xxx) xx xxxx"}
            data-testid={"caller-number"}
          />
        </div>
        <div className={styles.searchFormCell}>
          <div className={styles.textLabel}> Called Number </div>
          <input
            className={styles.roundInputBox}
            type="number"
            value={calledNumber}
            onChange={(event) => setCalledNumber(event.target.value)}
            placeholder={"(xxx) xx xxxx"}
            data-testid={"called-number"}
          />
        </div>
      </div>
      <div className={styles.searchFormRow}>
        <div className={styles.buttonContainer}>
          <SkyButton
              text={"Cancel"}
              onClick={() => props.onCancel()}
              size={ButtonSize.SMALL}
              testId={"dialog-cancel-button"}
          />
          <SkyButton
              text={"Ok"}
              onClick={() => handleSearch()}
              size={ButtonSize.SMALL}
              testId={"cdr-search-ok-button"}
          />
        </div>
      </div>
    </div>
  );
};

export default CDRSearchForm;
