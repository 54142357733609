import {createContext, useContext, useEffect, useState} from "react";
import {AuthContext} from "../AuthContext/authContext";
import {User} from "../../domains/User";
import {fetchUserProfile} from "../../api/endpoint";
import {notyf} from "../../notyf";

type UserDetailsContextDataType = {
    user: User | null;
    setUser: (user: User | null) => void;
}
export const UserDetailsContext = createContext<UserDetailsContextDataType>({
    user: null,
    setUser: () => {
    }
})

export const UserDetailsContextProvider = ({children}) => {
    const {accessToken} = useContext(AuthContext);
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        if (!accessToken) return;

        fetchUserProfile(accessToken)
            .then((userProfile) => {
                setUser(userProfile);
            })
            .catch(() => {
                notyf.error("Unable to fetch user profile");
            })
    }, [accessToken]);

    return (
        <UserDetailsContext.Provider value={{
            user,
            setUser,
        }}>
            {children}
        </UserDetailsContext.Provider>
    )
}