import React, { FC, useContext, useEffect, useRef, useState } from "react";
import styles from "./SquareWidget.module.css";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import DotLoader from "../../../components/business/DotLoader/DotLoader";
import classNames from "classnames";
import { ViewPortSizeContext } from "../../../contextApi/ViewportSizeContext/ViewPortSizeContext";

type Props = {
  icon: string;
  data: string;
  label: string;
  isLoading?: boolean;
  showTrapezoid?: boolean;
  background?: string;
  dataFontColor?: string;
  labelFontColor?: string;
  staticIcon:string;
};

const SquareWidget: FC<Props> = (props) => {
  const [dataAreaFontSize, setDataAreaFontSize] = useState<number>(32);
  const [labelFontSize, setLabelFontSize] = useState<number>(16);
  const [iconZoom, setIconZoom] = useState<number>(1.3);
  const dataArea = useRef<HTMLDivElement>(null);
  const labelArea = useRef<HTMLDivElement>(null);
  const iconArea = useRef<HTMLDivElement>(null);
  const { isMenuBarExpanded, isProfileCardExpanded } =
    useContext(ViewPortSizeContext);
  const [isHovered,setIsHovered]= useState<boolean>(false);

  useEffect(() => {
    setDataAreaFontSize(
      dataArea.current ? dataArea.current.offsetWidth / 5 : 32
    );
    setLabelFontSize(
      labelArea.current ? labelArea.current.offsetWidth / 10 : 16
    );
    setIconZoom(iconArea.current ? iconArea.current.offsetWidth / 115 : 1.3);
  }, [isMenuBarExpanded, isProfileCardExpanded]);

  return (
    <div
      className={styles.widgetContainer}
      data-testid={"square-widget"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div ref={iconArea} className={styles.iconArea}>
        <img
            src={isHovered ? props.icon : props.staticIcon}
            className={styles.icon}
            data-testid={`${props.label.replace(/[^a-z0-9]/gi, "")}-icon`}
            alt={isHovered ? props.icon : props.staticIcon}
            style={{zoom: iconZoom}}
        />
      </div>
      <div
        className={classNames(
          styles.bottomContainer,
          props.isLoading ? styles.centerAlign : ""
        )}
        style={{
          background: `url(${
            props.background ? props.background : ""
          }) no-repeat`,
          backgroundSize: "100% 100%",
        }}
      >
        {props.isLoading && <DotLoader />}
        {!props.isLoading && (
          <div
            className={styles.dataArea}
            ref={dataArea}
            style={{
              fontSize: dataAreaFontSize,
              color: props.dataFontColor ? props.dataFontColor : "",
            }}
            data-testid={`${props.label.replace(/[^a-z0-9]/gi, "")}-value`}
          >
            {props.data}
          </div>
        )}
        <div
          className={styles.labelArea}
          ref={labelArea}
          style={{
            fontSize: labelFontSize,
            color: props.labelFontColor ? props.labelFontColor : "",
          }}
          data-testid={`${props.label.replace(/[^a-z0-9]/gi, "")}-label`}
        >
          {props.label}
        </div>
      </div>
    </div>
  );
};

export default SquareWidget;
