import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./UserTrunkList.module.css";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import { fetchUserTrunkList } from "../../../api/endpoint";
import classNames from "classnames";
import { notyf } from "../../../notyf";
import { UserTrunkListResponse } from "../../../models/response/UserTrunkListResponse";
import CLIDetailsContainer from "./CLIDetailsContainer";

const UserTrunkList: FC = () => {
  const [tableCellRenderer, setTableCellRenderer] =
    useState<(() => JSX.Element)[][]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const columns = ["Type", "Username", "Capacity", "Status", "Mapped CLI"];
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    fetchUserTrunkList(accessToken ? accessToken : "")
      .then((userTrunkResponses: UserTrunkListResponse[]) => {
        try {
          setTableCellRenderer(
            userTrunkResponses
              .sort((r1, r2) => r1.lockType - r2.lockType)
              .map((response) => {
                return [
                  () => <>{"SIP 2.0"}</>,
                  () => <>{response.username}</>,
                  () => <>{response.lineCapacity}</>,
                  () => (
                    <>
                      <div
                        className={classNames(
                          styles.dot,
                          response.lockType == 0 ? styles.green : styles.red
                        )}
                      />
                    </>
                  ),
                  () => (
                    <CLIDetailsContainer cliDetails={response.cliDetails} />
                  ),
                ];
              })
          );
        } catch (error) {
          notyf.error("Unable to process data");
        } finally {
          setIsLoading(false);
        }
      })
      .catch(() => {
        notyf.error("Unable to fetch User Trunk list");
      });
  }, [accessToken]);

  return (
    <div className={styles.userTrunkContainer} data-testid="data-sheet">
      <SkyGrid
        isLoading={isLoading}
        columns={columns}
        renderer={tableCellRenderer}
      />
    </div>
  );
};

export default UserTrunkList;
